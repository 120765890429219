//Import des composants utiles
import React from 'react';
import { Redirect } from 'react-router-dom';

//Import des composants de design
import { Card, Typography, Layout } from 'antd';
import axios from 'axios';
const { Title } = Typography;
const { Header, Content } = Layout;


//Page de connexion de l'utilisateur
class Confirmation extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            message: "",       
            redirect: false
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    //Récupération des variables passé dans l'url
    getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    componentDidMount(){
        this._isMounted = true;

        axios.get(decodeURIComponent(this.getUrlVars()['queryURL']))
        .then((res) => {
            if(res.status == 204){
                if(this._isMounted){
                    this.setState({message: "Mail confirmé"})
                    this.setState({redirect: true})
                }
            }
        })
        .catch((err) => {
            if(err.response.status == 422){
                if(this._isMounted)
                    this.setState({message: "Mail de confirmation expiré"})
            }else{
                if(this._isMounted)
                    this.setState({message: "Erreur Inconnue"})
            }
        })
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        //Redirection si l'utilisateur a son email vérifié
        if(this.state.redirect)
            return <Redirect to='/' />; 

        return (
            <div id="login">
                <Card style={{margin:'auto', width:400}}>
                    <Layout className="light-background"> 
                        <Header className="light-background">
                            <Title level={4} style={{textAlign:'center'}}>Confirmation de votre addresse mail</Title>
                        </Header>
                        <Content className="light-background">
                            <Title level={3} style={{textAlign:'center', color:'#e3342f'}}>{this.state.message}</Title>
                        </Content>                              
                    </Layout>
                </Card>
            </div>
        );
    }
}

export default Confirmation;