//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';
import store from '../../Store/Store';
import { DeleteQuestion, AddQuestion } from '../../components/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Import des composants de design
import { Typography, Layout, Input, Collapse, Button, InputNumber, Empty, Space } from 'antd';
import { Row, Col } from 'antd';
import { EditOutlined,InfoCircleOutlined,SaveOutlined } from '@ant-design/icons';

import { UserAccount } from '../../components/Component';
const { Title, Text } = Typography;
const { Footer, Header, Sider, Content } = Layout;
const { Search } = Input;
const { Panel } = Collapse;

//Page de listing des questions
export default class Questions extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            questions:[],
            editing: null,
            searchText: "",
            loading: false
        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadQuestions();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des questions
    loadQuestions(){
        API.get('/api/questions')
        .then((res) => {
            if(this._isMounted)
                this.setState({questions: res.data})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Modification de la question
    sendQuestionData(question, event){
        if(this._isMounted)
            this.setState({loading: true})

        API.put('api/questions/'+question.id, {
            html: question.html,
            order: question.order,
            title: question.title
        })
        .then((res) => {
            if(this._isMounted){
                this.setState({loading: false})
                this.setState({editing: null})
                this.loadQuestions();
            }
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Edition On/Off
    setEditing(question, event){
        event.stopPropagation();

        if(this._isMounted){
            if(this.state.editing != null && this.state.editing.id == question.id){
                this.setState({editing: null})
            }else{
                this.setState({editing: question})
            }
        }
    }

    //Modification du titre de la question
    changeQuestionTitle(event){
        if(this._isMounted){
            const question = this.state.editing
            question.title = event.target.value
            this.setState({editing: question})
        }
    }

    //Modification de l'ordre de la question
    changeQuestionOrder(value){
        if(this._isMounted){
            const question = this.state.editing
            question.order = value
            this.setState({editing: question})
        }
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){

        const genExtra = (question) => ( 
            <Row>  
                <Button type="text" shape="circle" onClick={this.setEditing.bind(this, question)} icon={<EditOutlined />}/>
                <DeleteQuestion refresh_handler={this.loadQuestions.bind(this)} question={question}/>
            </Row>          
          );

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar actual_tab={"Faq"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={10}>
                                {store.getState().user.profile.role == "ROLE_ADMIN"?<Title level={3}>Gérer la <span className="hightlight">FAQ</span></Title>:<Title level={3}>Vous avez une <span className="hightlight">question</span> ?</Title>}
                            </Col>
                            {store.getState().user.profile.role == "ROLE_ADMIN"?<AddQuestion refresh_handler={this.loadQuestions.bind(this)}/>:<></>}
                        </Row>

                        <Row className="toolsbar" justify="space-between">
                            <Col>
                                <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                            </Col>
                        </Row>


                        <Content className="main-content light-background">                        
                            {/* Liste des questions */}
                                {(this.state.questions.length > 0)?
                                    <Collapse accordion expandIconPosition={'right'} > 
                                    {this.state.questions.sort(function(a, b){return a.order - b.order}).filter(question => (question.title).toLowerCase().includes(this.state.searchText.toLowerCase())).map((question, index) => { 
                                            return (
                                            <Panel showArrow={true} header={question.order + ". " + question.title} key={index} extra={store.getState().user.profile.role == "ROLE_ADMIN"?genExtra(question):null}>
                                                {store.getState().user.profile.role == "ROLE_ADMIN" && this.state.editing != null && this.state.editing.id == question.id?
                                                <Space direction='vertical' size={32}>
                                                    <Row gutter={[32, 32]}>
                                                        <Col span={18}>
                                                            <Title level={5}>Titre de la question</Title>
                                                            <Input value={this.state.editing.title} onChange={this.changeQuestionTitle.bind(this)} placeholder="Entrée le titre de la question" />
                                                        </Col>
                                                        <Col span={6}>
                                                            <Title level={5}>Ordre</Title>
                                                            <InputNumber min={0} onChange={this.changeQuestionOrder.bind(this)} value={this.state.editing.order} />
                                                        </Col>
                                                    </Row>
                                                    <Row justify="center">
                                                        <CKEditor 
                                                            editor={ClassicEditor}
                                                            config={{
                                                                toolbar: [ 'undo', 'redo', 
                                                                '|', 'heading', 
                                                                '|', 'fontfamily', 'fontsize', 'fontColor',
                                                                '|', 'alignment',
                                                                '|','bold', 'italic',
                                                                '|',  'link', 'mediaEmbed',
                                                                '|', 'numberedList',
                                                                '|','insertTable',
                                                                '|', 'codeBlock',
                                                                ],
                                                                mediaEmbed: {
                                                                    previewsInData:true
                                                                },
                                                                heading: {
                                                                    options: [
                                                                        { model: 'heading1', view: 'h4', title: 'Titre 1', class: 'ck-heading_heading1' },
                                                                        { model: 'heading2', view: 'h5', title: 'Titre 2', class: 'ck-heading_heading2' },
                                                                        { model: 'paragraph', title: 'Paragraphe', class: 'ck-heading_paragraph' },
                                                                    ]
                                                                }
                                                            }}
                                                            data={question.html}
                                                            onReady={ editor => {
                                                                
                                                            }}
                                                            onChange={ (event, editor) => {
                                                                const data = editor.getData();
                                                                question.html = data;
                                                            }}
                                                        />
                                                    </Row>
                                                    
                                                    <Row justify="end">
                                                        <Button type="primary" shape="round" loading={this.state.loading} disabled={this.state.loading} onClick={this.sendQuestionData.bind(this, question)} icon={<SaveOutlined />}>
                                                                Sauvegarder
                                                        </Button>
                                                    </Row>
                                                </Space>:
                                                <Row className="ckeditor-html">
                                                    <div dangerouslySetInnerHTML={{ __html: question.html }}></div>                                       
                                                </Row>}
                                            </Panel>
                                            )
                                    })}
                                    </Collapse>
                                :<Empty
                                image={<InfoCircleOutlined />}
                                description={this.state.searchText!=""?
                                <span>
                                    Il n'y a pas de réponse correspondante à votre recherche
                                </span>
                                :
                                <span>
                                    La FAQ est actuellement vide
                                </span>
                                }
                            />
                            }                           
                        </Content>
                    </Content>
                </Layout>
            </Layout>
        );

    }
}