require('aos');
import AOS from 'aos';
AOS.init();

require('./bootstrap');

import React from "react";
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from "react-router-dom";

//Import du Scss Global
//import 'antd/dist/antd.css';
import '../sass/app.scss';

//All ---
import LoginPage from './Pages/Login';
import RegisterPage from './Pages/Register';
import FinalStepRegisterPage from './Pages/FinalStepRegisterPage';
import PasswordReset from './Pages/PasswordReset';
import ProfilePage from './Pages/User/Profile';
import FAQ from './Pages/Admin/FAQ';
import CompanyPage from './Pages/Admin/Company';
import CampaignsPage from './Pages/User/Campaigns';
import StreamPage from './Pages/Admin/StreamPage';
import FlowPage from './Pages/Admin/FlowPage';
import FlowManagePage from './Pages/User/FlowManagePage';
import CreateCampaignsPage from './Pages/Admin/CampaignCreate';
import ModifyCampaignsPage from './Pages/Admin/CampaignModify';
import Confirmation from './Pages/Confirmation';

import { ContactForm } from './components/Component';

//Admin ---
import AdminTagsPage from './Pages/Admin/Tags';
import AdminUserPage from './Pages/Admin/User';
import AdminUsersPage from './Pages/Admin/Users';
import AdminCabinetsPage from './Pages/Admin/Cabinets';
import AdminCreatorsPage from './Pages/Admin/Creators';

//User ---
import UserWorkspace from './Pages/User/Workspace';

//Homepage ---
import WebFAQ from './components/WebFaq';

function App() {
    return (
        <div className="App" style={{height:'100%'}}>
            <HashRouter>               
                <Switch>                  
                    <Route exact path="/" component={LoginPage} />
                    <Route path="/atags" component={AdminTagsPage} />
                    <Route path="/auser" component={AdminUserPage} />
                    <Route path="/ausers" component={AdminUsersPage} />
                    <Route path="/acabinets" component={AdminCabinetsPage} />
                    <Route path="/acreators" component={AdminCreatorsPage} />

                    <Route path="/company" component={CompanyPage} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/register" component={RegisterPage} />
                    <Route path="/inscription" component={FinalStepRegisterPage}/>
                    <Route path="/passwordreset" component={PasswordReset} />
                    <Route path="/profile" component={ProfilePage} />
                    <Route path="/workspace" component={UserWorkspace} />
                    <Route path="/campaigns" component={CampaignsPage} />

                    <Route path="/createcampaign" component={CreateCampaignsPage} />
                    <Route path="/modifycampaign" component={ModifyCampaignsPage} />
                    <Route path="/createstream" component={StreamPage} />
                    <Route path="/modifystream" component={StreamPage} />
                    <Route path="/createflow" component={FlowPage} />
                    <Route path="/modifyflow" component={FlowPage} />
                    <Route path="/manageflow" component={FlowManagePage} />

                    <Route path="/verify-email" component={Confirmation} />
                </Switch>
            </HashRouter> 
        </div>
    )
}

if (document.getElementById('contact')) {
    ReactDOM.render(
            <ContactForm />,
        document.getElementById('contact'));
}

if (document.getElementById('root')) {
    ReactDOM.render(<App />, document.getElementById('root'));
}