//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import store from '../../Store/Store';
import { ActionCreators } from '../../Store/Action';
import API from '../../api';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import arrayMove from 'array-move';
import { Redirect } from 'react-router-dom';
import { sortAZ, sortDateInverse, replaceCaracters } from '../../utils';

//Import des composants de design
import { Typography, Layout, Input, List,Empty,Select } from 'antd';
import { FileExclamationOutlined  } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { CampaignModify } from '../../components/LeftPanel';
import { AddADirectFile } from '../../components/Modal';
import { GoBackTitle, UserAccount, CampaignFileSmallCard, CampaignAddedFileSmallCard } from '../../components/Component';
const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const { Header, Sider, Content } = Layout;

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 10;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#00A887" : "transparent",
    padding: grid,
});

//Page de l'entreprise
class CampaignPage extends React.Component{
    constructor(props){
        super(props)

        if(props.location.state != undefined){
            this.state = {
                campaign: this.props.location.state.campaign,
                files: [],
                favorite_files: [],
                addedFiles: [],
                searchText: "",
                enoughSpace:true,
                used_storage:store.getState().user.company.used_storage,
                storage_limit:store.getState().user.company.storage_limit,
            }
        }else{
            this.state = {
                campaign: undefined,
                files: [],
                favorite_files: [],
                addedFiles: [],
                searchText: "",
                enoughSpace:true,
                used_storage:store.getState().user.company.used_storage,
                storage_limit:store.getState().user.company.storage_limit,
            }
        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.checkStorage = this.checkStorage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        
        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        if(this.props.location.state != undefined){
            this.loadUserFavorite();
            this.loadCampaignsFilesList();
            this.loadCompanyFilesList();
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des fichiers favoris
    loadUserFavorite(){
        API.get('/api/users/'+store.getState().user.profile.id+'/files')
        .then((res) => {
            if(this._isMounted)
                this.setState({favorite_files:res.data})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement des fichiers lier à la campagne
    loadCampaignsFilesList(){
        var files = []

        API.get('/api/campaigns/'+this.state.campaign.id+'/files')
        .then((res) => {
            res.data.forEach(async (file) => {
                /*const tags = await API.get('/api/files/'+file.id+"/tags")
                file["tags"] = tags.data*/

                file["order"] = file.pivot.order
                file["duration"] = file.pivot.duration

                file["listId"] = file.id.toString();

                files.forEach(listFile => {
                    if(file["listId"] == listFile.listId){
                        file["listId"] = "0"+listFile.listId;
                    }
                })

                files.push(file);
                files = sortAZ(files,"name");

                if(this._isMounted)
                    this.setState({addedFiles:files})
            });

        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement des fichiers de l'entreprise
    loadCompanyFilesList(){
        var files = []

        API.get('/api/companies/'+store.getState().user.selected_company+'/files')
        .then((res) => {
            res.data.forEach(async (file) => {
                /*
                const tags = await API.get('/api/files/'+file.id+"/tags")
                file["tags"] = tags.data*/

                files.push(file);
                files = sortAZ(files,"name");

                if(this._isMounted)
                    this.setState({files:files})
            });
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }
    
    loadDurationVideoFile(file) {
        return new Promise(resolve => {
            let video = document.createElement('video')
            video.setAttribute('src', file.url)
            video.onloadedmetadata = function() {
                file["duration"] = Math.floor(this.duration);
                resolve(file["duration"]);
            };
        });
    }

    //Ajout d'un fichier à la campagne
    async addFiles(value, event){
        var addedFiles = this.state.addedFiles;
        const file = {...this.state.files.filter(file => file.id == value)[0]};

        if(file.type=="TYPE_VIDEO"){
            await this.loadDurationVideoFile(file);
        }
        else{
            file["duration"] = 10;
        }

        file["listId"] = file.id.toString();

        addedFiles.forEach(listFile => {
            if(file["listId"] == listFile.listId){
                file["listId"] = "0"+listFile.listId;
            }
        })

        addedFiles.push(file);
        this.setState({addedFiles: addedFiles},() => {this.checkStorage(addedFiles)});
    }

    checkStorage(addedFiles){
        let durations=0;
        addedFiles.forEach(file => {
            durations += file.duration
        })
        let size = (durations/10)*1000000;

        if(size+parseInt(this.state.used_storage)>this.state.storage_limit){
            this.setState({enoughSpace: false})
        }else{
            this.setState({enoughSpace: true})
        }
    }

    //Suppresion d'un fichier à la campagne
    removeFiles(value, event){
        let addedFiles = this.state.addedFiles;
        addedFiles = addedFiles.filter(e => e.id !== value);
        this.setState({addedFiles: addedFiles},() => {this.checkStorage(addedFiles)});
    }

    //Modification de la durée 
    handleValueChange(index, value, event){
        var addedFiles = this.state.addedFiles;
        addedFiles[index].duration = value;
        this.setState({addedFiles: addedFiles},() => {this.checkStorage(addedFiles)}); 
    }

    onSortEnd({oldIndex, newIndex}){
        this.setState(({addedFiles}) => ({
            addedFiles: arrayMove(addedFiles, oldIndex, newIndex),
        }));
    }

    onDragEnd(result) {
        
        if (!result.destination) {
            return;
        }

        const addedFiles = reorder(
            this.state.addedFiles,
            result.source.index,
            result.destination.index
        );

        this.setState({
            addedFiles
        });
    }

    handleOnSearch(value){
        this.setState({searchText: replaceCaracters(value)});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: replaceCaracters(event.target.value)});
    }

    handleSort(value){

        switch (value) {
            case 'date':
                sortDateInverse(this.state.files,"created_at");
                break;
            default:
                sortAZ(this.state.files,"name");
        }

        this.setState({});
    }

    render(){
        if(this.state.campaign != undefined){
            return (
                <Layout>
                    <Sider width={80} className="main_nav">
                        <img src="img/ico.png"/>
                        <Navbar actual_tab={"Cabinets"}/>
                    </Sider>
                    <Layout hasSider={false} className="main-container">
                        <Header>
                            <Row justify="end" align="middle">
                                <UserAccount />
                            </Row>
                        </Header>
                        <Content className="app-content">
                            <Row justify="space-between" className="app-header">
                                <Col span={8}>
                                    <Title level={3}>Modifier une <span className="hightlight">campagne</span></Title>
                                    <GoBackTitle title={"Revenir à l'entreprise"} route={"/company"}/>
                                </Col>
                            </Row>
                            <Row gutter={[32, 16]}>
                                {/* Left Panel */}
                                <Col span={6}>
                                    <CampaignModify campaign={this.state.campaign} files={this.state.addedFiles} refresh_handler={this.loadCompanyFilesList.bind(this)} enoughSpace={this.state.enoughSpace}/>
                                </Col>
                                {/* Middle Panel */}
                                <Col span={18}>
                                    <Row gutter={[32, 16]}>
                                        <Col span={12}>
                                            <Row>
                                                <Title level={5}>Vos fichiers</Title>
                                            </Row>
                                            
                                            <Row className="toolsbar" justify="between">
                                                <Col>
                                                    {/* Barre de Recherche */}
                                                    <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                                                </Col>
                                                <Col>
                                                    <Select defaultValue="date" onChange={this.handleSort} bordered={false}>
                                                        <Select.Option value="az">A-Z</Select.Option>
                                                        <Select.Option value="date">Date</Select.Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* Bouton d'ajout d'un fichier direct */}
                                                <AddADirectFile company_id={store.getState().user.selected_company} refresh_handler={this.loadCompanyFilesList.bind(this)}/>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Title level={5}>Fichiers de la campagne</Title>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row gutter={[32, 16]}>
                                        <Col span={12}>
                                            <List
                                                pagination={{
                                                    pageSize: 10,
                                                    hideOnSinglePage:true,
                                                    position:'top'
                                                }}
                                                dataSource={this.state.files.filter(file => (replaceCaracters(file.name)).toLowerCase().includes(this.state.searchText.toLowerCase()))}
                                                renderItem={item => (
                                                    <CampaignFileSmallCard key={item.id} file={item} favorite={this.state.favorite_files.filter(ffile => ffile.id==item.id).length > 0} favorite_handler={this.loadUserFavorite.bind(this)} add_handler={this.addFiles.bind(this, item.id)}/>
                                                    )
                                                }
                                                locale={{emptyText:<Empty
                                                    image={<FileExclamationOutlined />}
                                                    description={this.state.searchText!=""?
                                                    <span>
                                                        Il n'y a pas de fichier correspondant à votre recherche
                                                    </span>
                                                    :
                                                    <span>
                                                        Il n'y a pas encore de fichier
                                                    </span>
                                                    }
                                                >
                                                </Empty>}}
                                            /> 
                                        </Col>
                                        <Col span={12}>
                                            <List
                                                className="listcol dragzone"
                                            >
                                                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {this.state.addedFiles.length>0 ?
                                                                <>{this.state.addedFiles.map((file, index) => (
                                                                    <Draggable key={file.listId}
                                                                     draggableId={file.listId} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                            >
                                                                                <CampaignAddedFileSmallCard file={file} remove_handler={this.removeFiles.bind(this, file.id)} handle_change={this.handleValueChange.bind(this, index)}/>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}</>
                                                                :
                                                                <Empty
                                                                    image={<FileExclamationOutlined />}
                                                                    description={
                                                                    <span>
                                                                        Votre campagne n'a pas encore de fichier
                                                                    </span>
                                                                    }
                                                                >
                                                                </Empty>
                                                                }
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </List>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Layout>
            );

        }else{
            return <Redirect to='/company'/>
        }

    }
}

export default CampaignPage;