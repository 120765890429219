//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import store from '../../Store/Store';
import API from '../../api';
import { Redirect } from 'react-router-dom';
import { sortAZ, sortDate } from '../../utils';

//Import des composants de design
import { Button, Typography, Layout, Input, List, Tabs, Empty,Segmented,Select  } from 'antd';
import { Row, Col } from 'antd';
import {HeartFilled,HeartOutlined,FundProjectionScreenOutlined  } from '@ant-design/icons';
import { StreamAdd, StreamModify } from '../../components/LeftPanel';
import { GoBackTitle, UserAccount, Tags, StreamCampaignSmallCard,CampaignAddBtn } from '../../components/Component';
const { Title, Text } = Typography;
const { Search } = Input;
const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

//Page de l'entreprise
class StreamPage extends React.Component{
    constructor(props){
        super(props)
        if(props.location.pathname.includes("modify")){
            let broadcast=undefined;
            let campaign=undefined;
            if(props.location.state!=undefined){
                broadcast=props.location.state.broadcast;
                campaign=props.location.state.broadcast.campaign;
            }

            this.state = {
                broadcast:broadcast,
                campaigns: [],
                publicCampaigns:[],
                selectedCampaign: campaign,
                favorite_campaigns: [],
                searchCampaignText: "",
                action:"modify",
                filter_favorite_campaign: false,
                filter_included_campaigns:"All",
            }
        }else{
            this.state = {
                broadcast:undefined,
                campaigns: [],
                publicCampaigns:[],
                selectedCampaign: {},
                favorite_campaigns: [],
                searchCampaignText: "",
                action:"create",
                filter_favorite_campaign: false,
                filter_included_campaigns:"All",
            }
        }

        this.favorite_campaign = this.favorite_campaign.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.filter_included_campaigns = this.filter_included_campaigns.bind(this);


        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadUserFavorite();
        this.loadCompanyCampaignsList();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des campagnes favorite
    loadUserFavorite(){
        API.get('/api/users/'+store.getState().user.profile.id+'/campaigns')
        .then((res) => {
            if(this._isMounted)
                this.setState({favorite_campaigns:res.data})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement des campagnes de l'entreprise
    loadCompanyCampaignsList(){
        var campaigns = [];

        API.get('/api/campaigns')
        .then((res) => {
            res.data.forEach(async (campaign) => {
                if(!campaign.in_creation){
                    if(campaign.public){
                        //const tags = await API.get('/api/campaigns/'+campaign.id+"/tags");
                        //campaign["tags"] = tags.data
    
                        const company = await API.get('/api/companies/'+campaign.company_id);
                        campaign["company"] = company.data
    
                        campaigns.push(campaign)
                        campaigns = sortDate(campaigns, "updated_at");
    
                    }else{
                        if(campaign.company_id == store.getState().user.selected_company){
                            //const tags = await API.get('/api/campaigns/'+campaign.id+"/tags");
                            //campaign["tags"] = tags.data
    
                            const company = await API.get('/api/companies/'+store.getState().user.selected_company);
                            campaign["company"] = company.data
        
                            campaigns.push(campaign)
                            campaigns = sortDate(campaigns, "updated_at");
                        }
                    }
                }
                
                if(this.state.action=="modify"){
                    this.setState({campaigns:campaigns})
                }else{
                    this.setState({campaigns:campaigns,
                        selectedCampaign: campaigns[0]})
                }
            });
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Sélection de la campagne
    selectCampaign(value, event){
        var selectedCampaign = value;
        this.setState({selectedCampaign: selectedCampaign});
    }

    handleOnSearch(value){
        this.setState({searchCampaignText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchCampaignText: event.target.value});
    }

    handleSort(value){
        switch (value) {
            case 'date':
                sortDate(this.state.campaigns,"updated_at");
                break;
            default:
                sortAZ(this.state.campaigns,"name");
        }

        this.setState({});
    }

    favorite_campaign = () => this.setState({filter_favorite_campaign: !this.state.filter_favorite_campaign});
    filter_included_campaigns = value => this.setState({filter_included_campaigns: value});


    render(){
        
        let filterCampaigns=[];
        if(this.state.filter_favorite_campaign){
            this.state.campaigns.filter(campaign => this.state.favorite_campaigns.filter(fcampaign => fcampaign.id==campaign.id).length > 0).forEach(campaign =>{
                if(this.state.filter_loading_campaign){
                    filterCampaigns.push(campaign);
                }
                else {
                    if(!campaign.in_creation){
                        filterCampaigns.push(campaign);
                    }
                }     
            })
        }else{
            this.state.campaigns.forEach(campaign => {
                if(this.state.filter_loading_campaign){
                    filterCampaigns.push(campaign);
                }
                else {
                    if(!campaign.in_creation){
                        filterCampaigns.push(campaign);
                    }
                } 
            });
        }

        let filterIncludedCampaigns = [];
        filterCampaigns.forEach((campaign) => {
            switch(this.state.filter_included_campaigns){
                case "Campaigns":
                    if(!campaign.public){
                        filterIncludedCampaigns.push(campaign);
                    }
                    break;
                case "PublicCampaigns":
                    if(campaign.public){
                        filterIncludedCampaigns.push(campaign);
                    }
                    break;
                default:
                    filterIncludedCampaigns.push(campaign);
                    break;   
            }
        })

        //Redirection si l'utilisateur a réussi a créer une diffusion
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar  actual_tab={store.getState().user.company.type == "TYPE_CABINET"?"Cabinets":"Creators"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={8}>
                                {this.state.action=="create"?<Title level={3}>Créer une <span className="hightlight">diffusion</span></Title>:<Title level={3}>Modifier une <span className="hightlight">diffusion</span></Title>}
                                {store.getState().user.profile.role == "ROLE_ADMIN"?<GoBackTitle title={"Revenir à l'entreprise"} route={"/company"}/>:<GoBackTitle title={"Revenir au cabinet"} route={"/company"}/>}
                            </Col>
                        </Row>
                        <Row gutter={[32, 16]}>
                            {/* Left Panel */}
                            <Col span={6}>
                                {this.state.action=="create"?
                                <StreamAdd campaign={this.state.selectedCampaign} />
                                :
                                <StreamModify broadcast={this.state.broadcast} campaign={this.state.selectedCampaign}/>
                                }
                            </Col>
                            
                            {/* Middle Panel */}
                            <Col span={18}>
                            {this.state.campaigns.length>0&&
                                <Row className="toolsbar" justify="space-between">
                                    <Col span={10}>
                                        <Row>
                                            <Col>
                                                {/* Barre de Recherche */}
                                                <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                                            </Col>
                                            <Col className="filtersbuttons">
                                                {/* Filtre */}
                                                <Row>
                                                    <Button onClick={this.favorite_campaign} type="text" shape="circle" icon={this.state.filter_favorite_campaign?<HeartFilled/>:<HeartOutlined/>} />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={14}>
                                        <Row justify="end">
                                            <Col>
                                                <Segmented
                                                    onChange={this.filter_included_campaigns}
                                                    options={[
                                                    {
                                                        label: 'Toutes',
                                                        value: 'All',
                                                    },
                                                    {
                                                        label: 'Mes campagnes',
                                                        value: 'Campaigns',
                                                    },
                                                    {
                                                        label: 'Campagnes publiques',
                                                        value: 'PublicCampaigns',
                                                    },
                                                    ]}
                                                />
                                            </Col>
                                            <Col span={3}>
                                                <Select defaultValue="Date" onChange={this.handleSort} bordered={false}>
                                                    <Select.Option value="AZ">A-Z</Select.Option>
                                                    <Select.Option value="Date">Date</Select.Option>
                                                </Select>
                                            </Col>                     
                                        </Row>
                                    </Col>
                                </Row>
                                }
                                <Row>
                                    <List
                                        dataSource={filterIncludedCampaigns.filter(campaign => (campaign.name).toLowerCase().includes(this.state.searchCampaignText.toLowerCase()))}
                                        renderItem={item => (
                                            <StreamCampaignSmallCard key={item.id} campaign={item} selected={item.id == this.state.selectedCampaign.id} favorite={this.state.favorite_campaigns.filter(fcampaign => fcampaign.id==item.id).length > 0} favorite_handler={this.loadUserFavorite.bind(this)} select_handler={this.selectCampaign.bind(this, item)}/>
                                        )}
                                        className="list-columns"
                                        pagination={{
                                            pageSize: 12,
                                            hideOnSinglePage:true,
                                            size:"small",
                                            position:'top'
                                            }}
                                            locale={{
                                                emptyText:
                                                    <Empty
                                                        image={<FundProjectionScreenOutlined />}
                                                        description={this.state.searchText!=""?
                                                        <span>
                                                            Pas de campagne correspondante
                                                        </span>
                                                        :
                                                        <span>
                                                            Vous n'avez pas encore créé de campagne
                                                        </span>
                                                        }
                                                    >
                                                    </Empty>
                                            }}   
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default StreamPage;