//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import store from '../../Store/Store';
import API from '../../api';
import { Redirect } from 'react-router-dom';
import { RRule } from 'rrule';
import { sortDate } from '../../utils';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import arrayMove from 'array-move';

//Import des composants de design
import { Typography, Layout, Input, List, Empty } from 'antd';
import { Row, Col } from 'antd';
import { FlowManage } from '../../components/LeftPanel';
import { GoBackTitle, UserAccount,FlowStreamManageSmallCard } from '../../components/Component';
const { Title, Text } = Typography;
const { Search } = Input;
const { Header, Sider, Content } = Layout;
import { NotificationOutlined  } from '@ant-design/icons';

//Page de l'entreprise
class FlowManagePage extends React.Component{
    constructor(props){
        super(props)
        if(props.location.state!=undefined){
            this.state = {
                flow:props.location.state.flow,
                addedStreams:props.location.state.flow.broadcasts,
                pivot:props.location.state.flow.pivot,
                duration:0,
                redirect:false,
                company:{},
            }
        }else{
            this.state = {
                redirect:true,
            }
        }
        this.loadCompanyDetails = this.loadCompanyDetails.bind(this);
        this.loadFluxStreamsList = this.loadFluxStreamsList.bind(this);

        this.totalDurationVideoStream = this.totalDurationVideoStream.bind(this);
        this.loadDurationVideoBroadcast = this.loadDurationVideoBroadcast.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        if(!this.state.redirect){
            this.loadCompanyDetails();
            this.loadFluxStreamsList();
        }
        
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadCompanyDetails(){
        API.get('api/companies/'+this.state.flow.company_id)
        .then((res)=> {
            this.setState({company: res.data});

        })
        .catch((err) => { 
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    loadFluxStreamsList(){
        API.get('/api/companies/'+store.getState().user.selected_company+'/flows')
        .then((res) => {
            let subscribedFlow = res.data;
            this.setState({flow:subscribedFlow.filter(subscribedFlow => subscribedFlow.id == this.state.flow.id)[0], pivot:subscribedFlow.filter(subscribedFlow => subscribedFlow.id == this.state.flow.id)[0].pivot}); 
        })
        .catch((err) => {
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    async totalDurationVideoStream(){
        let totalDuration = 0;

        for(const broadcast of this.state.addedStreams){
            const duration = await this.loadDurationVideoBroadcast(broadcast.file_url);
            totalDuration = totalDuration + duration;
            
        }
        this.setState({duration: totalDuration});
    }

    loadDurationVideoBroadcast(file) {
        return new Promise(resolve => {
            let video = document.createElement('video')
            video.setAttribute('src', file)
            video.onloadedmetadata = function() {
                resolve(Math.floor(this.duration));
            };
        });
    }

    render(){

        //Redirection si l'utilisateur a réussi a créer une diffusion
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar  actual_tab={store.getState().user.company.type == "TYPE_CABINET"?"Cabinets":"Creators"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={8}>
                                <Title level={3}>Gérer le  <span className="hightlight">flux</span></Title>
                                <GoBackTitle title={"Revenir au cabinet"} route={"/company"}/>
                            </Col>
                        </Row>
                        <Row gutter={[32, 16]}>
                            {/* Left Panel */}
                            <Col span={6}>
                                <FlowManage company={this.state.company} flow={this.state.flow} duration={this.state.duration}/>
                            </Col>
                            
                            {/* Middle Panel */}
                            <Col span={18}>
                                <Row gutter={[32, 16]}>
                                        <List
                                            dataSource={this.state.addedStreams}
                                            renderItem={item => (
                                                <FlowStreamManageSmallCard key={item.id} pivot={this.state.pivot} broadcast={item} refresh_handler={this.loadFluxStreamsList}/>
                                            )}
                                            className="list-columns"
                                            pagination={{
                                                position:"top",
                                                pageSize: 8,
                                                hideOnSinglePage:true,
                                                size:"small",
                                                }}
                                            locale={{
                                                emptyText:
                                                    <Empty
                                                        image={<NotificationOutlined />}
                                                        description={
                                                        <span>
                                                           Ce flux ne contient pas de diffusion
                                                        </span>
                                                        }
                                                    >
                                                    </Empty>
                                            }}
                                        />
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default FlowManagePage;