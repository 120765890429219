import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Typography, Layout, Input, Collapse, Button, InputNumber, Empty, Space } from 'antd';
import { Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Panel } = Collapse;
const { Footer, Header, Sider, Content } = Layout;

//Page des actualités
export default class WebFAQ extends Component {
    constructor(props){
        super(props);

        this.state = {
            questions:[],
            searchText:"",
        };

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    async componentDidMount(){
        this._isMounted = true;

        fetch("/api/questions")
            .then(res => res.json())
            .then(
            (res) => {
                if(this._isMounted){
                    this.setState({questions: res})
                } 
            }
        )           
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){
        return(
            <Layout>
                <Row className="toolsbar" justify='center'> 
                    <Col span={20}>
                        <Search placeholder="Mot clé de votre question" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} size="large" enterButton />
                    </Col>
                </Row>
                <Content className="main-content">                        
                    {/* Liste des questions */}
                        {(this.state.questions.length > 0)?
                            <Collapse accordion expandIconPosition={'right'} > 
                            {this.state.questions.sort(function(a, b){return a.order - b.order}).filter(question => (question.title).toLowerCase().includes(this.state.searchText.toLowerCase())).map((question, index) => { 
                                    return (
                                    <Panel showArrow={true} header={question.order + ". " + question.title} key={index}>
                                        <Row className="ckeditor-html">
                                            <div dangerouslySetInnerHTML={{ __html: question.html }}></div>                                       
                                        </Row>
                                    </Panel>
                                    )
                            })}
                            </Collapse>
                        :<Empty
                        image={<InfoCircleOutlined />}
                        description={this.state.searchText!=""?
                        <span>
                            Il n'y a pas de réponse correspondante à votre recherche
                        </span>
                        :
                        <span>
                            La FAQ est actuellement vide
                        </span>
                        }
                    />
                    }                           
                </Content>
            </Layout>
        )
    }
}

if (document.getElementById('faq')) {
    ReactDOM.render(<WebFAQ />, document.getElementById('faq'));
}
