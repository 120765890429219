import { createStore, combineReducers } from 'redux'
import User from './Reducers/User';
import { loadState, saveState } from './LocalStorage';

//Rassemblement des différents reducers
const rootReducer = combineReducers({
    user: User
})

const persistedState = loadState();

//Création du store
const store = createStore(
    rootReducer,
    persistedState
)

//Sauvegarde du store lors des modifications
store.subscribe(() => {
    saveState(store.getState());
}); 

export default store;