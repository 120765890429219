//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';
import store from '../../Store/Store';
import { ActionCreators } from '../../Store/Action';

//Import des composants de design
import { Typography, Layout, List, Empty } from 'antd';
import { UserProfileCard } from '../../components/LeftPanel';
import { CompanySmallCard } from '../../components/MiddlePanel';
import { UserAccount } from '../../components/Component';
import { Row, Col } from 'antd';
import { HomeOutlined  } from '@ant-design/icons';
const { Title, Text } = Typography;
const { Header, Sider, Content } = Layout;

//Page de l'entreprise
class UserPage extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            user: store.getState().user.profile,
            companies: []
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadUserCompanies();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des entreprises ou travail l'utilisateur
    loadUserCompanies(){
        API.get('api/users/'+store.getState().user.profile.id+'/companies')
        .then((res)=> {
            if(this._isMounted)             
                this.setState({companies: res.data});  

        })
        .catch((err) => {          
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    render(){

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar actual_tab={""}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={12}>
                                <Title level={3}>Bonjour <span className="hightlight">{this.state.user.first_name}</span>,</Title>
                            </Col>
                        </Row>

                        <Row gutter={[32, 16]}>
                            {/* Left Panel */}
                            <Col span={8}>
                                <UserProfileCard user={this.state.user}/>
                            </Col>

                            {/* Middle Panel */}
                            <Col span={8}>
                                <List
                                    pagination={{
                                        pageSize: 6,
                                        hideOnSinglePage:true,
                                        position:'top'
                                    }}
                                    dataSource={this.state.companies}
                                    renderItem={item => (
                                        <CompanySmallCard key={item.id} company={item} user={this.state.user}/>
                                        )
                                    }
                                    locale={{emptyText:<Empty
                                        image={<HomeOutlined />}
                                        description={
                                        <span>
                                            Vous n'avez pas encore rejoint un cabinet
                                        </span>
                                        }
                                    >
                                    </Empty>}}
                                />
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default UserPage;