//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';
import store from '../../Store/Store';
import { ActionCreators } from '../../Store/Action';
import { sortAZ, sortDateInverse, replaceCaracters } from '../../utils';

//Import des composants de design
import { Typography, Layout, Card, Avatar, Input, List, Empty, Segmented,Select } from 'antd';
import { Row, Col } from 'antd';
import { GoToCompanyButton, UserAccount, CompanyApproval } from '../../components/Component';
import { HomeOutlined,BarsOutlined,AppstoreOutlined  } from '@ant-design/icons';
import { AddCompanies } from '../../components/Modal';
const { Title, Text } = Typography;
const { Footer, Header, Sider, Content } = Layout;
const { Search } = Input;
const { Meta } = Card;
const { Option } = Select;

//Carte du cabinet
export function CabinetBigCard(props){
    return (
        <Card 
            cover={props.cabinet.logo_url!=null?<Avatar src={props.cabinet.logo_url} size={146}/>:<Avatar size={146}>{props.cabinet.name.substring(0,3)}</Avatar>}
            actions={[<GoToCompanyButton route={'/company'} company={props.cabinet}/>]}
        >
            <Meta
                title={<Title level={5}>{props.cabinet.name}</Title>}
            />
        </Card>
    );
}

//Carte d'un cabinet List
export function CabinetListCard(props){

    return (
        <List.Item
            actions={[<GoToCompanyButton route={'/company'} company={props.cabinet}/>]}
        >
            <List.Item.Meta
            avatar={props.cabinet.logo_url!=null?<Avatar src={props.cabinet.logo_url} size={34}/>:<Avatar size={34}>{props.cabinet.name.substring(0,3)}</Avatar>}
            title={props.cabinet.name}
            />
        </List.Item>
    );
}

//Page de listing des cabinets
class Cabinets extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            companies:[],
            searchText: "",
            toggleGrid:"kanban",
        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.toggleGrid = this.toggleGrid.bind(this);


        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadCabinetsList();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement de la liste des cabinets
    loadCabinetsList(){
        API.get('/api/companies?type=TYPE_CABINET')
        .then((res) => {

            let companies = res.data;
            sortDateInverse(companies,"created_at");

            if(this._isMounted)
                this.setState({companies: companies})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    handleOnSearch(value){
        let search = value;
        search = replaceCaracters(search);
        this.setState({searchText: search});
    }

    handleOnChangeSearch(event){
        let search = event.target.value;
        search = replaceCaracters(search);
        this.setState({searchText: search});
    }

    handleSort(value){
        switch (value) {
            case 'date':
                sortDateInverse(this.state.companies,"created_at");
                break;
            default:
                sortAZ(this.state.companies,"name");
        }

        this.setState({});
    }

    toggleGrid(event){
        this.setState({toggleGrid:event});
    }


    render(){
         return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar actual_tab={"Cabinets"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={8}>
                                <Title level={3}>Gérer les <span className="hightlight">cabinets</span></Title>
                            </Col>
                            <AddCompanies type={"TYPE_CABINET"} refresh_handler={this.loadCabinetsList.bind(this)}/>
                        </Row>
                        <Row className="toolsbar" justify="space-between">
                            <Row>
                                <Col>
                                    <Search placeholder="Rechercher un cabinet" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Segmented
                                        onChange={this.toggleGrid}
                                        defaultValue={this.state.toggleGrid}
                                        options={[
                                        {
                                            value: 'list',
                                            icon:<BarsOutlined />,
                                        },
                                        {
                                            value: 'kanban',
                                            icon: <AppstoreOutlined />,
                                        }
                                        ]}
                                    />
                                    <Select defaultValue="date" onChange={this.handleSort} bordered={false}>
                                        <Select.Option value="az">A-Z</Select.Option>
                                        <Select.Option value="date">Date</Select.Option>
                                    </Select>
                                </Col>
                            </Row>
                            
                        </Row>

                        <Row gutter={[32, 16]}> 
                        {this.state.toggleGrid=="kanban" ?

                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 6,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 10,
                                    hideOnSinglePage:true,
                                    position:'top'
                                }}
                                dataSource={this.state.companies.filter(company => (replaceCaracters(company.name)).toLowerCase().includes(this.state.searchText.toLowerCase()))}
                                renderItem={item => (
                                    <CabinetBigCard key={item.id} cabinet={item} refresh_handler={this.loadCabinetsList.bind(this)}/>
                                    )
                                }
                                locale={{emptyText:<Empty
                                    image={<HomeOutlined />}
                                    description={this.state.searchText!=""?
                                    <span>
                                        Il n'y a pas de cabinet correspondant à votre recherche
                                    </span>
                                    :
                                    <span>
                                        Il n'y a pas encore de cabinet
                                    </span>
                                    }
                                >
                                    <Row justify="center">
                                        <AddCompanies type={"TYPE_CABINET"} refresh_handler={this.loadCabinetsList.bind(this)}/>
                                    </Row>
                                </Empty>}}
                            />   
                            :
                            <List
                                className="list-three-columns"
                                pagination={{
                                    pageSize: 24,
                                    hideOnSinglePage:true,
                                    position:'top'
                                }}
                                dataSource={this.state.companies.filter(company => (company.name).toLowerCase().includes(this.state.searchText.toLowerCase()))}
                                renderItem={item => (
                                    <CabinetListCard key={item.id} cabinet={item} />
                                    )
                                }
                                locale={{emptyText:<Empty
                                    image={<HomeOutlined />}
                                    description={this.state.searchText!=""?
                                    <span>
                                        Il n'y a pas de cabinet correspondant à votre recherche
                                    </span>
                                    :
                                    <span>
                                        Il n'y a pas encore de cabinet
                                    </span>
                                    }
                                />
                                }}
                            />
                        }                         
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );

    }
}

export default (Cabinets);