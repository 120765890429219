//Import des composants utiles
import React from 'react';
import API from '../api';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

//Import des composants de design
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Input, Card, Button, Typography, Layout, Checkbox } from 'antd';
const { Title, Text } = Typography;
import { Row, Col } from 'antd';


//Page de connexion de l'utilisateur
class PasswordReset extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            email: this.getUrlVars()['email'],
            password_confirm: "",
            password: "",
            token: this.getUrlVars()['token'],
            redirect: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this)

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    //Récupération des variables passé dans l'url
    getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    //Prise en charge de la modification des champs
    handleChange(event){
        if(this._isMounted)
            this.setState({[event.target.name]: event.target.value})
    }

    //Prise en charge de la connection de l'utilisateur
    async save(){
        API.post('/reset-password?token='+this.state.token,{
            email: this.state.email,
            password_confirmation: this.state.password_confirm,
            password: this.state.password,
        })
        .then(() => {
            this.setState({redirect: true})
        })                                                 
        
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){

        if(this.state.redirect){
            return <Redirect to='/' />;
        }else{
            return (
                <section id="reset">
                    <Row id="passwordReset">
                        <Col span={6} offset={3}>
                            <Button shape="circle" icon={<ArrowLeftOutlined  />} size="large" href="/app"/>
                            <Title level={3}>Réinitialisation de votre mot de passe</Title>
                            <Form layout="vertical"> 
                                <Form.Item name="password" label="Mot de passe" normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Veuillez entrer votre mot de passe' },
                                        () => ({
                                            validator(_, value) {
                                                const regex = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=.*[!@#$&*])(?=(.*[0-9]){2,}).{8,}$");
                                                if (!regex.test(value)) {
                                                    return Promise.reject(new Error('Votre mot de passe doit faire minimum 8 caractères comprenant au moins des lettres majuscules et minuscules, 2 chiffres et 1 caractère spécial (!@#$&).'));
                                                }
                                                else{
                                                    return Promise.resolve();
                                                }

                                            
                                            },
                                        })]}>
                                        <Input.Password name={"password"} onChange={this.handleChange} placeholder={"Entrez un mot de passe"}/>
                                    </Form.Item>
                                    <Form.Item name="passwordbis" label="Confirmation du mot de passe" dependencies={['password']} normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Confirmez votre mot de passe' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Vos mots de passe ne sont pas identiques'));
                                            },
                                        }),]}>
                                        <Input.Password name={"password_confirm"} onChange={this.handleChange} placeholder={"Confirmer votre mot de passe"} visibilityToggle={false}/>
                                    </Form.Item>
                                
                            </Form>
                            <Row justify="center"> 
                                <Button type="primary" shape="round" size='large' onClick={this.save}>Valider la modification du mot de passe</Button>
                            </Row>                       
                        </Col>
                    </Row>
                </section>
            );
        }

    }
}

export default PasswordReset;
