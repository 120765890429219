//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';
import { sortAZ, sortDateInverse, replaceCaracters } from '../../utils';

//Import des composants de design
import { Typography, Layout, Card, Avatar, Input, Dropdown, Menu,Select, List, Empty, Badge, Tag, Button, Form,Segmented } from 'antd';
import { Row, Col } from 'antd';
import { GoToUserButton,GoToUserIcon, UserAccount, Tags } from '../../components/Component';
import { DownOutlined,UserAddOutlined,CoffeeOutlined,ClearOutlined,UserOutlined,BarsOutlined,AppstoreOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
const { Footer, Header, Sider, Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { Meta } = Card;

//Carte d'un utilisateur
export function UserBigCard(props){

    const icon = () => {
        if(props.user.role=="ROLE_ADMIN"){
            return <CoffeeOutlined />
        }else{
            let icon = <UserOutlined/>;
            if(props.user.compagnies != undefined){
                props.user.compagnies.forEach((compagny)=>{
                    if(compagny.type=="TYPE_COMPANY"){
                        icon = <ClearOutlined />;
                    }
                })
            }
            return icon;
        }
    }

    const functionLabel = () => {
        switch (props.user.function_label){
            case 'medecin':
                return "Médecin généraliste";
                break;
            case 'secretaire':
                return "Secrétaire";
                break;
            case 'coord':
                return "Coordinat.eur.rice";
                break;
            case 'infi':
                return "Infirmier-ère";
                break;
            case 'kine':
                return "Kinésithérapeute";
                break;
            case 'psy':
                return "Psychologue";
                break;
            case 'assist':
                return "Assistant.e sociale";
                break;
            case 'promot':
                return "Promoteur de la santé";
                break;
            default:
                return "Autre";
        }
    }

    return (
        <Card
            cover={props.user.avatar_url!=null?<Badge count={icon()} offset={[-20, 30]}><Avatar src={props.user.avatar_url} size={146}/></Badge>:<Badge count={icon()} offset={[-20, 30]}><Avatar size={146}>{props.user.first_name.substring(0,1)}{props.user.last_name.substring(0,1)}</Avatar></Badge>}
            actions={[<GoToUserButton route={'/auser'} user={props.user}/>]}
        >
            <Meta
                title={<Title level={5}>{props.user.first_name} {props.user.last_name}</Title>}
                description={functionLabel()}
            />
        </Card>
    );
}

//Carte d'un utilisateur
export function UserListCard(props){

    const functionLabel = () => {
        switch (props.user.function_label){
            case 'medecin':
                return "Médecin généraliste";
                break;
            case 'secretaire':
                return "Secrétaire";
                break;
            case 'coord':
                return "Coordinat.eur.rice";
                break;
            case 'infi':
                return "Infirmier-ère";
                break;
            case 'kine':
                return "Kinésithérapeute";
                break;
            case 'psy':
                return "Psychologue";
                break;
            case 'assist':
                return "Assistant.e sociale";
                break;
            case 'promot':
                return "Promoteur de la santé";
                break;
            default:
                return "Autre";
        }
    }

    return (
        <List.Item
            actions={[<GoToUserIcon route={'/auser'} user={props.user}/>]}
        >
            <List.Item.Meta
            avatar={props.user.avatar_url!=null?<Avatar src={props.user.avatar_url} size={34}/>:<Avatar size={34}>{props.user.first_name.substring(0,1)}{props.user.last_name.substring(0,1)}</Avatar>}
            title={<>{props.user.first_name} {props.user.last_name}</>}
            description={functionLabel()}
            />
        </List.Item>
    );
}

//Page de listing des utilisateurs
export default class Users extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            users:[],
            cabinets:[],
            filter_compagnies:[],
            searchText: "",
            filter_admin:true,
            filter_user:true,
            filter_other:true,
            filter_function:[],
            toggleGrid:"kanban",

        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleChangeFunction = this.handleChangeFunction.bind(this);
        this.handleChangeCompagny = this.handleChangeCompagny.bind(this);
        this.handleSearchSelect = this.handleSearchSelect.bind(this);
        this.toggleGrid = this.toggleGrid.bind(this);
        this.filterUsers = this.filterUsers.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadUsers();
        this.loadCabinet();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des utilisateurs
    loadUsers(){
        API.get('/api/users')
        .then((res) => {
            let users = res.data;
            users.forEach(async (user) => {
                const compagnies = await API.get('api/users/'+user.id+'/companies');
                user["compagnies"] = compagnies.data;
                sortDateInverse(users,"created_at");

                if(this._isMounted)
                    this.setState({users: users})
            })
        })
        .catch((err) => {
            console.log(err)
        })
    }

    //Chargement de la liste de cabinets
    loadCabinet(){
        API.get('/api/companies')
        .then((res) => {
            res.data.forEach(cabinet => {
                cabinet["selected"] = false;
            });

            if(this._isMounted)
                this.setState({cabinets: res.data})
        })
        .catch((err) => {
            console.log(err)
        })
    }

    filterUsers(){
        const users = [];

        if(this.state.tags.length>0){
            this.state.tags.forEach((tag) => {
                API.get('/api/companies/'+tag.id+"/users")
                    .then((res) => {
                        res.data.forEach((user) => {
                            users.push(user)
                            sortDateInverse(users,"created_at");
                        })
    
                        if(this._isMounted)
                            this.setState({users: users})
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        }else{
            this.loadUsers();
        }

        
    }

    handleOnSearch(value){
        let search = value;
        search = replaceCaracters(search);
        this.setState({searchText: search});
    }

    handleOnChangeSearch(event){
        let search = event.target.value;
        search = replaceCaracters(search);
        this.setState({searchText: search});
    }

    handleFilter(value){
        this.setState(value)
    }

    handleSort(value){

        switch (value) {
            case 'date':
                sortDateInverse(this.state.users,"created_at");
                break;
            default:
                sortAZ(this.state.users,"last_name");
        }

        this.setState({});
    }

    handleChangeFunction(event){
        this.setState({filter_function:event})
    }

    handleChangeCompagny(event){
        console.log(event);
        this.setState({filter_compagnies:event})
    }

    handleSearchSelect(inputValue,option){
        if(option.value.includes(replaceCaracters(inputValue))){
            return true;
        }
    }

    toggleGrid(event){
        this.setState({toggleGrid:event});
    }

    render(){
        let filterUsers=[];
        this.state.users.filter(user => replaceCaracters(user.first_name+" "+user.last_name).toLowerCase().includes(this.state.searchText.toLowerCase())).forEach(user => {

            let haveCompagny = false;
            if(this.state.filter_compagnies.length>0){
                user.compagnies.forEach(compagny => {
                    if(this.state.filter_compagnies.includes(compagny.name)){
                        haveCompagny = true;
                    }
                })
            }else{
                haveCompagny = true;
            }

            let haveFunction = false;
            if(this.state.filter_function.length>0){
                haveFunction = this.state.filter_function.includes(user.function_label);
            }else{
                haveFunction = true;
            }

            if(haveCompagny && haveFunction){
                if(this.state.filter_admin && user.role == "ROLE_ADMIN"){
                    filterUsers.push(user);
                    return;
                }
                if(user.compagnies != undefined){
                    user.compagnies.forEach((compagny)=>{
                        if(this.state.filter_other && compagny.type=="TYPE_COMPANY" && user.role != "ROLE_ADMIN"){
                            filterUsers.push(user);
                            return;
                        }
                    })
                }
                if(this.state.filter_user && user.role != "ROLE_ADMIN" && !filterUsers.includes(user)){
                    filterUsers.push(user);
                }
            }
        })      

        //Vérification de la connexion de l'utilisateur
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar actual_tab={"Users"}/>
                </Sider>
                <Layout className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row>
                            <Title level={3}>Gérer les <span className="hightlight">utilisateurs</span></Title>
                        </Row>
                        <Row className="toolsbar" justify="space-between">
                            <Row>
                                <Col>
                                    <Search placeholder="Rechercher un utilisateur" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton bordered={false}/>
                                </Col>
                                <Col className="filtersbuttons">
                                    {/* Filtre */}
                                    <Row>
                                        <Button onClick={() => this.handleFilter({filter_admin:!this.state.filter_admin})} type={this.state.filter_admin?"default":"text"} shape="circle" icon={<CoffeeOutlined />} />
                                        <Button onClick={() => this.handleFilter({filter_user:!this.state.filter_user})} type={this.state.filter_user?"default":"text"} shape="circle" icon={<UserOutlined />} />
                                        <Button onClick={() => this.handleFilter({filter_other:!this.state.filter_other})} type={this.state.filter_other?"default":"text"} shape="circle" icon={<ClearOutlined />} />
                                        
                                        <Form layout="inline">
                                            {(this.state.cabinets.length > 1)&&
                                                <Form.Item>
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Filtrer par cabinet"
                                                        style={{ width: '100%' }}
                                                        onChange={this.handleChangeCompagny}
                                                        filterOption={this.handleSearchSelect}
                                                    >
                                                        {this.state.cabinets.map((cabinet, index) => {
                                                                return <Option key={cabinet.id} value={replaceCaracters(cabinet.name).toLowerCase()}>{cabinet.name}</Option>
                                                        })} 
                                                    </Select>
                                                </Form.Item>
                                            }
                                            <Form.Item>
                                                <Select
                                                    onChange={this.handleChangeFunction}
                                                    filterOption={this.handleSearchSelect}
                                                    placeholder="Filtrer par fonction"
                                                    mode="multiple"
                                                    options={[
                                                        {
                                                        value: 'medecin',
                                                        label: 'Médecin généraliste',
                                                        },
                                                        {
                                                        value: 'secretaire',
                                                        label: 'Secrétaire',
                                                        },
                                                        {
                                                        value: 'coord',
                                                        label: 'Coordinat.eur.rice',
                                                        },
                                                        {
                                                        value: 'infi',
                                                        label: 'Infirmier-ère',
                                                        },
                                                        {
                                                        value: 'kine',
                                                        label: 'Kinésithérapeute',
                                                        },
                                                        {
                                                        value: 'psy',
                                                        label: 'Psychologue',
                                                        },
                                                        {
                                                        value: 'assist',
                                                        label: 'Assistant.e sociale',
                                                        },
                                                        {
                                                        value:'promot',
                                                        label: 'Promoteur de la santé',
                                                        },
                                                        {
                                                        value: 'autre',
                                                        label: 'Autre',
                                                        }
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Segmented
                                        onChange={this.toggleGrid}
                                        defaultValue={this.state.toggleGrid}
                                        options={[
                                        {
                                            value: 'list',
                                            icon:<BarsOutlined />,
                                        },
                                        {
                                            value: 'kanban',
                                            icon: <AppstoreOutlined />,
                                        }
                                        ]}
                                    />
                                    <Select defaultValue="date" onChange={this.handleSort} bordered={false}>
                                        <Select.Option value="az">A-Z</Select.Option>
                                        <Select.Option value="date">Date</Select.Option>
                                    </Select>
                                </Col>
                            </Row>
                            
                        </Row>

                        <Row gutter={[32, 16]}> 
                            {
                                this.state.toggleGrid=="kanban" ?
                                <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 6,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                    position:'top'
                                }}
                                dataSource={filterUsers}
                                renderItem={item => (
                                    <UserBigCard key={item.id} user={item} />
                                    )
                                }
                                locale={{emptyText:<Empty
                                    image={<UserAddOutlined />}
                                    description={this.state.searchText!=""?
                                    <span>
                                        Il n'y a pas d'utilisateur correspondant à votre recherche
                                    </span>
                                    :
                                    <span>
                                        Il n'y a pas encore d'utilisateur
                                    </span>
                                    }
                                />
                                }}
                            />
                            :
                            <List
                                className="list-three-columns"
                                pagination={{
                                    pageSize: 24,
                                    hideOnSinglePage:true,
                                    position:'top'
                                }}
                                dataSource={filterUsers}
                                renderItem={item => (
                                    <UserListCard key={item.id} user={item} />
                                    )
                                }
                                locale={{emptyText:<Empty
                                    image={<UserAddOutlined />}
                                    description={this.state.searchText!=""?
                                    <span>
                                        Il n'y a pas d'utilisateur correspondant à votre recherche
                                    </span>
                                    :
                                    <span>
                                        Il n'y a pas encore d'utilisateur
                                    </span>
                                    }
                                />
                                }}
                            />

                            }                            
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );

    }
}
