import { Types } from '../Action';

//Etat initial de l'utilisateur
const initialState = {
  profile: {},
  selected_campaign: null,
  selected_company: null,
  active_tab:"1",
  company:{},
  selected_user: null,
  isLogged: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return {
        ...state,
        profile: action.payload.user,
        isLogged: true,
      }

    case Types.LOGOUT:
      window.localStorage.clear();
      return {
        ...state,
        profile: {},
        selected: null,
        isLogged: false,
      }

    case Types.UPDATE_USER:
      return {
        ...state,
        profile: action.payload.user,
      }

    case Types.UPDATE_USER_AVATAR:
      return {
        ...state,
        profile: {
          avatar_url : action.payload.url,
          ...state,
        }
      }

    case Types.UPDATE_SELECTEDUSER:
      return {
        ...state,
        selected_user: action.payload.selected_id,
      }

    case Types.UPDATE_SELECTEDCAMPAIGN:
      return {
        ...state,
        selected_campaign: action.payload.selected_id,
      }

    case Types.UPDATE_SELECTEDCOMPANY:
      return {
        ...state,
        selected_company: action.payload.selected_id,
        company:action.payload.company,
      }
    
    case Types.UPDATE_ACTIVETAB:
      return {
        ...state,
        active_tab: action.payload.index,
      }

    default:
      return state;
  }
}

export default reducer;