//Import des composants utiles
import React from 'react';
import { Link } from 'react-router-dom';

//Import des composants de design
import { Typography, Layout, Button } from 'antd';
const { Title,Text } = Typography;
const { Header, Content } = Layout;
import { Row, Col } from 'antd';


//Page de connexion de l'utilisateur
class FinalStepRegisterPage extends React.Component{
    constructor(props){
        super(props)

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        return (
            <section id="finalregister">
                <Row>
                    <Col span={6} offset={3}>
                        <Title level={3}>Félicitation !</Title>
                        <Text>Vous avez rejoins la plateforme Medisplay. Pour confirmer votre inscription, veuillez <strong>cliquer sur le lien dans le mail</strong> que vous venez de recevoir.
                        Attention, ce mail peut se retrouver dans vos <strong> courriers indésirables ou dans un dossier "Promotion"</strong>.</Text>
                        <Link to={{pathname:"/"}}><Button type="primary" shape="round" size='large'>Ok, j'ai compris</Button></Link>
                        
                    </Col>
                </Row>
            </section>
        );
    }
}

export default FinalStepRegisterPage;