//Import des composants utiles
import React from 'react';
import { Link } from "react-router-dom";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { RRule } from 'rrule';

import store from '../Store/Store';
import API from '../api';
import { ActionCreators } from '../Store/Action';
import { Redirect } from 'react-router-dom';

//Import des composants de design
import { Tag, Card, Button, Typography, Layout, Spin, Popconfirm, notification,Space, Avatar, Menu, Dropdown, Form, Input, InputNumber, List, Skeleton, Descriptions,Badge} from 'antd';
import { CheckCircleOutlined,StopOutlined,CopyOutlined, InteractionOutlined,FilePptOutlined, VideoCameraOutlined, ShareAltOutlined, SyncOutlined,TagOutlined ,FileOutlined, LoadingOutlined,BorderOutlined,CloseOutlined,CheckSquareFilled, DownOutlined, DeleteOutlined ,EyeOutlined,ArrowLeftOutlined,ArrowRightOutlined,NotificationOutlined,FundProjectionScreenOutlined,FileAddOutlined, DesktopOutlined,EditOutlined,HeartOutlined,HeartFilled,PictureOutlined,DownloadOutlined  } from '@ant-design/icons';
const { TextArea } = Input;
import { useState } from 'react';
import { ChangeFile } from '../components/Modal';
const { Title, Text } = Typography;
const { Footer, Header, Sider, Content } = Layout;
import { Row, Col } from 'antd';

//Navigation profile utilisateur
export function UserAccount(props){
    const [user, setUser] = useState(store.getState().user.profile)
    const [redirect, setRedirect] = useState(false)

    const Disconnect = () => {
        API.post('/logout')
        .then(() => {
            store.dispatch(ActionCreators.logout()); 
            setRedirect(true)   
        }).catch(err => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
                setRedirect(true)
            }
        })
    }

    const menu = (
        <Menu>
            <Menu.Item key="1"><Link to="/profile">Mon Profil</Link></Menu.Item>
            <Menu.Item  key="2" danger onClick={Disconnect}>Se Deconnecter</Menu.Item>
        </Menu>
    )

    if(!redirect && store.getState().user.isLogged){
        return (
            <Space>
                {/* Avatar */}
                {user.avatar_url!=null?<Avatar src={user.avatar_url}/>:<Avatar className='smallAvatar'>{user.first_name.substring(0,1)}{user.last_name.substring(0,1)}</Avatar>}
                <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <Text>{user.first_name} {user.last_name}</Text> <DownOutlined />
                    </a>
                </Dropdown>
            </Space>
        );
    }else{
        return <Redirect to='/' />;
    }
}

//Bouton principale pour la sélection d'une entreprise
export function GoToCompanyButton(props){
    const handleClick = () => {store.dispatch(ActionCreators.updateSelectedCompany(props.company.id,props.company));}

    if(props.company.type=="TYPE_CABINET"){
        store.dispatch(ActionCreators.updateActiveTab("1"));
    }else{
        store.dispatch(ActionCreators.updateActiveTab("2"));
    }
    

    return(
        <Link to={{pathname:props.route,state:{company:props.company}}} >
            <Button type="primary" shape="round" onClick={handleClick}>Consulter</Button>
        </Link>
    );
}

//Bouton principale pour la sélection d'un utilisateur
export function GoToUserButton(props){
    const handleClick = () => {store.dispatch(ActionCreators.updateSelectedUser(props.user.id));}

    return(
        <Link to={{pathname:props.route,state:{user:props.user}}}>
            <Button type="primary" shape="round" onClick={handleClick}>Consulter</Button>
        </Link>
    );
}

export function GoToUserIcon(props){
    const handleClick = () => {store.dispatch(ActionCreators.updateSelectedUser(props.user.id));}

    return(
        <Link to={{pathname:props.route,state:{user:props.user}}}>
            <Button onClick={handleClick} type="text" shape="circle" icon={<EyeOutlined/>}/>
        </Link>
    );
}

//Bouton d'action d'approbation d'un cabinet
export function CompanyApproval(props){
    const handleApprove = () => { API.put('api/companies/'+props.company.id,{approuved: true}).then(() => props.refresh_handler())}

    const handleDenied = () => { API.put('api/companies/'+props.company.id,{approuved: false}).then(() => props.refresh_handler())}

    return(
        <>
            <Button type="text" shape="round" onClick={handleDenied} danger>Refuser</Button>
            <Button type="primary" shape="round" onClick={handleApprove}>Approuver</Button>
        </>
    );
}

//Sous-Titre de retour -
export function GoBackTitle(props){
    return(     
        <Link to={props.route} className="backbutton">
            <Button type="text" shape="round" icon={<ArrowLeftOutlined />}>
                {props.title}
            </Button>
        </Link>
    );
}

//Bouton création de diffusion -
export function StreamBtn(props){
    return(     
        <Link to={{pathname:props.route}}>   
            <Button type="primary" shape="round" icon={<NotificationOutlined />}>
                Créer une diffusion
            </Button>             
        </Link>
    );
}

//Bouton création de diffusion -
export function FlowBtn(props){
    return(     
        <Link to={{pathname:props.route}}>   
            <Button type="primary" shape="round" icon={<InteractionOutlined />}>
                Créer un flux
            </Button>             
        </Link>
    );
}

//Bouton ajouter une campagne -
export function CampaignAddBtn(props){
    return(     
        <Link to={{pathname:props.route}}>   
            <Button type="primary" shape="round" icon={<FundProjectionScreenOutlined />}>
                Ajouter une campagne
            </Button>             
        </Link>
    );
}

//Bouton ajouter un fichier -
export function AddFileBtn(props){
    return(     
        <Link to={{pathname:props.route}}>   
            <Button type="primary" shape="round" icon={<FileAddOutlined />} size="large">
                Ajouter un fichier
            </Button>             
        </Link>
    );
}

//Carte de vue d'une campagne -
export function CampaignSmallCard(props){
    const handleClick = () => {store.dispatch(ActionCreators.updateSelectedCampaign(props.campaign.id));}

    const handleFav = () => {
        if(props.favorite){
            API.delete('api/users/'+store.getState().user.profile.id+'/campaigns/'+props.campaign.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }else{
            API.put('api/users/'+store.getState().user.profile.id+'/campaigns/'+props.campaign.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }
    }

    const deleteCampaign = () => {
        try {
            API.delete('api/campaigns/'+props.campaign.id)
            .then(() => {
                props.refresh_handler();
            })
        } catch (err){
            throw new Error('Error When Deleting File');
        }
    }

    const publishCampaign = () => {
        API.put('api/campaigns/'+props.campaign.id,{
            public: true,
        })
            .then(() => {
                props.refresh_handler();
        })
    }

    const copyCampaign = () => {
        var files = [];
        let campagn_files = [];

        API.get('/api/campaigns/'+props.campaign.id+'/files')
        .then((res) => {
            res.data.forEach(async (file) => {
                /*const tags = await API.get('/api/files/'+file.id+"/tags")
                file["tags"] = tags.data*/

                file["order"] = file.pivot.order
                file["duration"] = file.pivot.duration

                files.push(file)

                campagn_files = files.sort(function (a, b) {
                    return a.order - b.order;
                });
            });

            const filesParameters = []

            //Ajout des fichiers à la campagne
            campagn_files.forEach((file, index) => {
                var newfile = {
                    "file_id":file.id,
                    "order":index,
                    "duration":file.duration
                }
                filesParameters.push(newfile);
            })

            API.post('api/campaigns',{
                name: props.campaign.name+" - Copie",
                company_id: store.getState().user.selected_company
            })
            .then((res) => {

                API.put('api/campaigns/'+res.data.id+'/files',
                    filesParameters
                )
                .then(() => {
                    notification.success({message: 'Vous avez créé une nouvelle campagne !',duration:4.5})
                    props.refresh_handler();
                })
            })
            .catch((err) => {
                notification.error({message: '"Erreur lors de la création de la campagne !"',duration:4.5})
            })

        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })

    }

    var actions=[];

    /** Actions configuration */
    if(!props.campaign.in_creation)
        actions = [<Button onClick={handleFav} type="text" shape="circle" icon={props.favorite?<HeartFilled/>:<HeartOutlined/>}/>];
    if(!props.campaign.in_creation && !props.campaign.public && store.getState().user.company.type == "TYPE_COMPANY")
        actions.push(<Popconfirm
            title={<Space direction="vertical"><Text strong>Voulez-vous partager cette campagne ?</Text><Text type="secondary">Attention, en rendant la campagne publique, vous ne pourrez plus la modifier</Text></Space>}
            onConfirm={publishCampaign}
            cancelButtonProps={{type:'text',shape:'round'}}
            okButtonProps={{type:'primary',shape:'round'}}
            okText="Publier"
            cancelText="Annuler"
          >
              <Button type="text" shape="circle" icon={<ShareAltOutlined/>}/>
            </Popconfirm>);
    if(!props.campaign.in_creation && !props.campaign.public)
        actions.push(<Link to={{pathname:"/modifycampaign",state:{campaign:props.campaign}}}>   
                        <Button type="text" shape="circle" icon={<EditOutlined />}/>          
                    </Link>)
    if(!props.campaign.in_creation)
        actions.push(<Button onClick={copyCampaign} type="text" shape="circle"  icon={<CopyOutlined />}/>)
    if(!props.campaign.in_creation)
        actions.push(<Button onClick={() => window.open(props.campaign.file_url, '_blank')} type="text" shape="circle" icon={<EyeOutlined/>}/>)
    if(props.campaign.in_creation){
        actions.push(<Tag icon={<SyncOutlined spin />} className="inprogress" color="default"/>)
    }
    if(props.campaign.company.id == store.getState().user.company.id){
        actions.push(<Popconfirm
            title={<Space direction="vertical"><Text strong>Voulez-vous supprimer définitivement la campagne ?</Text><Text type="secondary">En supprimant une campagne, elle sera automatiquement retirée des listes de diffusion</Text></Space>}
            onConfirm={deleteCampaign}
            cancelButtonProps={{type:'text',shape:'round'}}
            okButtonProps={{type:'primary',shape:'round', danger:true}}
            okText="Supprimer"
            cancelText="Annuler"
          ><Button type="text" shape="circle" icon={<DeleteOutlined/>} danger/></Popconfirm>)
    }


    var date = new Date(props.campaign.updated_at);

    return (
        <List.Item
            actions={actions}
        >
            {props.campaign.public?
            <List.Item.Meta
            avatar={props.campaign.company.logo_url!=null?<Badge count={<ShareAltOutlined/>} offset={[0, 10]}><Avatar size={34} src={props.campaign.company.logo_url}/></Badge>:<ShareAltOutlined />}
            title={props.campaign.name}
            description={props.campaign.company.name}
            />
            :
            <List.Item.Meta
                avatar={<FundProjectionScreenOutlined />}
                title={props.campaign.name}
                description={"Dernière modification le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
              />
            }
            {/*{(props.campaign.tags.length > 0)?
                props.campaign.tags.map((tag, index) => {
                        return  <Text key={index} style={{fontSize:9, fontWeight:400, color:'#00A887', marginTop:4, marginRight:5}}> #{tag.label} </Text>
                })
            :<></>}  */}

        </List.Item>
    );
}

//Carte de vue d'une campagne extérieur
export function AllCampaignSmallCard(props){
    const handleFav = () => {
        if(props.favorite){
            API.delete('api/users/'+store.getState().user.profile.id+'/campaigns/'+props.campaign.id)
            .then(() => {
                props.refresh_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }else{
            API.put('api/users/'+store.getState().user.profile.id+'/campaigns/'+props.campaign.id)
            .then(() => {
                props.refresh_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }
    }

    var actions=[];

    if(props.campaign.in_creation){
        actions=[<Button onClick={handleFav} type="text" shape="circle" icon={props.favorite?<HeartFilled />:<HeartOutlined/>}/>]
    }else{
        actions=[<Button onClick={handleFav} type="text" shape="circle" icon={props.favorite?<HeartFilled />:<HeartOutlined/>}/>,
        <Button onClick={() => window.open(props.campaign.file_url, '_blank')} type="text" shape="circle" icon={<EyeOutlined/>}/>
        ]
    }

    var date = new Date(props.campaign.created_at);

    return (
        <List.Item
            actions={actions}
        >
            <List.Item.Meta
                avatar={props.campaign.company.logo_url!=null?<Avatar size={34} src={props.campaign.company.logo_url}/>:<FundProjectionScreenOutlined />}
                title={props.campaign.name}
                description={"Ajouté le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
              />        
            {/*{(props.campaign.tags.length > 0)?
                props.campaign.tags.map((tag, index) => {
                        return  <Text key={index} style={{fontSize:9, fontWeight:400, color:'#00A887', marginTop:4, marginRight:5}}> #{tag.label} </Text>
                })
            :<></>}  */}
        </List.Item>
    );
}

//Carte de vue d'un fichier -
export function FileSmallCard(props){

    //Librairie de téléchargement automatique
    const fileDownload = require('js-file-download');

    const downloadFile = async () => {
        try {
            const res = await API.get(props.file.url, {
                responseType: 'blob'
            });

            fileDownload(res.data, props.file.name)
        } catch (err){
            throw new Error('Error When Downloading File');
        }
    }

    const handleFav = () => {
        if(props.favorite){
            API.delete('api/users/'+store.getState().user.profile.id+'/files/'+props.file.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }else{
            API.put('api/users/'+store.getState().user.profile.id+'/files/'+props.file.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }
    }

    const deleteFile = () => {
        try {
            API.delete('api/files/'+props.file.id)
            .then(() => {
                props.refresh_handler();
            })
        } catch (err){
            throw new Error('Error When Deleting File');
        }
    }

    var date = new Date(props.file.created_at);

    let icon = <FileOutlined />;

    switch(props.file.type){
        case "TYPE_IMAGE" :
            icon = <PictureOutlined />;
            break;
        case "TYPE_VIDEO" :
            icon = <VideoCameraOutlined />;
            break;
        default :
            icon = <FilePptOutlined />;
    }

    return (
        <List.Item
            actions={[<Button onClick={handleFav} type="text" shape="circle" icon={props.favorite?<HeartFilled />:<HeartOutlined/>}/>,
            <Button onClick={downloadFile} type="text" shape="circle" icon={<DownloadOutlined />}/>,
            <Button onClick={() => window.open(props.file.url, '_blank')} type="text" shape="circle" icon={<EyeOutlined/>}/>,
            <Button onClick={deleteFile} type="text" shape="circle" icon={<DeleteOutlined />} danger/>
            ]}
        >
            <List.Item.Meta
                avatar={icon}
                title={props.file.name}
                description={"Ajouté le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
              />
            {/*{(props.file.tags.length > 0)?
                props.file.tags.map((tag, index) => {
                return  <Text key={index} style={{fontSize:9, fontWeight:400, color:'#00A887', marginTop:4, marginRight:5}}> #{tag.label} </Text>
                })
            :<></>} */}
        </List.Item>
    );
}

//Carte de vue d'un fichier pouvant être ajouter à la campagne
export function CampaignFileSmallCard(props){
    const handleFav = () => {
        if(props.favorite){
            API.delete('api/users/'+store.getState().user.profile.id+'/files/'+props.file.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }else{
            API.put('api/users/'+store.getState().user.profile.id+'/files/'+props.file.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }
    }

    let icon = <FileOutlined />;

    switch(props.file.type){
        case "TYPE_IMAGE" :
            icon = <PictureOutlined />;
            break;
        case "TYPE_VIDEO" :
            icon = <VideoCameraOutlined />;
            break;
        default :
            icon = <FilePptOutlined />;
    }

    var date = new Date(props.file.created_at);

    return (
        <List.Item
            actions={[<Button type="text" shape="circle" onClick={handleFav} icon={<HeartOutlined />}/>,
        <Button onClick={() => window.open(props.file.url, '_blank')} type="text" shape="circle" icon={<EyeOutlined />}/>,
        <Button onClick={() => {props.add_handler()}} type="text" shape="circle" icon={<ArrowRightOutlined />}/>]
        }
        >
            <List.Item.Meta
                avatar={icon}
                title={props.file.name}
                description={"Ajouté le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
              />
              {/*{(props.file.tags.length > 0)?
                        props.file.tags.map((tag, index) => {
                                return  <Text key={index} style={{fontSize:9, fontWeight:400, color:'#00A887', marginTop:4, marginRight:5}}> #{tag.label} </Text>
                        })
                    :<></>}*/}


        </List.Item>
    );
}

//Carte d'un fichier ajouter à la campagne
export function CampaignAddedFileSmallCard(props){
    const handleChange = (value) => {
        props.handle_change(value)
    }

    var date = new Date(props.file.created_at);
    var ext = props.file.name.substr(-3,3);

    return (
        <List.Item
            actions={[<Button onClick={() => window.open(props.file.url, '_blank')} type="text" shape="circle" icon={<EyeOutlined/>}/>]}
        >
            <List.Item.Meta
                avatar={<FundProjectionScreenOutlined />}
                title={props.file.name}
                description={"Ajouté le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
              />
              <Button onClick={() => {props.remove_handler()}} type="text" shape="circle" icon={<ArrowLeftOutlined />}/>
            {ext!="mp4"&&
            <Form layout="vertical" className="formList">
                    <Form.Item label={ext=='ptx'?'Secondes par slide':'Secondes'}>     
                        <InputNumber min={1} defaultValue={props.file.duration} onChange={handleChange}/>
                    </Form.Item>
            </Form>}
            {/*{(props.file.tags.length > 0)?
                props.file.tags.map((tag, index) => {
                        return  <Text key={index} style={{fontSize:9, fontWeight:400, color:'#00A887', marginTop:4, marginRight:5}}> #{tag.label} </Text>
                })
            :<></>}  */}

        </List.Item>
    );
}

//Carte de vue d'un fichier pouvant être ajouter à la campagne
export function FlowStreamSmallCard(props){
    
    const rule = RRule.fromString(props.broadcast.schedule)
    const datestart= new Date(rule.all()[0]);
    const dateend=new Date(rule.all()[rule.all().length-1]);

    return (
        <List.Item
            actions={[
        <Button onClick={() => window.open(props.broadcast.file_url, '_blank')} type="text" shape="circle" icon={<EyeOutlined />}/>,
        <Button onClick={() => {props.add_handler()}} type="text" shape="circle" icon={<ArrowRightOutlined />}/>]
        }
        >
            <List.Item.Meta
                title={props.broadcast.campaign.name}
            />
            <Descriptions title="Du" column="1">
                <Descriptions.Item>{datestart.getDate()}/{datestart.getMonth()+1}/{datestart.getFullYear()}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Au" column="1">
                <Descriptions.Item>{dateend.getDate()}/{dateend.getMonth()+1}/{dateend.getFullYear()}</Descriptions.Item>
            </Descriptions>
        </List.Item>
    );
}

//Carte de vue d'un fichier pouvant être ajouter à la campagne
export function FlowStreamManageSmallCard(props){
    
    const rule = RRule.fromString(props.broadcast.schedule)
    const datestart= new Date(rule.all()[0]);
    const dateend=new Date(rule.all()[rule.all().length-1]);

    let blacklist = [];

    if(props.pivot.blacklist != null){
        blacklist = props.pivot.blacklist;
    }   

    const handleBlacklist = (broadcast_id) => {
        if(blacklist.includes(props.broadcast.id)){
            blacklist = blacklist.filter(id => id!=props.broadcast.id);
        }else{
            blacklist.push(broadcast_id);
        }
        API.put('api/company-flows/'+props.pivot.id,{
            blacklist: blacklist,
        })
        .then(() => {
            props.refresh_handler();
        })
        .catch((err) => {
            if(err.response != null && (err.response.status == 401)){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    return (
        <List.Item
            actions={[<Button onClick={() => window.open(props.broadcast.file_url, '_blank')} type="text" shape="circle" icon={<EyeOutlined />}/>,<Button onClick={() => handleBlacklist(props.broadcast.id)} type="text" shape="circle" icon={blacklist.includes(props.broadcast.id)?<CheckCircleOutlined />:<StopOutlined />} danger={!(blacklist.includes(props.broadcast.id))}/>]}
        >
            <List.Item.Meta
                title={props.broadcast.campaign.name}
            />
            <Descriptions title="Du" column="1">
                <Descriptions.Item>{datestart.getDate()}/{datestart.getMonth()+1}/{datestart.getFullYear()}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Au" column="1">
                <Descriptions.Item>{dateend.getDate()}/{dateend.getMonth()+1}/{dateend.getFullYear()}</Descriptions.Item>
            </Descriptions>
        </List.Item>
    );
}

export function FlowAddedStreamSmallCard(props){
    const handleChange = (value) => {
        props.handle_change(value)
    }

    const rule = RRule.fromString(props.broadcast.schedule)
    const datestart= new Date(rule.all()[0]);
    const dateend=new Date(rule.all()[rule.all().length-1]);

    return (
        <List.Item
            actions={[<Button onClick={() => window.open(props.broadcast.url, '_blank')} type="text" shape="circle" icon={<EyeOutlined/>}/>]}
        >

            <Button onClick={() => {props.remove_handler()}} type="text" shape="circle" icon={<ArrowLeftOutlined />}/>
            <List.Item.Meta
                title={props.broadcast.campaign.name}
            />
            <Descriptions title="Du" column="1">
                <Descriptions.Item>{datestart.getDate()}/{datestart.getMonth()+1}/{datestart.getFullYear()}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Au" column="1">
                <Descriptions.Item>{dateend.getDate()}/{dateend.getMonth()+1}/{dateend.getFullYear()}</Descriptions.Item>
            </Descriptions>
        </List.Item>
    );
}

//Carte d'une campagne ajouter à la campagne
export function StreamCampaignSmallCard(props){

    const handleFav = () => {
        if(props.favorite){
            API.delete('api/users/'+store.getState().user.profile.id+'/campaigns/'+props.campaign.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }else{
            API.put('api/users/'+store.getState().user.profile.id+'/campaigns/'+props.campaign.id)
            .then(() => {
                props.favorite_handler();
            })
            .catch((err) => {
                if(err.response != null && (err.response.status == 401)){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }
    }

    var actions=[];

    if(props.campaign.in_creation){
        actions=[
            <Button onClick={handleFav} type="text" shape="circle" icon={props.favorite?<HeartFilled />:<HeartOutlined/>}/>,
            <Button onClick={props.select_handler} shape="circle" type="text" icon={!props.selected?<BorderOutlined />:<CheckSquareFilled />}/>
        ];
    }else{
        actions=[
            <Button onClick={handleFav} type="text" shape="circle" icon={props.favorite?<HeartFilled />:<HeartOutlined/>}/>,
            <Button onClick={() => window.open(props.campaign.file_url, '_blank')} type="text" shape="circle" icon={<EyeOutlined />}/>,
            <Button onClick={props.select_handler} shape="circle" type="text" icon={!props.selected?<BorderOutlined />:<CheckSquareFilled />}/>
        ];
    }

    var date = new Date(props.campaign.updated_at);

    return (
        <List.Item
            actions={actions}
        >

            {props.campaign.public?
            <List.Item.Meta
            avatar={props.campaign.company.logo_url!=null?<Badge count={<ShareAltOutlined/>} offset={[0, 10]}><Avatar size={34} src={props.campaign.company.logo_url}/></Badge>:<ShareAltOutlined />}
            title={props.campaign.name}
            description={"Modifié le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
            />
            :
            <List.Item.Meta
                avatar={<FundProjectionScreenOutlined />}
                title={props.campaign.name}
                description={"Modifié le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
              />
            }
                {/*{(props.campaign.tags.length > 0)?
                    props.campaign.tags.map((tag, index) => {
                            return  <Text key={index} style={{fontSize:9, fontWeight:400, color:'#00A887', marginTop:4, marginRight:5}}> #{tag.label} </Text>
                    })
                :<></>}  */}
        </List.Item>
    );
}

//Petit Tag
export function Tags(props){
    const remove = () => {props.remove_handler()}
    
    return (
        <Layout style={{display:'flex', height:25, backgroundColor:'#00A887', borderRadius:5, justifyContent:'space-evenly', margin:5, paddingLeft:5, paddingRight:5}}>
            <Content style={{display:'flex', alignItems:'center', padding:'0 5px'}}>
                <Button style={{border:'none', background:'transparent'}} onClick={remove}>
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.585 5.5L10.7122 2.37281C11.0959 1.98906 11.0959 1.36687 10.7122 0.982812L10.0172 0.287813C9.63344 -0.0959375 9.01125 -0.0959375 8.62719 0.287813L5.5 3.415L2.37281 0.287813C1.98906 -0.0959375 1.36688 -0.0959375 0.982813 0.287813L0.287813 0.982812C-0.0959375 1.36656 -0.0959375 1.98875 0.287813 2.37281L3.415 5.5L0.287813 8.62719C-0.0959375 9.01094 -0.0959375 9.63312 0.287813 10.0172L0.982813 10.7122C1.36656 11.0959 1.98906 11.0959 2.37281 10.7122L5.5 7.585L8.62719 10.7122C9.01094 11.0959 9.63344 11.0959 10.0172 10.7122L10.7122 10.0172C11.0959 9.63344 11.0959 9.01125 10.7122 8.62719L7.585 5.5Z" fill="white"/>
                    </svg>
                </Button>
                <Text style={{fontSize:14, fontWeight:400, color:'#FFFFFF'}}> {props.title} </Text>
            </Content>
        </Layout>
    )
}

//Carte d'un tag
export function TagSmallCard(props){
    const deleteTag = () => {
        API.delete('/api/tags/'+props.tag.id).then(props.refresh_handler());
    }

    return (
        <List.Item
            actions={[<Button type="text" shape="circle" onClick={deleteTag} size="default" icon={<DeleteOutlined />} danger/>]}
            >
                    <List.Item.Meta
                        avatar={<TagOutlined />}
                        title={props.tag.label}
                    />
        </List.Item>
    );
}

export function Loading(){
    const antIcon = <LoadingOutlined style={{ fontSize: 80, color:'orange' }} spin />;

    return (
        <>
            <Spin indicator={antIcon} style={{margin:'auto', paddingBottom:100}}/>
        </>
    );
}

export function SmallLoading(){
    const antIcon = <LoadingOutlined style={{ fontSize: 30, color:'orange' }} spin />;

    return (
        <>
            <Spin indicator={antIcon} style={{margin:'auto', paddingTop:10}}/>
        </>
    );
}

export class ContactForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isVerified: false
        };

        this.formRef = React.createRef();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    async componentDidMount(){
        this._isMounted = true;

        
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleVerify(){
        if(this._isMounted && !this.state.isVerified)
            this.setState({isVerified: true})
    }

    onFinish(values){
        const data = new FormData();

        data.append("Nom", values["Nom"])
        data.append("Prenom", values["Prenom"])
        data.append("Email", values["Email"])
        data.append("Object", values["Object"])
        data.append("Message", values["Message"])

        const requestOptions = {
            method: 'POST',
            body: data
        }

        if(this.state.isVerified){
            API.post("/contact", {
                last_name: values['Nom'],
                first_name: values["Prenom"],
                email: values["Email"],
                subject: values["Object"],
                message: values["Message"]
            })
            .then(res => {
                this.formRef.current.resetFields();
                message.success("Message bien envoyé");
            })
        }
    }
    
    render(){
        return(
            <div>
                <Header>
                    <Title level={3}><span className="hightlight">Contactez-nous</span></Title>
                </Header>
                <Content>
                    <Form
                        ref={this.formRef}
                        onFinish={this.onFinish.bind(this)}
                    >
                        <Row>             
                            <Col span={12}><Form.Item
                                label="Nom"
                                name="Nom"
                                rules={[
                                    {
                                      required: true,
                                      message: "Veuillez nous indiquer votre nom",
                                    },
                                  ]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item
                                label="Prénom"
                                name="Prenom"
                                rules={[
                                    {
                                      required: true,
                                      message: "Veuillez nous indiquer votre prénom",
                                    },
                                  ]}
                            >
                                <Input size="large"/>
                            </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Email"
                            name="Email"
                            rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Merci d'entrer une adresse mail valide - exemple@mail.be",
                                },
                              ]}
                        >
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item
                            label="Objet du message"
                            name="Object"
                            rules={[
                                {
                                  required: true,
                                  message: "Veuillez entrer l'objet de votre message",
                                },
                              ]}
                        >
                            <Input size="large"/>
                        </Form.Item>
                        <Form.Item
                            label="Contenu du message"
                            name="Message"
                            rules={[
                                {
                                  required: true,
                                  message: "Veuillez entrer votre message",
                                },
                              ]}
                        >
                            <Input.TextArea autoSize={{ minRows: 3 }}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" shape="round">Contacter</Button>
                        </Form.Item>
                    </Form>
                    <GoogleReCaptchaProvider reCaptchaKey="6LcuGkAaAAAAAKFmfR9e7E5nF5WLg2IOEIiUjuKc">
                        <GoogleReCaptcha
                            onVerify={this.handleVerify.bind(this)}
                        />
                    </GoogleReCaptchaProvider>
                </Content>
            </div>
        );
    }
}
