//Import des composants utiles
import React from 'react';
import API from '../api';
import store from '../Store/Store';
import arrayMove from 'array-move';
import { ActionCreators } from '../Store/Action';
import { sortAZ, replaceCaracters } from '../utils';

//Import des composants de design
import { Space,Typography, Modal, Avatar, Button, Form, Input, InputNumber, Radio, Select, Layout, Upload,Progress, AutoComplete, notification, Popconfirm, Drawer, List, Card, Empty } from 'antd';
import { Row, Col } from 'antd';
import { Tags } from './Component';
import { CoffeeOutlined, UserOutlined, FrownOutlined,CameraOutlined, DesktopOutlined, ClearOutlined,LoadingOutlined, InboxOutlined,RetweetOutlined,EditOutlined,TagOutlined,UploadOutlined,QuestionCircleOutlined,DeleteOutlined,UserAddOutlined,FileAddOutlined,HomeOutlined,PlusCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea, Search } = Input;
const { Title, Text } = Typography;
const { Footer, Header, Sider, Content } = Layout;
const { Meta } = Card;
const { Dragger } = Upload;

export class AddAMember extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            users: [],
            searchText: "",
            visible: false,
            error_message:""
        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.addUser = this.addUser.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des utilisateurs
    loadUsersList(){
        let users =[];

        API.get('api/users')
        .then((res)=> {
            if(this._isMounted){
                res.data.forEach((user) => { 
                    if(this.props.company_users.find(company_user => company_user.id==user.id) == undefined){
                        users.push(user);
                        sortAZ(users,"last_name");
                    }
                })  
            }
        }).catch(err => {

        })

        this.setState({users: users});
    }

    //Prise en charge de la validation
    addUser(user){
        let usersList =this.state.users;

        API.put('/api/companies/'+this.props.company.id+'/users/'+user.id,{
            role:"Doctor"
        })
        .then((res) => {
            notification.success({message: "Ajout réussi !", duration:4.5})
            usersList = usersList.filter(e => e.id !== user.id);
            this.setState({users:usersList})
            this.props.refresh_handler();
        }).catch((err) => {
            notification.error({message: "Erreur lors de l'ajout !", duration:4.5})
        })  
    }

    handleClick(user, event){
        if(this._isMounted)
            this.setState({selectedUser: user});
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false}) 
        }
    }

    showModal(){
        if(this._isMounted){
            this.setState({visible: true});
            this.loadUsersList();
        }
    }

    handleOnSearch(value){
        let search = value;
        search = replaceCaracters(search);
        this.setState({searchText: search});
    }

    handleOnChangeSearch(event){
        let search = event.target.value;
        search = replaceCaracters(search);
        this.setState({searchText: search});
    }

    render() {
        return (
            <>    
                <Button type="primary" shape="round" onClick={this.showModal} icon={<UserAddOutlined />}>
                    Ajouter un utilisateur
                </Button>
                <Drawer
                    placement="right"
                    onClose={this.hideModal}
                    width={400}
                    visible={this.state.visible}
                    className="drawerpanel"
                >
                    <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                        } 


                    {this.state.searchText !="" && this.state.searchText.length>2? 
                        <List
                            dataSource={this.state.users.filter(user => replaceCaracters(user.first_name + " " + user.last_name).toLowerCase().includes(this.state.searchText.toLowerCase()))}
                            renderItem={user => (
                                <List.Item
                                    actions={[<Button type="text" shape="circle" onClick={() => this.addUser(user)} icon={<UserAddOutlined />}></Button>]}
                                >
                                    <List.Item.Meta
                                        avatar={user.avatar_url!=null?<Avatar src={user.avatar_url} size={38}/>:<Avatar size={38}>{user.first_name.substring(0,1)}{user.last_name.substring(0,1)}</Avatar>}
                                        title={user.last_name + " " + user.first_name}
                                    />
                                </List.Item>
                            )}
                            locale={{emptyText:<Empty
                                image={<FrownOutlined />}
                                description={
                                <span>
                                    Aucun utilisateur ne correspond à votre recherche
                                </span>
                                }
                            >
                            </Empty>}}
                        >
                        </List>
                    :
                        <Empty
                            image={<EditOutlined />}
                            description={
                            <span>
                                Veuillez entrer au moins 3 caractères
                            </span>
                            }
                        />
                    }
                </Drawer>               
            </>
        );
    }
}

export class AddCompanies extends React.Component{
    constructor(props){
        super(props);

        const storageLimiteInit = () => {
                if( this.props.type == "TYPE_CABINET"){
                    return 2000
                }else{
                    return 4000
                }
            };

        //Récupération des informations dans le store
        this.state = {
            name: undefined,
            type: this.props.type,
            street: undefined,
            street_box: undefined,
            street_number:  undefined,
            zip_code: undefined,
            locality: undefined,
            country:  undefined,
            phone_number:  undefined,
            vat_number: undefined,
            description: undefined,
            storage_limit:storageLimiteInit(),
            visible: false,
            error_message:"",
        }

        this.initialState = this.state;

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleStorageLimitChange = this.handleStorageLimitChange.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        API.post('api/companies', {
            name: this.state.name,
            type: this.state.type,
            street: this.state.street,
            street_number: this.state.street_number,
            street_box: this.state.street_box,
            zip_code: this.state.zip_code,
            locality: this.state.locality,
            country: this.state.country,
            phone_number: this.state.phone_number,
            description: this.state.description,
            vat_number: this.state.vat_number,
            storage_limit: this.state.storage_limit*1000000,
        })
        .then(() => {
            this.props.refresh_handler();
            this.hideModal();
        })
        .catch(err => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    handleChange(event){
        if(this._isMounted){
            let value = event.target.value
            if(value == ""){
                value = undefined;
            }
            this.setState({[event.target.id]: value})
        }
    }

    handleDescriptionChange(event){
        if(this._isMounted){
            let value = event.target.value
            if(value == ""){
                value = undefined;
            }
            this.setState({description: value})
        }
    }

    handleStorageLimitChange(event){
        if(this._isMounted){
            let value = event.target.value;
            if(value == null){
                value = undefined;
            }
            this.setState({storage_limit: value})
        }
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false})
            this.resetModal(); 
        }
    }

    resetModal() {
        this.setState(this.initialState);
    }


    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    render() {
        return (
            <>    
                {this.props.type == "TYPE_CABINET" ?
                <Button type="primary" shape="round" size="large" onClick={this.showModal} icon={<HomeOutlined />}>
                    Ajouter un cabinet
                </Button>:
                <Button type="primary" shape="round" size="large" onClick={this.showModal}  icon={<ClearOutlined />}>
                    Ajouter un créateur de contenu
                </Button>}  
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal}>
                            Annuler
                        </Button>,
                        <Button form="AddACabinet" key="submit" htmlType="submit" type="primary" shape="round">
                            Ajouter le cabinet
                        </Button>,
                    ]}
                    width={1000}
                    destroyOnClose
                >
                    {this.props.type == "TYPE_CABINET" ?
                    <Title level={4}>Ajouter un cabinet</Title>
                    :<Title level={4}>Ajouter un créateur de contenu</Title>} 

                    <Form id="AddACabinet" layout="vertical" onFinish={this.handleOk}
                        fields={[
                            {
                                name: ["storage_limit"],
                                value: this.state.storage_limit,
                            },
                        ]}
                    >
                        <Row gutter={[32, 16]}>
                            <Col span={12}>
                                <Form.Item name="company_name" label="Nom du cabinet" rules={[{ required: true, message: 'Veuillez entrer le nom de votre cabinet' }]}>     
                                    <Input id={"name"} onChange={this.handleChange} value={this.state.name} placeholder="Entrer un nom" />
                                </Form.Item>
                                {/*<Form.Item name="vat_number" label="Numéro de TVA" rules={[{ required: true, message: 'Veuillez entrer votre numéro de TVA' }]}>     
                                    <Input id={"vat_number"} onChange={this.handleChange} value={this.state.vat_number} placeholder="Entrer un numéro de TVA" />
                                </Form.Item>*/}
                                <Form.Item name="phone_number" label="Numéro de téléphone" rules={[{ required: true, message: 'Veuillez entrer votre numéro de téléphone' }]}>     
                                    <Input id={"phone_number"} onChange={this.handleChange} value={this.state.phone_number} placeholder="Entrer un numéro de téléphone" />
                                </Form.Item>
                                <Form.Item name="description" label="Description">     
                                    <TextArea onChange={this.handleDescriptionChange} value={this.state.description} showCount maxLength={250} autoSize={{ minRows: 3, maxRows: 6 }} placeholder="Entrer une description" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row gutter={[16, 16]}>     
                                    <Col span={12}>
                                        <Form.Item name="street" label="Rue" rules={[{ required: true, message: 'Veuillez entrer votre adresse' }]}>     
                                            <Input id={"street"} onChange={this.handleChange} value={this.state.street} placeholder="Entrer la rue" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="street_number" label="N°" rules={[{ required: true, message: 'Veuillez entrer votre adresse' }]}>     
                                            <Input id={"street_number"} onChange={this.handleChange} value={this.state.street_number} placeholder="Numéro" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="street_box" label="N° de boite">     
                                            <Input id={"street_box"} onChange={this.handleChange}  value={this.state.street_box} placeholder="Numéro de boite" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item name="zip_code" label="Code Postal" rules={[{ required: true, message: 'Veuillez indiquer le code postal' }]}>     
                                            <Input id={"zip_code"} onChange={this.handleChange} value={this.state.zip_code} placeholder="Entrer le code postal" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="locality" label="Ville" rules={[{ required: true, message: 'Veuillez entrer la ville de référence' }]}>     
                                            <Input id={"locality"} onChange={this.handleChange} value={this.state.locality} placeholder="Entrer la ville" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item name="country" label="Pays" rules={[{ required: true, message: 'Veuillez entrer le pays' }]}>     
                                    <Input id={"country"} onChange={this.handleChange} value={this.state.country} placeholder="Entrer votre pays" />
                                </Form.Item>
                                <Form.Item name="storage_limit" label="Limite de l'espace de stockage" onChange={this.handleStorageLimitChange} rules={[{ required: true, message: 'Veuillez définir une limite' }]}>     
                                    <InputNumber min={0} name="storage_limit" addonAfter="Mo" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                        } 
           
                </Modal>
            </>
        );
    }
}

export class AddAFile extends React.Component{
    constructor(props){
        super(props);
        //Récupération des informations dans le store
        this.state = {
            used_storage:0,
            storage_limit:0,
            fileList: [],
            allTags: [],
            tags: [],
            selectedTag: null,
            progress:0,
            uploading: false,
            error: false,
            visible: false,
            error_message:""
        }

        this.initialState = this.state;

        this.loadCompanyDetails = this.loadCompanyDetails.bind(this);
        this.checkFile = this.checkFile.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.uploadFile = this.uploadFile.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadCompanyDetails(){
        API.get('api/companies/'+store.getState().user.selected_company)
        .then((res)=> {
            this.setState({used_storage: res.data.used_storage, storage_limit:res.data.storage_limit})
        })
        .catch((err) => { 
            
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    loadTagList(){
        API.get('/api/tags')
        .then((res) => {
            if(this._isMounted){
                const options = []

                res.data.forEach(tag => {
                    options.push({value: tag.label, id:tag.id})
                });

                this.setState({allTags: options})
            }
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Prise en charge de la validation
    handleOk(){
        this.hideModal();  
    }

    checkFile(file){
        const limitFile = (parseInt(this.state.used_storage)+parseInt(file.size))>this.state.storage_limit;
        if(limitFile){
            notification.error({message: "Le chargement de ce fichier dépasse la limite de votre espace de stockage", duration:4.5});
        }
        /* Test format fichier */
        let fileType = true;
        const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
        const isVideo = file.type.startsWith('video/');
        const isPPT = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (!isImage && !isVideo && !isPPT) {
            notification.error({message: 'Vous ne pouvez pas charger un fichier de ce format', duration:4.5});
            fileType = false;
        }
        
        if(!limitFile && fileType){
            /* Vignette aperçu*/
            const url = window.URL ? window.URL.createObjectURL(file)
            : window.webkitURL.createObjectURL(file);
            file.thumbUrl = url;
            if(this._isMounted){
                this.setState({fileList:[file]});
            }
            return false;
        }
    }

    //Gestion de l'envoie du fichier 
    uploadFile(){
        const formData = new FormData();
        const key = 'updatable';

        formData.append('file',this.state.fileList[0]);
        formData.append('name',this.state.fileList[0].name);
        formData.append('company_id',store.getState().user.selected_company);

        notification.open({key, message:this.state.fileList[0].name ,description:<Progress size="small" percent={this.state.progress} strokeColor="#00a887"/>})
        this.setState({visible: false}) 
        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: event => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              this.setState({
                  progress:percent,
              })
              notification.open({key, message:this.state.fileList[0].name ,description:<Progress size="small" percent={this.state.progress} strokeColor="#00a887"/>})
            }
          };

        if(this._isMounted){
            const uplFile = this.state.fileList[0];
            uplFile['status'] = 'uploading';
            this.setState({fileList: [uplFile]});
            this.setState({error: false})
            this.setState({uploading: true})  
        }

        API.post('api/files', formData,config)
        .then((res) => {
            this.props.refresh_handler();
            this.props.refresh_company();
            this.hideModal();
            notification.success({key, message: "Ajout de "+res.data.name+" réussi !", duration:4.5})

            if(this._isMounted){
                this.handleOk.bind(this);
            }
        }).catch(err => {
            if(this._isMounted){
                const errFile = this.state.fileList[0];
                errFile['status'] = 'error';
                this.setState({fileList: [errFile]});
                this.setState({error: true});
                this.setState({uploading: false});
                notification.error({message: "Erreur lors de l'ajout du fichier", duration:4.5})
            }
        })
    }

    addTag(){
        if(this.state.selectedTag != null){
            const actualTags = this.state.allTags.find(element => element.value == this.state.selectedTag)
            
            if(actualTags != null){
                this.state.tags.push(actualTags)
            }else{
                API.post('/api/tags',{
                    label:this.state.selectedTag
                }).then((res) => {
                    this.loadTagList();
                    this.state.tags.push({value: res.data.label, id:res.data.id})
                })
            }
            
            if(this._isMounted)
                this.setState({selectedTag:null})
        }
    }

    removeTag(value){
        var tags = this.state.tags;
        tags = arrayMove(tags, value, tags.length-1)
        tags.pop();
        this.setState({tags: tags})
    }

    onSelect(data){
        if(this._isMounted){
            this.setState({selectedTag:data})
        }
    };

    hideModal(){
        if(this._isMounted)
            this.setState({visible: false}) 
            this.resetModal();
    }

    resetModal() {
        this.setState(this.initialState);
    }


    showModal(){
        if(this._isMounted)
            this.loadCompanyDetails();
            this.setState({visible: true})
    }

    render() {
        const { uploading, fileList, error } = this.state;

        const props = {
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onRemove: () => {
                if(this._isMounted){
                    this.setState({fileList:[]});
                }
            },
            fileList:this.state.fileList,
            listType:"picture",
            className:"upload-list-inline"
        }

        return (
            <>    
                <Button onClick={this.showModal} shape="round" type="primary" icon={<FileAddOutlined />}>
                    Ajouter un fichier
                </Button>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal}>
                            Annuler
                        </Button>,
                        <Button form="AddAFile" key="submit" htmlType="submit" type="primary" shape="round" loading={uploading} disabled={fileList.length === 0}>
                            {error ? 'Réesayer' : (uploading ? 'Envoi en cours' : 'Envoyer le fichier')}
                        </Button>,
                    ]}
                    destroyOnClose
                >   
                    <Form id="AddAFile" layout="vertical" onFinish={this.uploadFile}>
                        <Row justify="center">
                            <Col span={20}>
                                <Dragger {...props} beforeUpload={this.checkFile}>
                                    <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                    </p>
                                    <Title level={4}>Ajouter un fichier</Title>
                                    <p className="ant-upload-text">Cliquez ou déposez votre fichier dans cette zone</p>
                                </Dragger>
                            </Col>
                        </Row> 
                        {/*<Row>
                            <Col span={4}>
                                <TagOutlined />
                            </Col>
                            <Col span={20} className="ant-form-item-label">
                                <Row justify="space-between" align="middle">
                                    <Form.Item label="Tags">     
                                        <AutoComplete options={this.state.allTags} onChange={this.onSelect.bind(this)} style={{border:'none', borderBottom:'1px solid grey', width:200}} placeholder="Entrer un tag" name={"tags"}/>
                                    </Form.Item>
                                    <Button type="text" shape="circle" onClick={this.addTag.bind(this)} icon={<PlusCircleOutlined />}/>
                                </Row>

                            </Col>
                        </Row>*/}
                    </Form>           

                    {/* Tags 
                        {(this.state.tags.length > 0)?
                        this.state.tags.map((tag, index) => {
                                return <Tags key={index} title={tag.value} remove_handler={this.removeTag.bind(this, index)}/>
                        })
                        :<></>} */}    

                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 


                </Modal>
            </>
        );
    }
}

export class AddADirectFile extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            fileList: [],
            allTags: [],
            tags: [],
            selectedTag: null,
            uploading: false,
            error: false,
            visible: false,
            error_message:"",
        }

        this.initialState = this.state;

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadTagList();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadTagList(){
        API.get('/api/tags')
        .then((res) => {
            if(this._isMounted){
                const options = []

                res.data.forEach(tag => {
                    options.push({value: tag.label, id:tag.id})
                });

                this.setState({allTags: options})
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    //Prise en charge de la validation
    handleOk(){
        this.hideModal();  
    }

    //Gestion de l'envoie du fichier 
    uploadFile(){
        const formData = new FormData();

        formData.append('file',this.state.fileList[0])
        formData.append('name',this.state.fileList[0].name)

        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: event => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              this.setState({
                  progress:percent,
              })
            }
          };

        if(this._isMounted){
            const uplFile = this.state.fileList[0];
            uplFile['status'] = 'uploading';
            this.setState({fileList: [uplFile]});
            this.setState({error: false})
            this.setState({uploading: true})  
        }

        API.post('api/files', formData,config)
        .then(file_data => {
            this.state.tags.forEach(async (tag) => {
                API.put('api/files/'+file_data.data.id+'/tags/'+tag.id)
            })

            API.put('api/companies/'+this.props.company_id+'/files/'+file_data.data.id)
            .then((res) => {
                this.props.refresh_handler();
                this.hideModal();

                if(this._isMounted){
                    this.setState({fileList: []});
                    this.setState({uploading: false});
                    this.handleOk.bind(this);
                }
            }).catch((err) => {
                API.delete('api/files/'+file_data.data.id)
                then((res) => {
                    if(this._isMounted){
                        const errFile = this.state.fileList[0];
                        errFile['status'] = 'error';
                        this.setState({fileList: [errFile]});
                        this.setState({error: true});
                        this.setState({uploading: false});
                    }
                })            
            })
        })
        .catch(err => {
            if(this._isMounted){
                const errFile = this.state.fileList[0];
                errFile['status'] = 'error';
                this.setState({fileList: [errFile]});
                this.setState({error: true});
                this.setState({uploading: false});
            }
        })
    }

    addTag(){
        if(this.state.selectedTag != null){
            const actualTags = this.state.allTags.find(element => element.value == this.state.selectedTag)
            
            if(actualTags != null){
                this.state.tags.push(actualTags)
            }else{
                API.post('/api/tags',{
                    label:this.state.selectedTag
                }).then((res) => {
                    this.loadTagList();
                    this.state.tags.push({value: res.data.label, id:res.data.id})
                })
            }
            
            if(this._isMounted)
                this.setState({selectedTag:null})
        }
    }

    removeTag(value){
        var tags = this.state.tags;
        tags = arrayMove(tags, value, tags.length-1)
        tags.pop();
        this.setState({tags: tags})
    }

    onSelect(data){
        if(this._isMounted){
            this.setState({selectedTag:data})
        }
    };

    hideModal(){
        if(this._isMounted)
            this.setState({visible: false})
            this.resetModal();
    }

    resetModal() {
        this.setState(this.initialState);
    }


    showModal(){
        if(this._isMounted)
            this.setState({visible: true})
    }

    render() {
        const { uploading, fileList, error } = this.state;

        const props = {
            onRemove: () => {
                if(this._isMounted){
                    this.setState({fileList:[]});
                }
            },
            beforeUpload: file => {
                /* Test format fichier */
                const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
                const isVideo = file.type.startsWith('video/');
                const isPPT = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                if (!isImage && !isVideo && !isPPT) {
                    notification.error({message: 'Vous ne pouvez pas charger un fichier de ce format', duration:4.5});
                }else{
                    /* Vignette aperçu*/
                    const url = window.URL ? window.URL.createObjectURL(file)
                    : window.webkitURL.createObjectURL(file);
                    file.thumbUrl = url;
                    if(this._isMounted){
                        this.setState({fileList:[file]});
                    }
                    return false;
                }
            },
            fileList:this.state.fileList,
            listType:"picture",
            className:"upload-list-inline"
        }

        return (
            <>    
                <Button onClick={this.showModal.bind(this)} type="text" shape="round" icon={<FileAddOutlined />}>Ajouter un fichier</Button>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal.bind(this)}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal.bind(this)}>
                            Annuler
                        </Button>,
                        <Button form="AddADirectFile" key="submit" htmlType="submit" type="primary" shape="round" loading={uploading} disabled={fileList.length === 0}>
                            {error ? 'Réesayer' : (uploading ? 'Envoi en cours' : 'Envoyer le fichier')}                        
                        </Button>,
                    ]}
                    destroyOnClose
                >
                    <Title level={4}>Ajouter un fichier</Title>   
                    <Form id="AddADirectFile" layout="vertical" onFinish={this.uploadFile.bind(this)}>
                        <Row justify="center">
                            <Col span={20}>
                                <Dragger maxCount={1} {...props}>
                                    <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                    </p>
                                    <Title level={4}>Ajouter un fichier</Title>
                                    <p className="ant-upload-text">Cliquez ou déposez votre fichier dans cette zone</p>
                                </Dragger>
                                {this.state.progress > 0 ? <Progress size="small" percent={this.state.progress} strokeColor="#00a887"/> : null}
                            </Col>
                        </Row>   
                        <Row>
                            {/*<Col span={4}>
                                <TagOutlined />
                            </Col>
                            <Col span={20} className="ant-form-item-label">
                                <Row justify="space-between" align="middle">
                                    <Form.Item label="Tags">     
                                    <   AutoComplete options={this.state.allTags} onChange={this.onSelect.bind(this)} style={{border:'none', borderBottom:'1px solid grey', width:200}} placeholder="Entrer un tag" name={"tags"}/>
                                    </Form.Item>
                                    <Button type="text" shape="circle" onClick={this.addTag.bind(this)} icon={<PlusCircleOutlined />}/>
                                </Row>

                            </Col>*/}   
                        </Row> 
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                        } 
            

                    {/* Tags 
                    {(this.state.tags.length > 0)?
                    this.state.tags.map((tag, index) => {
                            return <Tags key={index} title={tag.value} remove_handler={this.removeTag.bind(this, index)}/>
                    })
                    :<></>}   */}                      

                </Modal>
            </>
        );
    }
}

export class ChangeFile extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            fileList: [],
            uploading: false,
            error: false,
            visible: false,
            error_message:"",
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        this.hideModal();  
    }

    //Gestion de l'envoie du fichier 
    uploadFile(){
        const formData = new FormData();

        formData.append('file',this.state.fileList[0])
        formData.append('name',this.state.fileList[0].name)

        if(this._isMounted){
            const uplFile = this.state.fileList[0];
            uplFile['status'] = 'uploading';
            this.setState({fileList: [uplFile]});
            this.setState({error: false})
            this.setState({uploading: true})  
        }   

        API.post('api/files', formData,{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(file_data => {
            API.put('api/files/'+this.props.file.id,{
                path: file_data.data.path,
                name: file_data.data.name
            })
            .then((res) => {
                this.props.refresh_handler();
                this.hideModal();

                if(this._isMounted){
                    this.setState({fileList: []})
                    this.setState({uploading: false})
                }
            }).catch((err) => {
                API.delete('api/files/'+file_data.data.id)
                .then((res) => {
                    if(this._isMounted){
                        const errFile = this.state.fileList[0];
                        errFile['status'] = 'error';
                        this.setState({fileList: [errFile]});
                        this.setState({error: true});
                        this.setState({uploading: false});
                    }
                })            
            })
        })
        .catch(err => {
            if(this._isMounted){
                const errFile = this.state.fileList[0];
                errFile['status'] = 'error';
                this.setState({fileList: [errFile]});
                this.setState({error: true});
                this.setState({uploading: false});
            }
        })
    }

    hideModal(){
        if(this._isMounted)
            this.setState({visible: false}) 
    }

    showModal(){
        if(this._isMounted)
            this.setState({visible: true})
    }

    render() {
        const uploadButton = (
            <div id="upload-button">
                <Text>Déposer votre fichier</Text>
                <a>Parcourir vos fichiers</a>
            </div>
        );

        const { uploading, fileList, error } = this.state;

        const props = {
            onRemove: () => {
                if(this._isMounted){
                    this.setState({fileList:[]});
                }
            },
            beforeUpload: file => {
                if(this._isMounted){
                    this.setState({fileList:[file]});
                }

                return false;
            },
            fileList,
        }

        return (
            <>    
                <Button onClick={this.showModal.bind(this)} type="text" shape="circle" icon={<RetweetOutlined /> }disabled />
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal.bind(this)}
                    onOk={this.handleOk.bind(this)}
                >
                    <Title level={4} style={{margin:'auto', marginTop:0}}>Ajouter un fichier</Title>               

                    <Layout style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:40}}>
                        <Upload maxCount={1} { ...props}>
                            {uploadButton}
                        </Upload>
                        <Content style={{width:261, paddingTop:20}}>
                            
                        </Content>
                        <Footer>
                            <Button style={{margin:'auto', backgroundColor: '#00A887', borderRadius:50, color:'white', border:'none', height:42, width:201}} loading={uploading} disabled={fileList.length === 0} onClick={this.uploadFile.bind(this)} shape="round" size='large' icon={
                                <svg style={{marginRight:11}} width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.81771 2.66602L7.26823 0.177734C7.15104 0.0634766 6.99219 0 6.82552 0H6.66667V3.25H10V3.09512C10 2.93516 9.9349 2.78027 9.81771 2.66602ZM5.83333 3.45312V0H0.625C0.278646 0 0 0.27168 0 0.609375V12.3906C0 12.7283 0.278646 13 0.625 13H9.375C9.72135 13 10 12.7283 10 12.3906V4.0625H6.45833C6.11458 4.0625 5.83333 3.78828 5.83333 3.45312ZM7.5 7.51562V8.73438C7.5 8.84609 7.40625 8.9375 7.29167 8.9375H5.83333V10.3594C5.83333 10.4711 5.73958 10.5625 5.625 10.5625H4.375C4.26042 10.5625 4.16667 10.4711 4.16667 10.3594V8.9375H2.70833C2.59375 8.9375 2.5 8.84609 2.5 8.73438V7.51562C2.5 7.40391 2.59375 7.3125 2.70833 7.3125H4.16667V5.89062C4.16667 5.77891 4.26042 5.6875 4.375 5.6875H5.625C5.73958 5.6875 5.83333 5.77891 5.83333 5.89062V7.3125H7.29167C7.40625 7.3125 7.5 7.40391 7.5 7.51562Z" fill="white"/>
                                </svg>}>
                                {error ? 'Réesayer' : (uploading ? 'Envoi en cours' : 'Envoyer le fichier')}
                            </Button> 
                        </Footer>
                    </Layout>
                {this.state.error_message != "" &&
                    <Row justify="center">
                        <Text type="danger">{this.state.error_message}</Text>
                    </Row>
                    } 

                </Modal>
            </>
        );
    }
}

export class PasswordReset extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            email: "",
            visible: false,
            error_message:"",
        }

        this.initialState = this.state;

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        API.post('/forgot-password', {
            email: this.state.email
        })
        .then(() => {
            this.hideModal();
        })
        .catch(err => {

            if (err.response.status == 404){ 
                this.setState({
                    error_message: "Désolé, l'email entrée n'est pas connue de notre système",
                })
            }else{
                this.setState({
                    error_message: "Une erreur est survenue, veuillez réessayer plus tard",
                })
            };
        })
    }

    handleChange(event){
        if(this._isMounted){
            const value = event.target.value
            this.setState({[event.target.id]: value})
        }
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false}) 
            this.resetModal();
        }
    }

    resetModal() {
        this.setState(this.initialState);
    }


    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    render() {
        return (
            <>     
                <Text onClick={this.showModal.bind(this)}><a>Mot de passe oublié</a></Text>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal.bind(this)}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal.bind(this)}>
                            Annuler
                        </Button>,
                        <Button form="ResetPassword" htmlType="submit" key="submit" type="primary" shape="round">
                            Réinitialiser mon mot de passe
                        </Button>,
                    ]}
                    destroyOnClose
                    >
                    <Title level={4}>Mot de passe oublié</Title>               
                    <Form id="ResetPassword" layout="vertical" onFinish={this.handleOk.bind(this)}
                        fields={[
                            {
                                name: ["email"],
                                value: this.state.email,
                            },
                        ]}
                    > 
                        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Veuillez entrer votre email' },{ type: 'email', message:"Votre email n'est pas valide : exemple@domaine.be" }]}>     
                            <Input id={"email"} onChange={this.handleChange.bind(this)} placeholder="Entrer le mail de récupération" />
                        </Form.Item>
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 


                </Modal>
            </>
        );
    }
}

export class AddQuestion extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            title: "",
            order: 0,
            visible: false,
            error_message:"",
        }

        this.initialState = this.state;

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        API.post('api/questions',{
            title: this.state.title,
            order: this.state.order,
            html: '<h4>'+this.state.order+'. '+this.state.title+'</h4>'
        })
        .then(() => {
            this.props.refresh_handler();
            this.hideModal();
        })
        .catch(err => {})     
    }

    handleChange(event){
        if(this._isMounted){
            const value = event.target.value
            this.setState({[event.target.id]: value})
        }
    }

    handleOrderChange(value){
        if(this._isMounted){
            this.setState({order: value})
        }
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false}) 
            this.resetModal();
        }
    }

    resetModal() {
        this.setState(this.initialState);
    }


    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    render() {
        return (<>
            <Button onClick={this.showModal.bind(this)} type="primary" shape="round">
                <svg style={{marginRight:11}} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.6562 11C21.6562 16.8866 16.8849 21.6562 11 21.6562C5.11513 21.6562 0.34375 16.8866 0.34375 11C0.34375 5.11685 5.11513 0.34375 11 0.34375C16.8849 0.34375 21.6562 5.11685 21.6562 11ZM11.286 3.86719C8.94429 3.86719 7.45078 4.85362 6.27799 6.60679C6.12605 6.83392 6.17689 7.14025 6.39465 7.30538L7.88563 8.43588C8.10928 8.60548 8.42793 8.56513 8.6017 8.3447C9.36929 7.37112 9.89562 6.80655 11.0639 6.80655C11.9417 6.80655 13.0275 7.3715 13.0275 8.22271C13.0275 8.86621 12.4963 9.19669 11.6296 9.68262C10.6188 10.2493 9.28125 10.9545 9.28125 12.7188V12.8906C9.28125 13.1754 9.51212 13.4062 9.79688 13.4062H12.2031C12.4879 13.4062 12.7188 13.1754 12.7188 12.8906V12.8333C12.7188 11.6104 16.2931 11.5595 16.2931 8.25C16.2931 5.75773 13.7079 3.86719 11.286 3.86719ZM11 14.5234C9.9101 14.5234 9.02344 15.4101 9.02344 16.5C9.02344 17.5899 9.9101 18.4766 11 18.4766C12.0899 18.4766 12.9766 17.5899 12.9766 16.5C12.9766 15.4101 12.0899 14.5234 11 14.5234Z" fill="#ffffff"/>
                </svg>
                Ajouter une question
            </Button>
            <Modal
                visible={this.state.visible}
                onCancel={this.hideModal.bind(this)}
                footer={[
                    <Button key="back" type="text" shape="round" onClick={this.hideModal.bind(this)}>
                        Annuler
                    </Button>,
                    <Button form="AddQuestion" key="submit" htmlType="submit" type="primary" shape="round">
                        Ajouter la question
                    </Button>,
                ]}
                destroyOnClose
            >
                <Title level={4}>Ajouter une question</Title>
                <Form id="AddQuestion" layout="vertical" onFinish={this.handleOk.bind(this)}> 
                    <Form.Item label="Titre de la question">     
                        <Input id={"title"} onChange={this.handleChange.bind(this)} value={this.state.title} placeholder="Entrer le titre" />
                    </Form.Item>
                    <Form.Item label="Ordre de la question">     
                        <InputNumber min={0} onChange={this.handleOrderChange.bind(this)} value={this.state.order}/>
                    </Form.Item>
                </Form>
                {this.state.error_message != "" &&
                    <Row justify="center">
                        <Text type="danger">{this.state.error_message}</Text>
                    </Row>
                } 

            </Modal></>
        )
    }
}

export function DeleteQuestion(props){
    const deleteQuestion = () => {
        API.delete('/api/questions/'+props.question.id)
        .then((res) => {
            props.refresh_handler();
        }).catch((err) => {

        })
    }

    const deleteConfirm = () => {
        deleteQuestion();
        notification.success({message: "Suppression de la question !", duration:4.5})
    }

    return (
        <Popconfirm
            title="Voulez-vous vraiment supprimer cette question ?"
            onConfirm={deleteConfirm}
            okText="Oui"
            cancelText="Non"
        >
            <Button type="text" shape="circle"  onClick={(event)=> {event.stopPropagation()}} icon={<DeleteOutlined />}/>
        </Popconfirm>
        )
}

export function DeleteUser(props){
    const deleteQuestion = () => {
        API.delete('/api/users/'+props.user.id)
        .then((res) => {
            props.refresh_handler();
        }).catch((err) => {

        })
    }

    const deleteConfirm = () => {
        deleteQuestion();
        notification.success({message: "Suppression de l'utilisateur !", duration:4.5})
    }

    return (
        <Popconfirm
            title="Voulez-vous vraiment supprimer cet utilisateur ?"
            icon={<QuestionCircleOutlined/>}
            onConfirm={deleteConfirm}
            okText="Supprimer définitivement cet utilisateur"
            okType="primary"
            cancelText="Annuler"
            
        >
            <Button type="text" shape="round" danger>Supprimer</Button>
        </Popconfirm>
        )
}

export function DeleteCompany(props){
    const deleteQuestion = () => {
        API.delete('/api/companies/'+props.company.id)
        .then((res) => {
            <Redirect to='/acabinets' />;
        }).catch((err) => {

        })
    }

    const deleteConfirm = () => {
        deleteQuestion();
        notification.success({message: "Suppression de l'entreprise !", duration:4.5})
    }

    return (
        <Popconfirm
            title="Voulez-vous vraiment supprimer cette entreprise ?"
            onConfirm={deleteConfirm}
            cancelButtonProps={{type:'text',shape:'round'}}
            okButtonProps={{type:'primary',shape:'round', danger:true}}
            okText="Supprimer"
            cancelText="Annuler"
        >
            <Button type="text" shape="round" danger>Supprimer</Button>
        </Popconfirm>
        )
}

export class EditPassword extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            password: "",
            password_confirmation: "",
            old_password: "",
            visible: false,
            error_message:"",
        }

        this.initialState = this.state;

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        API.put('api/users/'+this.props.user.id, {
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
            password_old: this.state.old_password
        })
        .then(() => {
            this.hideModal();
        })
        .catch(err => {})
    }

    handleChange(event){
        if(this._isMounted){
            const value = event.target.value
            this.setState({[event.target.id]: value})
        }
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false}) 
            this.resetModal();
        }
    }

    resetModal() {
        this.setState(this.initialState);
    }


    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    render() {
        return (
            <>  
                <Text onClick={this.showModal.bind(this)}><a className="editLabel">Modifier mon mot de passe</a></Text>  
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal.bind(this)}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal.bind(this)}>
                            Annuler
                        </Button>,
                        <Button form="EditPassword" key="submit" htmlType="submit" type="primary" shape="round">
                            Changer le mot de passe
                        </Button>,
                    ]}
                    destroyOnClose
                >
                    <Title level={4}>Modifier le mot de passe</Title> 
                    <Form id="EditPassword" layout="vertical" onFinish={this.handleOk.bind(this)}> 
                        <Form.Item label="Ancien mot de passe">     
                            <Input.Password id={"old_password"} onChange={this.handleChange.bind(this)} value={this.state.old_password} placeholder="Entrer votre mot de passe actuel" />      
                        </Form.Item>
                        <Form.Item name="password" label="Nouveau mot de passe" normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Veuillez entrer votre mot de passe' },
                            () => ({
                                validator(_, value) {
                                    const regex = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=.*[!@#$&*])(?=(.*[0-9]){2,}).{8,}$");
                                    if (!regex.test(value)) {
                                        return Promise.reject(new Error('Votre mot de passe doit faire minimum 8 caractères comprenant au moins des lettres majuscules et minuscules, 2 chiffres et 1 caractère spécial (!@#$&).'));
                                    }
                                    else{
                                        return Promise.resolve();
                                    }

                                
                                },
                            })]}>
                            <Input.Password name={"password"} onChange={this.handleChange.bind(this)} value={this.state.password} placeholder={"Entrez un nouveau mot de passe"}/>
                        </Form.Item>
                        <Form.Item name="password_confirmation" label="Confirmer votre nouveau mot de passe"  dependencies={['password']} normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Confirmez votre mot de passe' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Vos mots de passe ne sont pas identiques'));
                                },
                            }),]}>
                            <Input.Password name={"password_confirmation"} onChange={this.handleChange.bind(this)} value={this.state.password_confirmation} placeholder={"Confirmer votre nouveau mot de passe"} visibilityToggle={false}/>
                        </Form.Item>  
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 
      
                </Modal>
            </>
        );
    }
}

export class UserCompanyRegister extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            companies: [],
            visible: false,
            searchText: "",
            error_message:"",
        }

        this.initialState = this.state;

        this.loadCompanyList = this.loadCompanyList.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.addUser = this.addUser.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement de la liste des entreprises
    loadCompanyList(){
        let companies =[];

        API.get('/api/companies')
        .then((res) => {
            if(this._isMounted)
                res.data.forEach((company) => { 
                    if(this.props.user_companies.find(user_company => user_company.id==company.id) == undefined){
                        companies.push(company);
                    }
                })
                this.setState({companies: companies})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
        
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false});
        }
    }

    showModal(){
        if(this._isMounted){
            this.setState({visible: true});
            this.loadCompanyList();
        }
    }

    addUser(company){
        API.put('/api/users/'+this.props.user.id+'/companies/'+company.id,{
            role:"Doctor"
        })
        .then((res) => {
            notification.success({message: "Ajout réussi !", duration:4.5})
            this.props.refresh_handler();
        }).catch((err) => {
            notification.error({message: "Erreur lors de l'ajout !", duration:4.5})
        })
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){
        return(<>
            <Button onClick={this.showModal} type="primary" shape="round" size="large" icon={<HomeOutlined />}>
                Inscrire à un cabinet
            </Button> 
            <Drawer
                title=""
                placement="right"
                onClose={this.hideModal}
                width={400}
                visible={this.state.visible}
                className="drawerpanel"
            >
                <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                <List
                    dataSource={this.state.companies.filter(company => company.name.toLowerCase().includes(this.state.searchText.toLowerCase()))}
                    renderItem={company => (
                        <List.Item type="inner"
                            actions={[<Button type="text" shape="circle" onClick={() => this.addUser(company)} icon={<UserAddOutlined />}></Button>]}
                        >
                            <List.Item.Meta
                                title={company.name}
                                description={company.type == "TYPE_COMPANY"?"Créateur de contenu":"Cabinet"}
                            />
                        </List.Item>
                    )}
                    locale={{emptyText:<Empty
                        image={<HomeOutlined />}
                        description={
                        <span>
                            Aucune companie ne correspond à votre recherche
                        </span>
                        }
                    >
                    </Empty>}}
                >
                </List>
            </Drawer>
        </>)
    }
}

export class EditCompanyAvatar extends React.Component{
    constructor(props){
        super(props);
        
        //Récupération des informations dans le store
        this.state = {
            fileList: [],
            uploading: false,
            error: false,
            visible: false,
            error_message:"",
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        const formData = new FormData();

        formData.append('file',this.state.fileList[0])
        formData.append('name',this.state.fileList[0].name)
        formData.append('type','TYPE_AVATAR')

        if(this._isMounted){
            const uplFile = this.state.fileList[0];
            uplFile['status'] = 'uploading';
            this.setState({error: false})
            this.setState({fileList: [uplFile]});
            this.setState({uploading: true})  
        }

        API.post('api/files', formData,{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then((res) => {
            API.put('api/companies/'+this.props.company.id, {
                logo_id: res.data.id,
                logo_url: res.data.url
            })
            .then((res) => {
                this.hideModal();
                this.props.refresh_handler();
                notification.success({message: "Ajout du fichier réussi !", duration:4.5})
            })
            .catch((err) => {})
        })
        .catch(err => {
            if(this._isMounted){
                const errFile = this.state.fileList[0];
                errFile['status'] = 'error';
                this.setState({fileList: [errFile]});
                this.setState({error: true});
                this.setState({uploading: false});
                notification.error({message: "Un problème est survenu à l'ajout du fichier", duration:4.5})
            }
        })
    }

    hideModal(){
        if(this._isMounted){
            this.setState({
                fileList: [],
                uploading: false,
                visible: false}) 
        }
    }

    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    render() {
        const propsModal = {
            onRemove: () => {
                if(this._isMounted){
                    this.setState({fileList:[]});
                }
            },
            beforeUpload: file => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    notification.error({message: 'Seuls les fichiers PNG ou JPEG sont acceptés', duration:4.5});
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    notification.error({message: 'Votre image doit peser moins de 2MB', duration:4.5});
                }
                if(isJpgOrPng && isLt2M && this._isMounted){
                    const url = window.URL ? window.URL.createObjectURL(file)
                    : window.webkitURL.createObjectURL(file);
                    file.thumbUrl = url;
                    this.setState({fileList:[file]});
                }
            },
            fileList:this.state.fileList,
            maxCount:1,
            listType:"picture",
            className:"upload-list-inline",
        };  
        if(this.props.company!=undefined){
            return (
                
                <>    
                    {this.props.company.logo_url!=null?<Avatar className="editAvatar" onClick={this.showModal.bind(this)} src={this.props.company.logo_url} size={146}/>:<Avatar className="editAvatar" onClick={this.showModal.bind(this)} size={146}>{this.props.company.name.substring(0,3)}</Avatar>}

                    <Modal
                        visible={this.state.visible}
                        onCancel={this.hideModal.bind(this)}
                        footer={[
                            <Button key="back" type="text" shape="round" onClick={this.hideModal.bind(this)}>
                                Annuler
                            </Button>,
                            <Button key="submit" htmlType="submit" loading={this.state.uploading} type="primary" shape="round" onClick={this.handleOk.bind(this)}>
                                {this.state.error ? 'Réesayer' : (this.state.uploading ? 'Envoi en cours' : 'Mettre à jour la photo')}
                            </Button>,
                        ]}
                    >
                        <Row justify="center">
                            <Col span={20}>
                                <Dragger {...propsModal}>
                                    <p className="ant-upload-drag-icon">
                                    <CameraOutlined />
                                    </p>
                                    <Title level={4}>Mettre à jour la photo de profil</Title>
                                    <p className="ant-upload-text">Cliquez ou déposez votre fichier dans cette zone</p>
                                </Dragger>
                            </Col>
                        </Row>
                        {this.state.error_message != "" &&
                            <Row justify="center">
                                <Text type="danger">{this.state.error_message}</Text>
                            </Row>
                        } 
                    </Modal>
                </>
            );}else{
            return(<></>)
        }
    }
}

export class EditCompanyDetails extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            name: this.props.company.name,
            street: this.props.company.street,
            street_box: this.props.company.street_box,
            street_number:  this.props.company.street_number,
            zip_code: this.props.company.zip_code,
            locality: this.props.company.locality,
            country:  this.props.company.country,
            phone_number:  this.props.company.phone_number,
            vat_number: this.props.company.vat_number,
            description: this.props.company.description,
            used_storage:this.props.company.used_storage/1000000,
            storage_limit:this.props.company.storage_limit/1000000,
            error: false,
            visible: false,
            error_message:"",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleStorageLimitChange = this.handleStorageLimitChange.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        if(this.state.storage_limit>this.state.used_storage){
            API.put('api/companies/'+this.props.company.id, {
                name: this.state.name,
                street: this.state.street,
                street_number: this.state.street_number,
                street_box: this.state.street_box,
                zip_code: this.state.zip_code,
                locality: this.state.locality,
                country: this.state.country,
                phone_number: this.state.phone_number,
                description: this.state.description,
                vat_number: this.state.vat_number,
                storage_limit:this.state.storage_limit*1000000,
            })
            .then(() => {
                this.props.refresh_handler();
                this.hideModal();
            })
            .catch(err => {
                if(err.response != null && err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            })
        }
    }

    handleChange(event){
        if(this._isMounted){
            let value = event.target.value
            if(value == ""){
                value = undefined;
            }
            this.setState({[event.target.id]: value})
        }
    }

    handleStorageLimitChange(event){
        if(this._isMounted){
            let value = event;
            if(value == null){
                value = undefined;
            }
            this.setState({storage_limit: value})
        }
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false}) 
        }
    }

    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    render() {
        return (
            <>    
                <Button type="primary" shape="round" onClick={this.showModal.bind(this)}>
                    Modifier
                </Button>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal.bind(this)}
                    width={800}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal.bind(this)}>
                            Annuler
                        </Button>,
                        <Button form="EditCompanyDetails" key="submit" htmlType="submit" type="primary" shape="round">
                            Valider les modifications
                        </Button>,
                    ]}
                >
                    <Title level={4}>Informations du cabinet</Title> 
                    <Form id="EditCompanyDetails" layout="vertical" onFinish={this.handleOk.bind(this)}
                        fields={[
                            {
                                name: ["name"],
                                value: this.state.name,
                            },
                            {
                                name:["vat_number"],
                                value:this.state.vat_number,
                            },
                            {
                                name:["phone_number"],
                                value:this.state.phone_number,
                            },
                            {
                                name:["street"],
                                value:this.state.street,
                            }
                            ,
                            {
                                name:["street_number"],
                                value:this.state.street_number,
                            }
                            ,
                            {
                                name:["street_box"],
                                value:this.state.street_box,
                            }
                            ,
                            {
                                name:["zip_code"],
                                value:this.state.zip_code,
                            }
                            ,
                            {
                                name:["locality"],
                                value:this.state.locality,
                            }
                            ,
                            {
                                name:["country"],
                                value:this.state.country,
                            }
                            ,
                            {
                                name:["description"],
                                value:this.state.description,
                            }
                            ,
                            {
                                name:["storage_limit"],
                                value:this.state.storage_limit,
                            }
                        ]}
                    >   <Row gutter={[16,40]}>
                            <Col span={12}>
                                <Form.Item name="name" label="Nom de l'entreprise" rules={[{ required: true, message: 'Veuillez entrer le nom de votre cabinet' }]}>     
                                    <Input id={"name"} onChange={this.handleChange} value={this.state.name} placeholder="Entrer un nom" />
                                </Form.Item>
                                {/*<Form.Item name="vat_number" label="Numéro de TVA" rules={[{ required: true, message: 'Veuillez entrer votre numéro de TVA' }]}>     
                                    <Input id={"vat_number"} onChange={this.handleChange} value={this.state.vat_number} placeholder="Entrer un numéro de TVA" />
                                </Form.Item>*/}
                                <Form.Item name="phone_number" label="Numéro de téléphone" rules={[{ required: true, message: 'Veuillez entrer votre numéro de téléphone' }]}>     
                                    <Input id={"phone_number"} onChange={this.handleChange} value={this.state.phone_number} placeholder="Entrer un numéro de téléphone" />
                                </Form.Item>
                                <Form.Item name="description" label="Description">     
                                    <TextArea name="description" onChange={this.handleChange} showCount maxLength={250} autoSize={{ minRows: 3, maxRows: 6 }} value={this.state.description} placeholder="Entrer une description" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item name="street" label="Rue" rules={[{ required: true, message: 'Veuillez entrer votre adresse' }]}>     
                                            <Input id={"street"} onChange={this.handleChange} value={this.state.street} placeholder="Entrer la rue" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="street_number" label="N°" rules={[{ required: true, message: 'Veuillez entrer votre adresse' }]}>     
                                            <Input id={"street_number"} onChange={this.handleChange} value={this.state.street_number} placeholder="Numéro" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="street_box" label="N° de boite">     
                                            <Input id={"street_box"} onChange={this.handleChange}  value={this.state.street_box} placeholder="Numéro de boite" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item name="zip_code" label="Code Postal" rules={[{ required: true, message: 'Veuillez indiquer le code postal' }]}>     
                                            <Input id={"zip_code"} onChange={this.handleChange} value={this.state.zip_code} placeholder="Entrer le code postal" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="locality" label="Ville" rules={[{ required: true, message: 'Veuillez entrer la ville de référence' }]}>     
                                            <Input id={"locality"} onChange={this.handleChange} value={this.state.locality} placeholder="Entrer la ville" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item name="country" label="Pays" rules={[{ required: true, message: 'Veuillez entrer le pays' }]}>     
                                    <Input id={"country"} onChange={this.handleChange} value={this.state.country} placeholder="Entrer votre pays" />
                                </Form.Item>
                                {store.getState().user.profile.role ==  "ROLE_ADMIN"&&
                                    <Form.Item name="storage_limit" label="Limite de l'espace de stockage">     
                                        <InputNumber min={0} name="storage_limit" onChange={this.handleStorageLimitChange} addonAfter="Mo" defaultValue={this.state.storage_limit} rules={[{ required: true, message: 'Veuillez définir une limite' }]}/>
                                        <Row>
                                        { this.state.storage_limit<this.state.used_storage &&
                                            <Text type="danger">La taille limite de l'espace de stockage ne peut pas être inférieur à l'espace utilisé</Text>
                                        }
                                        </Row>
                                    </Form.Item>
                                }

                            </Col>
                        </Row>
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 

                </Modal>
            </>
        );
    }
}

export class EditUserAvatar extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            fileList: [],
            uploading: false,
            progress:0,
            error: false,
            visible: false,
            error_message:"",
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        const formData = new FormData();

        formData.append('file',this.state.fileList[0]);
        formData.append('name',this.state.fileList[0].name);
        formData.append('type','TYPE_AVATAR');

        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: event => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              this.setState({
                  progress:percent,
              })
            }
          };

        if(this._isMounted){
            const uplFile = this.state.fileList[0];
            uplFile['status'] = 'uploading';
            this.setState({error: false})
            this.setState({fileList: [uplFile]});
            this.setState({uploading: true})  
        }

        API.post('api/files', formData,config)
        .then((res) => {
            API.put('api/users/'+this.props.user.id, {
                avatar_id: res.data.id,
                avatar_url: res.data.url
            })
            .then((res) => {
                this.resetModal();
                this.hideModal();
                this.props.refresh_handler();
                store.dispatch(ActionCreators.updateProfileAvatar(res.data.avatar_url));
                notification.success({message: "Ajout du fichier réussi !", duration:4.5})
            })
            .catch((err) => {})
        })
        .catch(err => {
            if(this._isMounted){
                const errFile = this.state.fileList[0];
                errFile['status'] = 'error';
                this.setState({fileList: [errFile]});
                this.setState({error: true});
                this.setState({uploading: false});
                notification.error({message: "Erreur lors de l'ajout du fichier", duration:4.5})
            }
        })
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false}) 
        }
    }

    resetModal(){
        if(this._isMounted){
            this.setState({fileList: [],
                uploading: false,
                progress:0,
                error: false,}) 
        }
    }

    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    render() {
        const props = {
            onRemove: () => {
                if(this._isMounted){
                    this.setState({fileList:[]});
                }
            },
            beforeUpload: file => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    notification.error({message: 'Seuls les fichiers PNG ou JPEG sont acceptés', duration:4.5});
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    notification.error({message: 'Votre image doit peser moins de 2MB', duration:4.5});
                }
                if(isJpgOrPng && isLt2M && this._isMounted){
                    const url = window.URL ? window.URL.createObjectURL(file)
                    : window.webkitURL.createObjectURL(file);
                    file.thumbUrl = url;
                    this.setState({fileList:[file]});
                }

                return false;
            },
            multiple: false
        };

        return (
            <>    
                {this.props.user.avatar_url!=null?<Avatar onClick={this.showModal.bind(this)} className="editAvatar" src={this.props.user.avatar_url} size={150}/>:<Avatar onClick={this.showModal.bind(this)} className="editAvatar" size={150} >{this.props.user.first_name.substring(0,1)}{this.props.user.last_name.substring(0,1)}</Avatar>}
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal.bind(this)}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal.bind(this)}>
                            Annuler
                        </Button>,
                        <Button form="EditAvatar" key="submit" htmlType="submit" type="primary" shape="round" loading={this.state.uploading} disabled={this.state.fileList.length === 0}>
                            {this.state.error ? 'Réesayer' : (this.state.uploading ? 'Envoi en cours' : 'Envoyer le fichier')}
                        </Button>,
                    ]}
                >
                    <Title level={4}>Modifier votre avatar</Title>
                    <Form id="EditAvatar" layout="vertical" onFinish={this.handleOk.bind(this)}>               
                        <Dragger {...props} fileList={this.state.fileList} maxCount={1}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <Title level={4}>Ajouter un fichier</Title>
                            <p className="ant-upload-text">Cliquez ou déposez votre fichier dans cette zone</p>
                        </Dragger>
                        {this.state.progress > 0 ? <Progress size="small" percent={this.state.progress} strokeColor="#00a887"/> : null}
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 
                </Modal>
            </>
        );
    }
}

//Bouton ajouter un ecran -
export class AddMonitorBtn extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            name: undefined,
            description:undefined,
            company_id:0,
            association_code:undefined,
            broadcasts:props.broadcasts,
            error_message:"",
        }

        this.initialState = this.state;

        this.hideModal = this.hideModal.bind(this);
        this.resetModal = this.resetModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);


        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        API.put('api/companies/'+this.props.company_id+'/broadcasters/'+this.state.association_code, {
            name: this.state.name,
            description:this.state.description,
            association_code:this.state.association_code,
        })
        .then((res) => {
            res.data.forEach(broadcaster => {
                if(broadcaster.association_code != null){
                    this.state.broadcasts.forEach(broadcast => {
                        API.put('api/broadcasters/'+broadcaster.id+'/broadcasts/'+broadcast.id);
                    });
                }
            })

            this.props.refresh_handler();
            this.hideModal();
        })
        .catch(err => {

            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
            else if(err.response.status == 404){
                this.setState({
                    error_message : "Le code d'association n'est pas reconnu, veuillez réessayer ou réinitialisez les fichiers cachés de votre moniteur",
                })
            }
            /* renvoi vers la FAQ pour réinitialiser le moniteur */
        })
    }

    handleChange(event){
        if(this._isMounted){
            let value = event.target.value
            if(value == ""){
                value = undefined;
            }
            this.setState({[event.target.id]: value})
        }
    }

    handleDescriptionChange(event){
        if(this._isMounted){
            let value = event.target.value
            if(value == ""){
                value = undefined;
            }
            this.setState({description: value})
        }
    }

    handleCodeChange(event){
        if(this._isMounted){
            let value = event.target.value
            if(value == ""){
                value = undefined;
            }
            this.setState({association_code: value})
        }
    }

    hideModal(){
        if(this._isMounted)
            this.setState({visible: false}) 
            this.resetModal();
    }

    resetModal() {
        this.setState(this.initialState);
    }


    showModal(){
        if(this._isMounted)
            this.setState({visible: true})
    }

    render() {
        return (
            <>    
                <Button onClick={this.showModal} shape="round" type="primary" icon={<DesktopOutlined />}>
                    Ajouter un moniteur
                </Button>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal}>
                            Annuler
                        </Button>,
                        <Button form="AddMonitorBtn" key="submit" htmlType="submit" type="primary" shape="round">
                            Ajouter un moniteur
                        </Button>,
                    ]}
                    destroyOnClose
                >
                    <Title level={4}>Ajouter un moniteur</Title>    
                    <Form id="AddMonitorBtn" layout="vertical" onFinish={this.handleOk}>
                        <Form.Item name="name" label="Nom du moniteur" rules={[{ required: true, message: 'Veuillez entrer un nom' }]}>     
                            <Input id={"name"} onChange={this.handleChange} value={this.state.name} placeholder="Entrez un nom" />
                        </Form.Item>
                        <Form.Item name="description" label="Description">     
                            <TextArea onChange={this.handleDescriptionChange} value={this.state.description} placeholder="Entrez une description" />
                        </Form.Item>
                        <Form.Item name="association_code" label="Code d'association" rules={[{ required: true, message: 'Veuillez entrer le code visible sur votre écran' }]}>     
                            <Input onChange={this.handleCodeChange} value={this.state.association_code} placeholder="Entrez le code d'association visible sur votre écran" />
                        </Form.Item>
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    }         
                </Modal>
            </>
        );
    }
}

//Profil user editing
export class EditUserProfil extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            id:this.props.user.id,
            last_name: this.props.user.last_name,
            first_name:this.props.user.first_name,
            base_email:this.props.user.email,
            email:this.props.user.email,
            phone_number:this.props.user.phone_number,
            street:this.props.user.street,
            street_number:this.props.user.street_number,
            street_box:this.props.user.street_box,
            zip_code:this.props.user.zip_code,
            locality:this.props.user.locality,
            country:this.props.user.country,
            role:this.props.user.role,
            function:this.props.user.function_label,
            inami:this.props.user.inami,
            visible: false,
            error_message:"",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleChangeFunction = this.handleChangeFunction.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge de la validation
    handleOk(){
        API.put('api/users/'+this.state.id, {
            last_name: this.state.last_name,
            first_name:this.state.first_name,
            email: this.state.base_email!=this.state.email?this.state.email:undefined,
            phone_number:this.state.phone_number!=null?this.state.phone_number:undefined,
            street:this.state.street!=null?this.state.street:undefined,
            street_number:this.state.street_number!=null?this.state.street_number:undefined,
            street_box:this.state.street_box!=null?this.state.street_box:undefined,
            zip_code:this.state.zip_code!=null?this.state.zip_code:undefined,
            locality:this.state.locality!=null?this.state.locality:undefined,
            country:this.state.country!=null?this.state.country:undefined,
            role:this.state.role,
            inami:this.state.inami,
            function_label:this.state.function,
        })
        .then((res) => {
            this.props.refresh_handler();
            this.hideModal();
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
        
    }

    handleChange(event){
        let value = event.target.value
        if(value == ""){
            value = undefined;
        }
        if(event.target.name == "email"){
            if(this._isMounted)
                this.setState({[event.target.name]: value.replace(/\s/g, '')})
        }else{
            if(this._isMounted)
                this.setState({[event.target.name]: value})
        }
    }

    handleRoleChange(event){
        this.setState({role: event.target.value})
    }

    handleChangeFunction(event){
        this.setState({function:event})
    }

    hideModal(){
        if(this._isMounted){
            this.setState({visible: false}) 
        }
    }

    showModal(){
        if(this._isMounted){
            this.setState({visible: true})
        }
    }

    render() {

        return (
            <>    
                <Button type="primary" shape="round" onClick={this.showModal}>
                    Modifier
                </Button>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hideModal}
                    footer={[
                        <Button key="back" type="text" shape="round" onClick={this.hideModal}>
                            Annuler
                        </Button>,
                        <Button form="EditProfilUser" key="submit" htmlType="submit" type="primary" shape="round">
                            Valider les modifications
                        </Button>,
                    ]}
                    width={1000}
                >
                    <Title level={4}>Édition du profil</Title> 
                    <Form id="EditProfilUser" layout="vertical" onFinish={this.handleOk}
                        fields={[
                            {
                                name: ["last_name"],
                                value: this.state.last_name,
                            },
                            {
                                name:["first_name"],
                                value:this.state.first_name,
                            },
                            {
                                name:["phone_number"],
                                value:this.state.phone_number,
                            },
                            {
                                name:["email"],
                                value:this.state.email,
                            },
                            {
                                name:["street"],
                                value:this.state.street,
                            }
                            ,
                            {
                                name:["street_number"],
                                value:this.state.street_number,
                            }
                            ,
                            {
                                name:["street_box"],
                                value:this.state.street_box,
                            }
                            ,
                            {
                                name:["zip_code"],
                                value:this.state.zip_code,
                            }
                            ,
                            {
                                name:["locality"],
                                value:this.state.locality,
                            }
                            ,
                            {
                                name:["country"],
                                value:this.state.country,
                            }
                            ,
                            {
                                name:["role"],
                                value:this.state.role,
                            },
                            {
                                name:["function"],
                                value:this.state.function,
                            },
                            {
                                name:["inami"],
                                value:this.state.inami,
                            }
                        ]}
                    > 
                    {store.getState().user.profile.role ==  "ROLE_ADMIN"&&
                        <Row>
                            <Form.Item name="role" label="Rôle de l'utilisateur">     
                                <Radio.Group  onChange={this.handleRoleChange} buttonStyle="solid">
                                    <Radio.Button value="ROLE_USER"><UserOutlined /> Utilisateur</Radio.Button>
                                    <Radio.Button value="ROLE_ADMIN"><CoffeeOutlined/> Administrateur</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Row>
                    }
                        <Row gutter={[32, 16]}>
                            <Col span={12}>
                                <Form.Item name="function" label="Fonction" rules={[{ required: true, message: 'Veuillez entrer votre fonction' }]}>
                                    <Select
                                    onChange={this.handleChangeFunction}
                                    options={[
                                        {
                                        value: 'medecin',
                                        label: 'Médecin généraliste',
                                        },
                                        {
                                        value: 'secretaire',
                                        label: 'Secrétaire',
                                        },
                                        {
                                        value: 'coord',
                                        label: 'Coordinat.eur.rice',
                                        },
                                        {
                                        value: 'infi',
                                        label: 'Infirmier-ère',
                                        },
                                        {
                                        value: 'kine',
                                        label: 'Kinésithérapeute',
                                        },
                                        {
                                        value: 'psy',
                                        label: 'Psychologue',
                                        },
                                        {
                                        value: 'assist',
                                        label: 'Assistant.e sociale',
                                        },
                                        {
                                        value: 'promot',
                                        label: 'Promoteur de la santé',
                                        },
                                        {
                                        value: 'autre',
                                        label: 'Autre',
                                        }
                                    ]}
                                    />
                                </Form.Item>
                                <Form.Item name="last_name" label="Nom" rules={[{ required: true, message: 'Veuillez entrer votre nom' }]}>     
                                    <Input  name="last_name" id={"last_name"} onChange={this.handleChange} placeholder="Nom" />
                                </Form.Item>
                                <Form.Item name="first_name" label="Prénom" rules={[{ required: true, message: 'Veuillez entrer votre prénom' }]}>     
                                    <Input name="first_name" id={"first_name"} onChange={this.handleChange} placeholder="Prénom" />
                                </Form.Item>
                                { this.state.function!="Autre" &&
                                <Form.Item name="inami" label={this.state.function=="Médecin"?"Numéro INAMI":"Numéro INAMI de votre responsable"} rules={[{ required: true, message: 'Veuillez entrer le numéro INAMI' }]}>
                                    <Input autoComplete={"inami"} name={"inami"} onChange={this.handleChange} placeholder={"Entrez le numéro INAMI"}/>
                                </Form.Item>
                                }
                                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Veuillez entrer une adresse email' }]}>     
                                    <Input name="email" id={"email"} onChange={this.handleChange} placeholder="email" />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item name="phone_number" label="Numéro de téléphone" rules={[{ required: true, message: 'Veuillez entrer le numéro de téléphone' }]}>     
                                    <Input name="phone_number" id={"phone_number"} onChange={this.handleChange} placeholder="Numéro de téléphone" />
                                </Form.Item>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item name="street" label="Rue" rules={[{ required: true, message: 'Veuillez entrer une adresse' }]}>     
                                            <Input name="street" id={"street"} onChange={this.handleChange} placeholder="Rue" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="street_number" label="N°" rules={[{ required: true, message: 'Veuillez entrer le numéro dans la rue' }]}>     
                                            <Input name="street_number" id={"street_number"} onChange={this.handleChange} placeholder="Numéro" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="street_box" label="N° de boite">     
                                            <Input name="street_box" id={"street_box"} onChange={this.handleChange} placeholder="Numéro de boite" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item name="zip_code" label="Code Postal" rules={[{ required: true, message: 'Veuillez indiquer le code postal' }]}>     
                                            <Input name="zip_code" id={"zip_code"} onChange={this.handleChange} placeholder="Code postal" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="locality" label="Ville" rules={[{ required: true, message: 'Veuillez entrer la ville de référence' }]}>     
                                            <Input name="locality" id={"locality"} onChange={this.handleChange} placeholder="Ville" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item name="country" label="Pays" rules={[{ required: true, message: 'Veuillez entrer le pays' }]}>     
                                    <Input name="country" id={"country"} onChange={this.handleChange} placeholder="Pays" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 

                </Modal>
            </>
        );
    }
}
