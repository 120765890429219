//Import des composants utiles
import React from 'react';
import API from '../api';
import { Link, Redirect } from 'react-router-dom';
import store from '../Store/Store';

//Import des composants de design
import { ArrowLeftOutlined,LeftOutlined } from '@ant-design/icons';
import { Form, Input, Card, Button, Typography, Layout, Select, Steps, Radio,Checkbox } from 'antd';
const { Title, Text } = Typography;
const { Footer, Header, Content } = Layout;
import { Row, Col } from 'antd';
const { Option } = Select;
const { Step } = Steps;


//Page de création du compte de l'utilisateur
class RegisterPage extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            function : "medecin",
            first_name: undefined,
            last_name: undefined,
            inami_number:undefined,
            password_confirm: undefined,
            password: undefined,
            email: undefined,
            company_name:undefined,
            street:undefined,
            street_number:undefined,
            street_box:undefined,
            zip_code:undefined,
            locality:undefined,
            policy:false,
            subscribe:false,
            country:"Belgique",
            current: 0,
            redirect: false,
            complete:false,
            error_message:"",
            errorUniqueMail: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFunction = this.handleChangeFunction.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleRegister = this.handleRegister.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleNext(){
        if(this._isMounted)
            this.setState({current: this.state.current+1})
    }

    handlePrevious(){
        if((this.state.current-1)==0){
            if(this._isMounted)
                this.setState({current: this.state.current-1,
                    password:"",
                    password_confirm:"",
                })
        }else{
            this.setState({current: this.state.current-1
            })
        }
    }

    //Prise en charge de la modification des champs
    handleChange(event){
        let value = event.target.value
        if(value == ""){
            value = undefined;
        }
        if(event.target.name == "email" || event.target.name.includes("password")){
            if(this._isMounted)
                this.setState({[event.target.name]: value.replace(/\s/g, '')})
        }else{
            if(this._isMounted)
                this.setState({[event.target.name]: value})
        }
    }

    handleChangeFunction(event){
        this.setState({function:event})
    }

    handleChangeCheckbox(event){
        this.setState({[event.target.name]: event.target.checked});
    }

    //Prise en charge de l'enregistrement de l'utilisateur
    handleRegister(){
        API.post('/api/users',{
            function_label:this.state.function,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            inami:this.state.inami_number,
            password_confirmation: this.state.password_confirm,
            password: this.state.password,
            company_name:this.state.company_name,
            street:this.state.street,
            street_number:this.state.street_number,
            street_box:this.state.street_box,
            zip_code:this.state.zip_code,
            locality:this.state.locality,
            country:this.state.country,
            email: this.state.email.toLowerCase(),
            policy:this.state.policy,
            subscribe:this.state.subscribe,
            role:'ROLE_USER'
        })
        .then(() => {
            if(this._isMounted)
                this.setState({redirect: true})
        }).catch(err => {
            if(err.response.status == 422){
                this.setState({current: 0,errorUniqueMail: true, email:""})
            }
        }) 
    }

    render(){

        const isCabinet = this.state.function == "promot" || this.state.function == "coord" || this.state.function == "autre";

        const steps = [
            {
                title : "Complétez les informations de votre profil",
                content:<Col span={8} >
                    <Form layout="vertical" onFinish={this.handleNext}
                        fields={[
                            {
                                name: ["function"],
                                value: this.state.function,
                            },
                            {
                                name: ["inami_number"],
                                value: this.state.inami_number,
                            },
                            {
                                name: ["lastname"],
                                value: this.state.last_name,
                            },
                            {
                                name:["firstname"],
                                value:this.state.first_name,
                            },
                            {
                                name:["email"],
                                value:this.state.email,
                            },
                            {
                                name:["password"],
                                value:this.state.password,
                            }
                            ,
                            {
                                name:["passwordbis"],
                                value:this.state.password_confirm,
                            }
                        ]}
                    > 
                        <Form.Item name="function" label="Fonction" rules={[{ required: true, message: 'Veuillez entrer votre fonction' }]}>
                            <Select
                            onChange={this.handleChangeFunction}
                            options={[
                                {
                                value: 'medecin',
                                label: 'Médecin généraliste',
                                },
                                {
                                value: 'secretaire',
                                label: 'Secrétaire',
                                },
                                {
                                value: 'coord',
                                label: 'Coordinat.eur.rice',
                                },
                                {
                                value: 'infi',
                                label: 'Infirmier-ère',
                                },
                                {
                                value: 'kine',
                                label: 'Kinésithérapeute',
                                },
                                {
                                value: 'psy',
                                label: 'Psychologue',
                                },
                                {
                                value: 'assist',
                                label: 'Assistant.e sociale',
                                },
                                {
                                value:'promot',
                                label: 'Promoteur de la santé',
                                },
                                {
                                value: 'autre',
                                label: 'Autre',
                                }
                            ]}
                            />
                        </Form.Item>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item name="lastname" label="Nom" rules={[{ required: true, message: 'Veuillez entrer votre nom' }]}>
                                    <Input autoComplete={"last_name"} name={"last_name"} onChange={this.handleChange} placeholder={"Entrez votre nom"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="firstname" label="Prénom" rules={[{ required: true, message: 'Veuillez entrer votre prénom' }]}>
                                    <Input name={"first_name"} onChange={this.handleChange} placeholder={"Entrez votre prénom"}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="email" label="Email" normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Veuillez entrer votre email' },{ type: 'email', message:"Votre email n'est pas valide : exemple@domaine.be" }]}>
                            <Input autoComplete={"email"} name={"email"} onChange={this.handleChange} placeholder={"Entrez votre adresse mail"}/>
                        </Form.Item>
                        { this.state.errorUniqueMail &&
                            <Text type="danger"> L'adresse mail que vous avez choisi est déjà utilisée</Text>
                        }
                        { this.state.function!="Autre" &&
                        <Form.Item name="inami_number" label={this.state.function=="Médecin"?"Numéro INAMI":"Numéro INAMI de votre responsable"} rules={[{ required: true, message: 'Veuillez entrer le numéro INAMI' }]}>
                            <Input autoComplete={"inami_number"} name={"inami_number"} onChange={this.handleChange} placeholder={"Entrez le numéro INAMI"}/>
                        </Form.Item>
                        }
                        <Form.Item name="password" label="Mot de passe" normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Veuillez entrer votre mot de passe' },
                            () => ({
                                validator(_, value) {
                                    const regex = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=.*[!@#$&*])(?=(.*[0-9]){2,}).{8,}$");
                                    if (!regex.test(value)) {
                                        return Promise.reject(new Error('Votre mot de passe doit faire minimum 8 caractères comprenant au moins des lettres majuscules et minuscules, 2 chiffres et 1 caractère spécial (!@#$&).'));
                                    }
                                    else{
                                        return Promise.resolve();
                                    }

                                
                                },
                            })]}>
                            <Input.Password name={"password"} onChange={this.handleChange} placeholder={"Entrez un mot de passe"}/>
                        </Form.Item>
                        <Form.Item name="passwordbis" label="Confirmation du mot de passe" dependencies={['password']} normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Confirmez votre mot de passe' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Vos mots de passe ne sont pas identiques'));
                                },
                            }),]}>
                            <Input.Password name={"password_confirm"} onChange={this.handleChange} placeholder={"Confirmer votre mot de passe"} visibilityToggle={false}/>
                        </Form.Item>  
                        <Button type="primary" shape="round" size='large' htmlType="submit">Passer à l'étape suivante </Button>                                                                       
                    </Form>
                    
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 

                </Col>
            },
            {
                title : <>{ isCabinet ? "Indiquez votre association principale":"Indiquez votre cabinet principal"}</>,
                content:<Col span={8}>
                    <Button type="text" onClick={this.handlePrevious} shape="round" icon={<LeftOutlined />}>Revenir à l’étape précédente</Button>
                    <Form layout="vertical" onFinish={this.handleNext}
                        fields={[
                            {
                                name:["company_name"],
                                value: this.state.company_name,
                            },
                            {
                                name: ["street"],
                                value: this.state.street,
                            },
                            {
                                name:["street_number"],
                                value:this.state.street_number,
                            },
                            {
                                name:["street_box"],
                                value:this.state.street_box,
                            },
                            {
                                name:["zip_code"],
                                value:this.state.zip_code,
                            }
                            ,
                            {
                                name:["locality"],
                                value:this.state.locality,
                            }
                            ,
                            {
                                name:["country"],
                                value:this.state.country,
                            }
                        ]}
                    > 
                        <Form.Item name="company_name" label={ isCabinet ? "Nom de l'association":"Nom du cabinet"} rules={[{ required: true, message: <>{ isCabinet ? 'Veuillez entrer le nom de votre association principale' :'Veuillez entrer le nom de votre cabinet principal' }</>}]}>     
                            <Input name={"company_name"} onChange={this.handleChange} value={this.state.company_name} placeholder={ isCabinet ? "Nom de l'association":"Nom du cabinet"} />
                        </Form.Item>   
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item name="street" label="Rue" rules={[{ required: true, message: 'Veuillez entrer une adresse' }]}>     
                                    <Input name={"street"} onChange={this.handleChange} value={this.state.street} placeholder="Rue" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="street_number" label="N°" rules={[{ required: true, message: 'Veuillez entrer le numéro dans la rue' }]}>     
                                    <Input name={"street_number"} onChange={this.handleChange} value={this.state.street_number} placeholder="Numéro" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="street_box" label="N° de boite">     
                                    <Input name={"street_box"} onChange={this.handleChange}  value={this.state.street_box} placeholder="Numéro de boite" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item name="zip_code" label="Code Postal" rules={[{ required: true, message: 'Veuillez indiquer le code postal' }]}>     
                                    <Input name={"zip_code"} onChange={this.handleChange} value={this.state.zip_code} placeholder="Code postal" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="locality" label="Ville" rules={[{ required: true, message: 'Veuillez entrer la ville de référence' }]}>     
                                    <Input name={"locality"} onChange={this.handleChange} value={this.state.locality} placeholder="Ville" />
                                </Form.Item>
                            </Col>
                        </Row>   
                        <Button type="primary" shape="round" size='large' htmlType="submit">Passer à l'étape suivante </Button>                                       
                    </Form>  
                    
                    {this.state.error_message != "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                    } 
                </Col>
            },
            {
                title : "Conditions d'utilisation et flux",
                content: 
                        <Col span={8}>
                            <Button type="text" onClick={this.handlePrevious} shape="round" icon={<LeftOutlined />}>Revenir à l’étape précédente</Button>
                            <Form  layout="vertical" onFinish={this.handleRegister}>
                                <Form.Item name="policy" valuePropName="checked" rules={[
                                    {
                                        validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error("Veuillez accepter nos conditions d'utilisation")),
                                    },
                                    ]}>     
                                    <Checkbox name="policy" onChange={this.handleChangeCheckbox}>En cochant cette case, vous déclarez avoir lu et accepté <a href="/conditions-utilisation" target="_blank">les conditions d'utilisation de Medisplay ainsi que les règles liées aux créateurs de contenu</a>.</Checkbox>
                                </Form.Item>
                                <Form.Item name="subscribe" valuePropName="checked">
                                    <Checkbox name="subscribe" onChange={this.handleChangeCheckbox}>Je souhaite bénéficier gratuitement de l'abonnement me permettant d'obtenir automatiquement des campagnes proposées par Santé Ardenne. J'accepte que ces campagnes soient diffusées sur ma télévision sans autre validation de ma part. Les sujets transmis auront été contrôlés par un comité et seront transmis de façon saisonnière (vaccination grippe, canicule, tique, etc.). A tous moments, je peux me désinscrire de ce service.</Checkbox>
                                </Form.Item>
                                <Button type="primary" shape="round" size='large' htmlType="submit">Finaliser mon inscription</Button>
                            </Form>
                            
                        </Col>   
            }
        ];

        //Redirection si l'utilisateur a réussi a créer son compte
        if(this.state.redirect || store.getState().user.isLogged)
            return <Redirect to='/inscription' />;

        return (
            <section id="register">
                <Row className="headerPage">
                    <Col span={2} offset={3}>
                        <Link to={{pathname:"/"}}><Button shape="circle" icon={<ArrowLeftOutlined  />} size="large"/></Link>
                    </Col>
                    <Col>
                        <Title level={3}>Inscrivez-vous sur Medisplay</Title>
                    </Col>
                </Row>
                <Row justify='center' gutter={[48, 16]}> 
                    <Col span={8} className="asidestep">
                        <img src="img/register.png" alt="Cabinet d'un médecin"/>
                        <div className="infostep">
                            <Steps
                            progressDot
                            direction="vertical"
                            current={this.state.current}>
                                {steps.map((item, index) => (
                                    <>
                                    <Step title={item.title} key={index}/>
                                    </>

                                ))}
                            </Steps>
                        </div>
                    </Col>
                    {steps[this.state.current].content}    
                </Row>
            </section>
        );
    }
}

export default RegisterPage;