import { RRule } from 'rrule';

//Convertion de la date
export function ConvertDate(olddate){
    const year = olddate.getUTCFullYear();
    const month = olddate.getUTCMonth();
    const day = olddate.getUTCDate()
    const hour = olddate.getUTCHours();
    const min = olddate.getUTCMinutes();

	const date = {
        "year":year,
        "month":month,
        "day":day,
        "hour": hour,
        "min": min,
    }

	return date;
}

//Tri des listes A-Z
export function sortAZ(array,variable){
    array.sort((a, b) => {
        let na = a[variable].toLowerCase(),
            nb = b[variable].toLowerCase();
    
        if (na < nb) {
            return -1;
        }
        if (na > nb) {
            return 1;
        }
        return 0;
    });

    return array;
}

//Tri des listes date de début
export function sortDate(array, variable){
    array.sort((a, b) => {
        let na = a[variable],
            nb = b[variable];
    
        if (na < nb) {
            return -1;
        }
        if (na > nb) {
            return 1;
        }
        return 0;
    });

    return array;
}

//Tri des listes date de fin
export function sortDateInverse(array, variable){
    array.sort((a, b) => {
        let na = a[variable],
            nb = b[variable];
    
        if (na > nb) {
            return -1;
        }
        if (na < nb) {
            return 1;
        }
        return 0;
    });

    return array;
}


//Remplacer les accents
export function replaceCaracters(text){
    const caracters = {"à":"a","á":"a","â":"a","ã":"a","ä":"a","å":"a","ò":"o","ó":"o","ô":"o","õ":"o","ö":"o","ø":"o","è":"e","é":"e","ê":"e","ë":"e","ç":"c","ì":"i","í":"i","î":"i","ï":"i","ù":"u","ú":"u","û":"u","ü":"u","ÿ":"y","ñ":"n","-":" ","_":" "}; 

	var reg=/[àáäâèéêëçìíîïòóôõöøùúûüÿñ_-]/gi; 
	return text.replace(reg,function(){ return caracters[arguments[0].toLowerCase()];}).toLowerCase();
}