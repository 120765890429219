export const Types = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_AVATAR: 'UPDATE_USER_AVATAR',
    UPDATE_SELECTEDCOMPANY: 'UPDATE_SELECTEDCOMPANY',
    UPDATE_SELECTEDCAMPAIGN: 'UPDATE_SELECTEDCAMPAIGN',
    UPDATE_SELECTEDUSER: 'UPDATE_SELECTEDUSER',
    UPDATE_ACTIVETAB: 'UPDATE_ACTIVETAB',
}

export const ActionCreators = {
  
    updateProfile: (user) => ({ type: Types.UPDATE_USER, payload: { user } }),

    updateProfileAvatar: (url) => ({ type: Types.UPDATE_USER_AVATAR, payload: { url } }),
  
    login: (user) => ({ type: Types.LOGIN, payload: { user } }),

    logout: () => ({ type: Types.LOGOUT }),

    updateSelectedCompany: (selected_id,company) => ({ type: Types.UPDATE_SELECTEDCOMPANY, payload: { selected_id,company } }),

    updateSelectedCampaign: (selected_id) => ({ type: Types.UPDATE_SELECTEDCAMPAIGN, payload: { selected_id } }),

    updateSelectedUser: (selected_id) => ({ type: Types.UPDATE_SELECTEDUSER, payload: { selected_id } }),

    updateActiveTab: (index) => ({ type: Types.UPDATE_ACTIVETAB, payload: { index } }),
}

export default ActionCreators;