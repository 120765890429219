//Import des composants utiles
import React from 'react';
import { Link } from "react-router-dom";

//Import des composants de design
import { Button, Layout, Menu } from 'antd';
import {TeamOutlined,HomeOutlined,ClearOutlined,TagOutlined,BookOutlined,InfoCircleOutlined} from '@ant-design/icons';
import store from '../Store/Store';
const { Header, Content } = Layout;

export default function Navbar(props){

    return (
        <Menu mode="inline">
            {store.getState().user.profile.role ==  "ROLE_ADMIN"?<>

            {/* Utilisateurs */}
            <Menu.Item key="1" className={props.actual_tab=="Users"?"selected":""}>
                <Link to={{pathname:"/ausers"}}>
                    <TeamOutlined/>
                </Link>
            </Menu.Item>


            {/* Cabinets */}
            <Menu.Item key="2" className={props.actual_tab=="Cabinets"?"selected":""}>
                <Link to={{pathname:"/acabinets"}}>
                    <HomeOutlined/>
                </Link>
            </Menu.Item>

            {/* Créateurs de contenu */}
            <Menu.Item key="3" className={props.actual_tab=="Creators"?"selected":""}>
                <Link to={{pathname:"/acreators"}}>
                    <ClearOutlined/>
                </Link>
            </Menu.Item>

            {/* Campagnes */}
            <Menu.Item key="8" className={props.actual_tab=="Campaigns"?"selected":""}>
                <Link to={{pathname:"/campaigns"}}>
                    <BookOutlined />
                </Link>
            </Menu.Item>

            {/* Tags */}
            <Menu.Item key="4" className={props.actual_tab=="Tags"?"selected":""}>
                <Link to={{pathname:"/atags"}}>
                    <TagOutlined />
                </Link> 
            </Menu.Item>

            </>:<>

            {/* Cabinet */}
            <Menu.Item key="5" className={props.actual_tab=="Cabinets"||props.actual_tab=="Creators"?"selected":""}>
                <Link to={{pathname:"/workspace"}}>
                    <HomeOutlined/>
                </Link>
            </Menu.Item>

            {/* Campagnes */}
            <Menu.Item key="6" className={props.actual_tab=="Campaigns"?"selected":""}>
                <Link to={{pathname:"/campaigns"}}>
                    <BookOutlined />
                </Link>
            </Menu.Item>

            </>}

            {/* Faq */}
            <Menu.Item key="7" className={props.actual_tab=="Faq"?"selected":""}>
                <Link to={{pathname:"/faq"}}>
                    <InfoCircleOutlined/>
                </Link> 
            </Menu.Item>
        </Menu>
    )
}