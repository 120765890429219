import axios from 'axios';

//Communication avec l'API
export default axios.create({
  baseURL: `/`,
  withCredentials: true,
  headers: {
    'X-Requested-With' : 'XMLHttpRequest',
    'Accept' : 'application/json',
    'Content-Type' : 'application/json'
  }
});