import React from 'react';


//Import des composants de design
import { Tag, Card, Button, Typography, Layout, Spin, Popconfirm, message,Space, Avatar, Menu, Dropdown, Form, Input, InputNumber, List, Skeleton} from 'antd';
import { SyncOutlined,TagOutlined , LoadingOutlined,BorderOutlined,CloseOutlined,CheckSquareFilled, DownOutlined, DeleteOutlined ,EyeOutlined,ArrowLeftOutlined,ArrowRightOutlined,NotificationOutlined,FundProjectionScreenOutlined,FileAddOutlined, DesktopOutlined,EditOutlined,HeartOutlined,HeartFilled,PictureOutlined,DownloadOutlined  } from '@ant-design/icons';
const { Footer, Header, Sider, Content } = Layout;
import { Row, Col } from 'antd';

//Navigation profile utilisateur
export function EmptyCard(){

    return (
        <Card
                cover={
                    <Skeleton.Avatar active={true} size={150} shape='circle' />}
                className="form-card"
            >
                <Skeleton avatar paragraph={{ rows: 1 }} />
                <Skeleton avatar paragraph={{ rows: 1 }} />
                <Skeleton avatar paragraph={{ rows: 4 }} />                                              
            </Card>
    );
}