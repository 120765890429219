//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';
import { ActionCreators } from '../../Store/Action';
import { Redirect } from 'react-router-dom';

//Import des composants de design
import { Typography, Layout, Card, Avatar, Input, Row, Col, List, Empty, Badge } from 'antd';
import { HomeOutlined,ClearOutlined  } from '@ant-design/icons';
import { GoToCompanyButton, UserAccount } from '../../components/Component';
import store from '../../Store/Store';
const { Title, Text, Paragraph } = Typography;
const { Footer, Header, Sider, Content } = Layout;
const { Search } = Input;
const { Meta } = Card;

//Carte des détails de la formation
export function CabinetBigCard(props){
    
    return (
        <Card
        cover={props.cabinet.logo_url!=null?<Badge count={props.cabinet.type=="TYPE_CABINET"?<HomeOutlined />:<ClearOutlined />} offset={[-20, 30]}><Avatar src={props.cabinet.logo_url} size={146}/></Badge>:<Badge count={props.cabinet.type=="TYPE_CABINET"?<HomeOutlined />:<ClearOutlined />} offset={[-20, 30]}><Avatar size={146}>{props.cabinet.name.substring(0,3)}</Avatar></Badge>}
        actions={[<GoToCompanyButton route={'/company'} company={props.cabinet}/>]}
        >
            <Meta
                title={<Title level={5}>{props.cabinet.name}</Title>}
            />
        </Card>
    );
}

//Page de listing des cabinets
export default class Cabinets extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            companies:undefined,
            searchText: ""
        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadCompaniesList();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement de la liste des cabinets
    loadCompaniesList(){
        API.get('/api/users/'+store.getState().user.profile.id+'/companies')
        .then((res) => {
            if(this._isMounted){
                this.setState({companies: res.data})
            }

        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
                <Redirect to='/'/>
            }
        })
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){

        if(this.state.companies != undefined){

            if(this.state.companies.length == 1){
                store.dispatch(ActionCreators.updateSelectedCompany(this.state.companies[0].id, this.state.companies[0]));
                return <Redirect to='/company' element_id={this.state.companies[0].id}/>;
            }

            return (
                <Layout>
                    <Sider width={80} className="main_nav">
                        <img src="img/ico.png"/>
                        <Navbar actual_tab={"Cabinets"}/>
                    </Sider>
                    <Layout hasSider={false} className="main-container">
                        <Header>
                            <Row justify="end" align="middle">
                                <UserAccount />
                            </Row>
                        </Header>
                    {this.state.companies.length == 0?
                        <Content className="app-content">
                            <Row className="emptyBox" align="middle">     
                                <Col span={8}>    
                                    <Title level={3}>Bonjour <span className="hightlight">{store.getState().user.profile.first_name}</span>,</Title>               
                                    <Title level={4}>Il semblerait que vous ne soyez pas encore inscrit à un cabinet</Title>
                                    <Paragraph>
                                        Pas de panique, votre compte est actif sur Medisplay.
                                        Pour rejoindre un cabinet, demandez à vos collègues de vous ajouter ou contactez directement Medisplay.
                                    </Paragraph>
                                </Col>
                                <Col span={16}>
                                    <img src="img/nocabinet.png" alt="pas de cabinet"/>
                                </Col>
                            </Row>
                        </Content>
                    :
                        <Content className="app-content">
                            <Row justify="space-between" className="app-header">
                                <Col span={12}>
                                    <Title level={3}>Sélectionnez votre <span className="hightlight">espace de travail</span></Title>
                                </Col>
                            </Row>
                            <Row className="toolsbar" justify="space-between">
                                <Col>
                                    <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                                </Col>
                            </Row>
                            <Row>
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 4,
                                        xl: 6,
                                        xxl: 6,
                                    }}
                                    pagination={{
                                        pageSize: 12,
                                        hideOnSinglePage:true,
                                        position:'top'
                                    }}
                                    dataSource={this.state.companies.filter(company => (company.name).toLowerCase().includes(this.state.searchText.toLowerCase()))}
                                    renderItem={item => (
                                        <CabinetBigCard key={item.id} cabinet={item} />
                                        )
                                    }
                                    locale={{emptyText:<Empty
                                        image={<HomeOutlined />}
                                        description={this.state.searchText!=""?
                                        <span>
                                            Il n'y a pas de cabinet correspondant à votre recherche
                                        </span>
                                        :
                                        <span>
                                            Vous n'êtes pas encore inscrit à un cabinet
                                        </span>
                                        }
                                    >
                                    </Empty>}}
                                />
                            </Row>
                        </Content>
                    }
                    </Layout>
                </Layout>
            );
        }else{
            return (
                <Layout>
                    <Sider width={80} className="main_nav">
                        <img src="img/ico.png"/>
                        <Navbar actual_tab={"Cabinets"}/>
                    </Sider>
                    <Layout hasSider={false} className="main-container">
                        <Header>
                            <Row justify="end" align="middle">
                                <UserAccount />
                            </Row>
                        </Header>
                    </Layout>
                </Layout>
            );
        }
    }
}