//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import store from '../../Store/Store';
import API from '../../api';
import { RRule } from 'rrule';
import { sortDate } from '../../utils';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import arrayMove from 'array-move';

//Import des composants de design
import { Typography, Layout, Input, List, Empty } from 'antd';
import { Row, Col } from 'antd';
import { FlowAdd,FlowModify } from '../../components/LeftPanel';
import { GoBackTitle, UserAccount,FlowStreamSmallCard,FlowAddedStreamSmallCard } from '../../components/Component';
const { Title, Text } = Typography;
const { Search } = Input;
const { Header, Sider, Content } = Layout;
import { CalendarOutlined,InteractionOutlined  } from '@ant-design/icons';

// Reorder the list items
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 10;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle
});

//Page de l'entreprise
class FlowPage extends React.Component{
    constructor(props){
        super(props)
        if(props.location.pathname.includes("modify")){
            let flow=undefined;
            let addedStreams = [];
            if(props.location.state!=undefined){
                flow=props.location.state.flow;
                addedStreams=props.location.state.flow.broadcasts;
            }

            this.state = {
                flow: flow,
                searchText: "",
                broadcasts:[],
                addedStreams:addedStreams,
                duration:0,
                action:"edit",
            }
        }else{
            this.state = {
                flow: [],
                searchText: "",
                broadcasts:[],
                addedStreams:[],
                duration:0,
                action:"create",
            }
        }

        this.loadCompanyBroadcastsList = this.loadCompanyBroadcastsList.bind(this);
        
        this.addStream = this.addStream.bind(this);
        this.removeStream = this.removeStream.bind(this);
        this.totalDurationVideoStream = this.totalDurationVideoStream.bind(this);
        this.loadDurationVideoBroadcast = this.loadDurationVideoBroadcast.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        this.loadCompanyBroadcastsList();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des diffusions de l'entreprise
    loadCompanyBroadcastsList(){
        let broadcastsList = [];

        API.get('/api/companies/'+store.getState().user.selected_company+'/broadcasts')
        .then((res) => {
            res.data.forEach(async (broadcast) => {
                
                const rrule = RRule.fromString(broadcast.schedule).all();
                broadcast.start_date = new Date(rrule[0]).setHours(0, 0, 0);
                broadcast.end_date = new Date(rrule[(rrule.length-1)]).setHours(0, 0, 0);
                
                broadcastsList.push(broadcast);
                broadcastsList = sortDate(broadcastsList,"start_date");

                if(this._isMounted)
                    this.setState({
                        broadcasts:broadcastsList})
            });
        })
        .catch((err) => {
            //this.source.cancel("Request Error");
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    
    //Ajout d'une diffusion à la campagne
    async addStream(value, event){
        var addedStreams = this.state.addedStreams;
        const stream = this.state.broadcasts.filter(broadcast => broadcast.id == value)[0];
        addedStreams.push(stream);
        this.setState({addedStreams: addedStreams},() => this.totalDurationVideoStream());
    }

    //Suppresion d'une diffusion à la campagne
    removeStream(value){
        let addedStreams = this.state.addedStreams;
        addedStreams = addedStreams.filter(e => e.id !== value);
        this.setState({addedStreams: addedStreams},() => this.totalDurationVideoStream());
    }

    async totalDurationVideoStream(){
        let totalDuration = 0;

        for(const broadcast of this.state.addedStreams){
            const duration = await this.loadDurationVideoBroadcast(broadcast.file_url);
            totalDuration = totalDuration + duration;
            
        }
        this.setState({duration: totalDuration});
    }

    loadDurationVideoBroadcast(file) {
        return new Promise(resolve => {
            let video = document.createElement('video')
            video.setAttribute('src', file)
            video.onloadedmetadata = function() {
                resolve(Math.floor(this.duration));
            };
        });
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){

        //Redirection si l'utilisateur a réussi a créer une diffusion
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar  actual_tab={store.getState().user.company.type == "TYPE_CABINET"?"Cabinets":"Creators"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={8}>
                                <Title level={3}>Créer un <span className="hightlight">flux</span></Title>
                                {store.getState().user.profile.role == "ROLE_ADMIN"?<GoBackTitle title={"Revenir à l'entreprise"} route={"/company"}/>:<></>}
                            </Col>
                        </Row>
                        <Row gutter={[32, 16]}>
                            {/* Left Panel */}
                            <Col span={6}>
                                {this.state.action=="create"?
                                <FlowAdd duration={this.state.duration} addedStreams={this.state.addedStreams}/>
                                :
                                <FlowModify duration={this.state.duration} flow={this.state.flow} addedStreams={this.state.addedStreams}/>
                                }

                            </Col>
                            
                            {/* Middle Panel */}
                            <Col span={18}>
                                <Row className="toolsbar" justify="start">
                                    <Col>
                                        {/* Barre de Recherche */}
                                        <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                                    </Col>
                                </Row>
                                <Row gutter={[32, 16]}>
                                    <Col span={12}>
                                        <List
                                            dataSource={this.state.broadcasts.filter(broadcast => (broadcast.campaign.name).toLowerCase().includes(this.state.searchText.toLowerCase()) && this.state.addedStreams.filter(s => s.id == broadcast.id).length == 0)}
                                            renderItem={item => (
                                                <FlowStreamSmallCard key={item.id} broadcast={item} refresh_handler={this.loadCompanyBroadcastsList} add_handler={() => this.addStream(item.id)}/>
                                            )}
                                            pagination={{
                                                position:"top",
                                                pageSize: 8,
                                                hideOnSinglePage:true,
                                                size:"small",
                                                }}
                                            locale={{
                                                emptyText:
                                                    <Empty
                                                        image={<CalendarOutlined />}
                                                        description={
                                                        this.state.broadcasts.length>0?<span>
                                                            Vous n'avez plus de diffusion disponible
                                                        </span>
                                                        :
                                                        <span>
                                                            Vous n'avez pas encore créé de diffusion
                                                        </span>
                                                        }
                                                    >
                                                    </Empty>
                                            }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <List
                                            dataSource={this.state.addedStreams}
                                            renderItem={item => (
                                                <FlowAddedStreamSmallCard broadcast={item} remove_handler={() => this.removeStream(item.id)}/>
                                            )}
                                            pagination={{
                                                position:"top",
                                                pageSize: 8,
                                                hideOnSinglePage:true,
                                                size:"small",
                                                }}
                                            locale={{
                                                emptyText:
                                                    <Empty
                                                        image={<InteractionOutlined />}
                                                        description={
                                                        <span>
                                                            Vous n'avez pas encore ajouté de diffusion au flux
                                                        </span>
                                                        }
                                                    >
                                                    </Empty>
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default FlowPage;