//Import des composants utiles
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import API from '../api';
import store from '../Store/Store';
import { ActionCreators } from '../Store/Action';
import { RRule } from 'rrule'
import { ConvertDate } from '../utils';

//Import des composants de design
import { Switch, Card, Button, Typography, Layout, Avatar,List,Descriptions,Popconfirm,Space } from 'antd';
import { CheckOutlined,CloseOutlined,EditOutlined,EyeInvisibleOutlined,DeleteOutlined,EyeOutlined,DesktopOutlined,UserDeleteOutlined  } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Header, Sider, Content } = Layout;

//Carte mini d'une entreprise
export function CompanySmallCard(props){
    const handleClick = () => {store.dispatch(ActionCreators.updateSelectedCompany(props.company.id,props.company));}
    store.dispatch(ActionCreators.updateActiveTab("1"));

    const deleteCompanyUser = () => {
        API.delete('/api/companies/'+props.company.pivot.company_id+'/users/'+props.company.pivot.user_id).then(props.refresh_handler());
    }

    return (
        <List.Item
            actions={[<Link to={{pathname:'/company'}}>
                            <Button onClick={handleClick} type="text" shape="circle" icon={<EyeOutlined/>}/>   
                    </Link>,
                    <Popconfirm
                    title={<Space direction="vertical"><Text strong>Voulez-vous désinscrire {props.user.first_name} du cabinet ?</Text><Text type="secondary">Une fois désinscrit, l'utilisateur n'aura plus accès à l'espace du cabinet.</Text></Space>}
                    onConfirm={deleteCompanyUser}
                    cancelButtonProps={{type:'text',shape:'round'}}
                    okButtonProps={{type:'primary',shape:'round', danger:true}}
                    okText="Supprimer"
                    cancelText="Annuler"
                    >
                        <Button type="text" shape="circle" icon={<UserDeleteOutlined />} danger/>
                    </Popconfirm>
                ]}
        >
            <List.Item.Meta
                avatar={props.company.logo_url!=null?<Avatar src={props.company.logo_url}/>:<Avatar>{props.company.name.substring(0,3)}</Avatar>}
                title={<Text>{props.company.type == "TYPE_CABINET"?"Cabinet":"Créateur de contenu"} {props.company.name}</Text>}
              />
        </List.Item>
    );
}

//Carte mini d'une diffusion
export function StreamSmallCard(props){ 
    const rule = RRule.fromString(props.broadcast.schedule)
    const datestart= new Date(rule.all()[0]);
    const dateend=new Date(rule.all()[rule.all().length-1]);
    const handleDelete = () => {
        API.delete('api/broadcasts/'+props.broadcast.id).then(() => props.refresh_handler())
    }

    return (
        <List.Item
            actions={[<Button  onClick={() => window.open(props.broadcast.file_url, '_blank')} type="text" shape="circle" icon={<EyeOutlined/>}/>,
            <Link to={{pathname:'/modifystream',state:{broadcast:props.broadcast}}}>
                <Button type="text" shape="circle" icon={<EditOutlined />}/>   
            </Link>
            ,<Button onClick={handleDelete} type="text" shape="circle" icon={<DeleteOutlined/>} danger/>
                ]}
        >
            <List.Item.Meta
                title={props.broadcast.campaign.name}
                description={"Du "+datestart.getDate()+"/"+(datestart.getMonth()+1)+"/"+datestart.getFullYear()+" - Au "+dateend.getDate()+"/"+(dateend.getMonth()+1)+"/"+dateend.getFullYear()}
            />
        </List.Item>
    );
}

export function AgendaStreamSmallCard(props){ 
    const rule = RRule.fromString(props.broadcast.schedule)
    const datestart= new Date(rule.all()[0]);
    const dateend=new Date(rule.all()[rule.all().length-1]);

    return (
        <List.Item
            actions={[<Button  onClick={() => window.open(props.broadcast.file_url, '_blank')} type="text" shape="circle" icon={<EyeOutlined/>}/>
                ]}
        >
            <List.Item.Meta
                title={props.broadcast.campaign.name}
                description={props.broadcast.flux_name != undefined&&"Flux - "+props.broadcast.flux_name}
            />
            <Descriptions title="Du" column="1">
                <Descriptions.Item>{datestart.getDate()}/{datestart.getMonth()+1}/{datestart.getFullYear()}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Au" column="1">
                <Descriptions.Item>{dateend.getDate()}/{dateend.getMonth()+1}/{dateend.getFullYear()}</Descriptions.Item>
            </Descriptions>
        </List.Item>
    );
}

//Carte mini d'un flux
export function FlowSmallCard(props){ 

    const [visible, setVisible] = useState(false);
    const [subscribed, setSubscribed] = useState(props.flow.subscribed);

    const unsubscribe = () => {
        API.delete('api/companies/'+store.getState().user.selected_company+'/flows/'+props.flow.id)
        .then((res)=> {
            setSubscribed(false);
            setVisible(false);
            props.refresh_handler();
        })
        .catch((err) => {  
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    const subscribe = () => {
        API.put('api/companies/'+store.getState().user.selected_company+'/flows/'+props.flow.id)
        .then((res)=> {
            setSubscribed(true);
            props.refresh_handler();
        })
        .catch((err) => {  
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    const handleCancel = () => {
        setVisible(false);
      };

    const onChange = (checked) => {
        if(checked){
            subscribe();
        }else{
            setVisible(true);
        }
    };

    let actionsButtons = [];

    if(props.flow.subscribed){
        actionsButtons.push(<Link to={{pathname:'/manageflow',state:{flow:props.flow}}}>
            <Button type="text" shape="circle" icon={<EditOutlined />}/>   
        </Link>);
    }

    actionsButtons.push(
            <Popconfirm
            title={<Space direction="vertical"><Text strong>Voulez-vous vous désabonner ?</Text><Text type="secondary">En vous désabonnant, votre moniteur ne diffusera plus le contenu lié au flux</Text></Space>}
            visible={visible}
            onConfirm={unsubscribe}
            onCancel={handleCancel}
            cancelButtonProps={{type:'text',shape:'round'}}
            okButtonProps={{type:'primary',shape:'round', danger:true}}
            okText="Se désabonner"
            cancelText="Annuler"
            >
                <Switch
                onChange={onChange}
                checkedChildren={"Abonné"}
                unCheckedChildren={"Désabonné"}
                checked={subscribed}
                />
            </Popconfirm>
    )

    return (
        <List.Item
            actions={actionsButtons}
        >
            <List.Item.Meta
                title={props.flow.name}
                description={props.flow.description}
            />
        </List.Item>
    );
}

//Carte mini d'un flux
export function FlowAdminSmallCard(props){ 

    const handleDelete = () => {
        API.delete('api/flows/'+props.flow.id).then(() => props.refresh_handler())
        
    }


    return (
        <List.Item
            actions={[<Link to={{pathname:'/modifyflow',state:{flow:props.flow}}}>
                <Button type="text" shape="circle" icon={<EditOutlined />}/>   
            </Link>,
            <Popconfirm
            title={<Space direction="vertical"><Text strong>Voulez-vous vous supprimer le flux ?</Text><Text type="secondary">La supression du flux supprimera sa diffusion sur les moniteurs des cabinets abonnés</Text></Space>}
            onConfirm={handleDelete}
            cancelButtonProps={{type:'text',shape:'round'}}
            okButtonProps={{type:'primary',shape:'round', danger:true}}
            okText="Supprimer"
            cancelText="Annuler"
          ><Button type="text" shape="circle" icon={<DeleteOutlined />} danger/>
          </Popconfirm>]}
        >
            <List.Item.Meta
                title={props.flow.name}
                description={props.flow.description}
            />
        </List.Item>
    );
}


//Carte mini d'un moniteur
export function MonitorSmallCard(props){

    const handleDelete = () => {
        API.delete('api/broadcasters/'+props.broadcaster.id).then(() => props.refresh_handler())
    }

    return (
        <List.Item
            actions={[<Button onClick={handleDelete} type="text" shape="circle" icon={<DeleteOutlined />} danger/>]}
        >
            <List.Item.Meta
                avatar={<DesktopOutlined />}
                title={props.broadcaster.name}
                description={props.broadcaster.description}
              />
        </List.Item>
    );
}

//Carte mini d'un utilisateur
export function UserSmallCard(props){
    const deleteCompanyUser = () => {
        API.delete('/api/companies/'+props.user.pivot.company_id+'/users/'+props.user.id).then(props.refresh_handler());
    }
    
    var date = new Date(props.user.created_at);

    return (
        <List.Item
            actions={[<Button type="text" shape="circle" onClick={props.select_handler} icon={<EyeOutlined/>}/>,
            <Popconfirm
            title={<Space direction="vertical"><Text strong>Voulez-vous désinscrire {props.user.first_name} du cabinet ?</Text><Text type="secondary">Une fois désinscrit, l'utilisateur n'aura plus accès à l'espace du cabinet.</Text></Space>}
            onConfirm={deleteCompanyUser}
            cancelButtonProps={{type:'text',shape:'round'}}
            okButtonProps={{type:'primary',shape:'round', danger:true}}
            okText="Supprimer"
            cancelText="Annuler"
            >
                <Button type="text" shape="circle" icon={<UserDeleteOutlined />} danger/>
            </Popconfirm>
            ]}
        >
            <List.Item.Meta
                avatar={
                    props.user.avatar_url!=null?<Avatar src={props.user.avatar_url} size={52}/>:<Avatar size={52}>{props.user.first_name.substring(0,1)}{props.user.last_name.substring(0,1)}</Avatar>
                }
                title={props.user.first_name+" "+props.user.last_name}
                description={"Membre depuis le "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()}
              />
        </List.Item>
    );
}