//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';

//Import des composants de design
import { Typography, Layout, Input, List, Empty } from 'antd';
import { TagOutlined  } from '@ant-design/icons';
import { LastTagView, TagAdd } from '../../components/RightPanel';
import { Row, Col } from 'antd';
import { TagSmallCard, UserAccount } from '../../components/Component';
const { Title, Text } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;

//Page de gestion des fichiers
class TagsPage extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            tags:[],
            searchText: ""
        }

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadTagsList();
    }

    loadTagsList(){
        API.get('/api/tags')
        .then((res) => {

            let tags = res.data;
            tags.sort((a, b) => {
                let na = a.name.toLowerCase(),
                    nb = b.name.toLowerCase();
            
                if (na < nb) {
                    return -1;
                }
                if (na > nb) {
                    return 1;
                }
                return 0;
            });

            if(this._isMounted)
                this.setState({tags: tags})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar actual_tab={"Tags"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={8}>
                                <Title level={3}>Gérer les <span className="hightlight">tags</span></Title>
                            </Col>
                        </Row>
                        <Row className="toolsbar" justify="space-between">
                            <Col>
                                {/* Barre de Recherche */}
                                <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                            </Col>
                        </Row>
                        {/* Main Panel */}
                        <Row gutter={[32, 16]}>
                            <Col span={16}>
                                <List
                                    dataSource={this.state.tags.filter(tag => (tag.label).toLowerCase().includes(this.state.searchText.toLowerCase()))}
                                    renderItem={item => (
                                        <TagSmallCard key={item.id} tag={item} refresh_handler={this.loadTagsList.bind(this)}/>
                                    )}
                                    pagination={{
                                        pageSize: 8,
                                        hideOnSinglePage:true,
                                        size:"small",
                                        position:'top'
                                        }}
                                        locale={{
                                            emptyText:
                                                <Empty
                                                    image={<TagOutlined />}
                                                    description={this.state.searchText!=""?
                                                    <span>
                                                        Il n'y a pas de tag correspondant à votre recherche
                                                    </span>
                                                    :
                                                    <span>
                                                        Il n'y a pas encore de tag
                                                    </span>
                                                    }
                                                />
                                        }}
                                    />
                            </Col>

                            {/* Right Panel */}
                            <Col span={8}>
                                <Row gutter={[32, 16]}>
                                    <Col span={24}>
                                        <TagAdd refresh_handler={this.loadTagsList.bind(this)}/>
                                    </Col>
                                    <Col span={24}>
                                        <LastTagView lastTags={this.state.tags.slice(0,3)}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default TagsPage;