//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';
import store from '../../Store/Store';
import { ActionCreators } from '../../Store/Action';
import { RRule } from 'rrule';
import { sortAZ, sortDate } from '../../utils';

//Import des composants de design
import { Button, Typography, Layout, Tabs, Image, Dropdown,Avatar, Menu, Input, List, Card, Calendar,Select,Radio,Skeleton,Space,Empty,Tooltip,Popconfirm,Segmented   } from 'antd';
import { Row, Col } from 'antd';
import { CompanyCard } from '../../components/LeftPanel';
import { InteractionOutlined, WarningOutlined, EyeOutlined,DisconnectOutlined, LinkOutlined,PictureOutlined, HeartOutlined,FrownOutlined,HeartFilled,FilePptOutlined,CalendarOutlined,FundProjectionScreenOutlined,FileExclamationOutlined,DesktopOutlined,VideoCameraOutlined,FileTextOutlined,SyncOutlined  } from '@ant-design/icons';
import { AddAMember, AddAFile, AddMonitorBtn } from '../../components/Modal';
import {AgendaStreamSmallCard,  UserSmallCard, FlowSmallCard,FlowAdminSmallCard, MonitorSmallCard, StreamSmallCard } from '../../components/MiddlePanel';
import { SmallCreatorCard, MonitorProfileCard, MonitorsView, FileUpload } from '../../components/RightPanel';
import { CampaignSmallCard, FileSmallCard, GoBackTitle, StreamBtn, FlowBtn, CampaignAddBtn, UserAccount, Tags } from '../../components/Component';
import SkeletonImage from 'antd/lib/skeleton/Image';
import reducer from '../../Store/Reducers/User';
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Header, Sider, Content } = Layout;
const { Search } = Input;

//Page d'une entreprise
class CompanyPage extends React.Component{
    constructor(props){
        super(props)

        let company ={};
        if(props.location.state!=undefined){
            company=props.location.state.company;
        }else{
            company=store.getState().user.company;
        }

        this.state = {
            company: company,
            files: [],
            favorite_files: [],
            favorite_campaigns: [],
            campaigns: [],
            broadcasts: [],
            broadcasts_filter: [],
            users: [],
            broadcasters: [],
            fileTags: [],
            campaignTags: [],
            companyFlows:[],
            flows:[],
            agendaList:[],
            duration:0,
            filter_favorite_campaign: false,
            filter_favorite_files: false,
            filter_type_file_image : true,
            filter_type_file_video : true,
            filter_type_file_document : true,
            filter_loading_campaign: true,
            filter_included_campaigns:"all",
            filter_included_broadcasts:"now",
            filter_date:new Date(),
            searchFileText: "",
            searchCampaignText: "",
            selectedUser: [],
            extratabs: 1,
            loading_company:false,
            loading_broadcasts:false,
            loading_users:false,
            loading_campaigns:false,
            loading_files:false,
            loading_broadcasters:false,
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;

        this.loadCompanyDetails = this.loadCompanyDetails.bind(this);
        this.loadCompanyUsersList = this.loadCompanyUsersList.bind(this);
        this.loadCompanyFilesList = this.loadCompanyFilesList.bind(this);
        this.loadCompanyCampaignsList = this.loadCompanyCampaignsList.bind(this);
        this.loadCompanyBroadcastsList = this.loadCompanyBroadcastsList.bind(this);
        this.loadCompanyBroadcastersList =  this.loadCompanyBroadcastersList.bind(this);
        this.loadCompanyFlowsList = this.loadCompanyFlowsList.bind(this);
        this.defineAgendaBroadcastList = this.defineAgendaBroadcastList.bind(this);
        this.loadTagsList = this.loadTagsList.bind(this);
        this.loadUserFavorite = this.loadUserFavorite.bind(this);
        this.handle_tab_change = this.handle_tab_change.bind(this);

        this.select_user = this.select_user.bind(this);
        this.onFileSearch = this.onFileSearch.bind(this);
        this.handleOnChangeFileSearch = this.handleOnChangeFileSearch.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.totalDurationVideoStream = this.totalDurationVideoStream.bind(this);
        this.onCampaignSearch = this.onCampaignSearch.bind(this);
        this.handleOnChangeCampaignSearch = this.handleOnChangeCampaignSearch.bind(this);
        this.favorite_campaign = this.favorite_campaign.bind(this);
        this.favorite_files = this.favorite_files.bind(this);
        this.filter_type_file_image = this.filter_type_file_image.bind(this);
        this.filter_type_file_video = this.filter_type_file_video.bind(this);
        this.filter_type_file_document = this.filter_type_file_document.bind(this);
        this.filter_loading_campaign = this.filter_loading_campaign.bind(this);
        this.filter_included_campaigns = this.filter_included_campaigns.bind(this);
        this.filter_included_broadcasts = this.filter_included_broadcasts.bind(this);
        this.setCalendarDate = this.setCalendarDate.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;
        
        this.loadCompanyDetails();
        this.loadCompanyUsersList();
        this.loadCompanyFilesList();
        this.loadCompanyCampaignsList();
        this.loadCompanyBroadcastsList();
        this.loadCompanyBroadcastersList();
        this.loadCompanyFlowsList();
        //this.loadTagsList(); Tags removed
        this.loadUserFavorite();

        this.handle_tab_change(store.getState().user.active_tab);

    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des tags
    loadTagsList(){
        API.get('/api/tags')
        .then((res) => {
            this.setState({fileTags: res.data})
        })
        .catch((err) => {
            //this.source.cancel("Request Error");

            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })

        API.get('/api/tags')
        .then((res) => {
            this.setState({campaignTags: res.data})
        })
        .catch((err) => {
            //this.source.cancel("Request Error");

            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    //Chargement des détails de l'entreprise
    loadCompanyDetails(){
        API.get('api/companies/'+store.getState().user.selected_company)
        .then((res)=> {
            this.setState({company: res.data,loading_company:true});
            store.dispatch(ActionCreators.updateSelectedCompany(res.data.id,res.data));

        })
        .catch((err) => { 
            //this.source.cancel("Request Error");
            
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    //Chargement des membres de l'entreprise
    loadCompanyUsersList(){
        API.get('/api/companies/'+store.getState().user.selected_company+'/users')
        .then((res) => {
            let users = res.data;
            users = sortAZ(users,"last_name");

            this.setState({users: users,
                selectedUser:res.data[0],
                loading_users:true
            });

        })
        .catch((err) => {
            //this.source.cancel("Request Error");
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    //Chargement des fichiers de l'entreprise
    loadCompanyFilesList(){

        API.get('/api/companies/'+store.getState().user.selected_company+'/files')
        .then((res) => {
            let files = [];
            res.data.forEach(async (file) => {
                //const tags = await API.get('/api/files/'+file.id+"/tags");
                //file["tags"] = tags.data;
                files.push(file);
                files = sortAZ(files, "name");

                this.setState({files:files});
            });
            this.setState({files:files,loading_files:true});

        })
        .catch((err) => {
            //this.source.cancel("Request Error");

            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    //Chargement des campagnes de l'entreprise

    loadCompanyCampaignsList(){
        let campaigns = [];

        API.get('/api/campaigns')
        .then((res) => {
            //let campaigns = [];
            //var publicCampaigns = [];
            
            res.data.forEach(async (campaign) => {
                if(campaign.public){
                    //const tags = await API.get('/api/campaigns/'+campaign.id+"/tags");
                    //campaign["tags"] = tags.data

                    const company = await API.get('/api/companies/'+campaign.company_id);
                    campaign["company"] = company.data

                    campaigns.push(campaign)
                    campaigns = sortDate(campaigns, "updated_at");

                }else{
                    if(campaign.company_id == store.getState().user.selected_company){
                        //const tags = await API.get('/api/campaigns/'+campaign.id+"/tags");
                        //campaign["tags"] = tags.data

                        const company = await API.get('/api/companies/'+store.getState().user.selected_company);
                        campaign["company"] = company.data
    
                        campaigns.push(campaign)
                        campaigns = sortDate(campaigns, "updated_at");
                    }
                }
                
                if(this._isMounted)
                    this.setState({campaigns:campaigns
                    })
            });
            this.setState({
                loading_campaigns:true,
            });
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    loadCompanyBroadcastsList(){
        let broadcastsList = [];

        API.get('/api/companies/'+store.getState().user.selected_company+'/broadcasts')
        .then((res) => {
            res.data.forEach(async (broadcast) => {
                
                const rrule = RRule.fromString(broadcast.schedule).all();
                broadcast.start_date = new Date(rrule[0]).setHours(0, 0, 0);
                broadcast.end_date = new Date(rrule[(rrule.length-1)]).setHours(0, 0, 0);
                
                broadcastsList.push(broadcast);
                broadcastsList = sortDate(broadcastsList,"start_date");
            });
            this.setState({
                loading_broadcasts:true,
                broadcasts:broadcastsList
            },() => {this.filterBroadcastPlaylist()});
        })
        .catch((err) => {
            //this.source.cancel("Request Error");
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    filterBroadcastPlaylist(){
        let playlist=[];

        const today = new Date(this.state.filter_date.getFullYear(),this.state.filter_date.getMonth(), this.state.filter_date.getDate());

        if(this._isMounted){
            if(this.state.broadcasts != undefined){
                if(this.state.broadcasts.length>0){
                    this.state.broadcasts.forEach(broadcast => {
                        if(today >= broadcast.start_date && today <= broadcast.end_date){
                            playlist.push(broadcast);
                        }
                    });
                }
            }
        }

        this.setState({
            broadcasts_filter:playlist,
        },() => {this.defineAgendaBroadcastList()});
    }

    defineAgendaBroadcastList(){
        /* Merge broadcasts list and broadcasts flows list */
        let agendaList = [];
        this.state.broadcasts_filter.forEach((broadcast) => {
            agendaList.push(broadcast);
        })
        const today = new Date(this.state.filter_date.getFullYear(),this.state.filter_date.getMonth(), this.state.filter_date.getDate());
        this.state.flows.forEach((flow) => {
            if(flow.subscribed){
                flow.broadcasts.forEach((broadcast) => {
                    if(!flow.pivot.blacklist.includes(broadcast.id)){
                        const rrule = RRule.fromString(broadcast.schedule).all();
                        broadcast.start_date = new Date(rrule[0]).setHours(0, 0, 0);
                        broadcast.end_date = new Date(rrule[(rrule.length-1)]).setHours(0, 0, 0);
                        broadcast.flux_name = flow.name;
                        broadcast.agenda_id = broadcast.id.toString()+flow.pivot.id.toString();
                        if(today >= broadcast.start_date && today <= broadcast.end_date){
                            agendaList.push(broadcast);
                        }
                    }
                })
            }
        });
        this.setState({agendaList:agendaList},() => this.totalDurationVideoStream());
    }

    //Chargement des moniteurs de l'entreprise
    loadCompanyBroadcastersList(){
        API.get('/api/companies/'+store.getState().user.selected_company+'/broadcasters')
        .then((res) => {
            let broadcasters = res.data;
            broadcasters = sortAZ(broadcasters, "name");

            this.setState({broadcasters:broadcasters,
                loading_broadcasters:true})
        })
        .catch((err) => {
            //this.source.cancel("Request Error");

            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    loadCompanyFlowsList(){
        let flows = [];
        let companyFlows = [];
        API.get('/api/flows')
        .then((res) => {
            flows = res.data
            API.get('/api/companies/'+store.getState().user.selected_company+'/flows')
            .then((res) => {
                let subscribedFlows = res.data;
                flows.forEach(async (flow) => {
                    /* Add the flow into the company creator list */
                    if(flow.company_id == store.getState().user.selected_company){
                        companyFlows.push(flow);
                    }
                    /* Add the flow into the doctor company list based on subscriptions */
                    if(subscribedFlows.filter(subscribedFlow => subscribedFlow.id == flow.id)[0]!=undefined){
                        flow["subscribed"]=true;
                        if(subscribedFlows.filter(subscribedFlow => subscribedFlow.id == flow.id)[0].pivot.blacklist==null){
                            subscribedFlows.filter(subscribedFlow => subscribedFlow.id == flow.id)[0].pivot.blacklist=[];
                        }
                        flow["pivot"]=subscribedFlows.filter(subscribedFlow => subscribedFlow.id == flow.id)[0].pivot;
                    }else{
                        flow["subscribed"]=false;
                        flow["pivot"]=undefined;
                    }
                });
                this.setState({flows:flows, companyFlows:companyFlows},() => {this.defineAgendaBroadcastList()}); 
            })
            .catch((err) => {
                if(err != undefined){
                    if(err.response.status == 401){
                        store.dispatch(ActionCreators.logout());
                    }
                }
            })
        })
        .catch((err) => {
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    //Chargement des fichiers et campagnes favorite
    loadUserFavorite(){
        API.get('/api/users/'+store.getState().user.profile.id+'/files')
        .then((res) => {
            this.setState({favorite_files:res.data});
        })
        .catch((err) => {
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })

        API.get('/api/users/'+store.getState().user.profile.id+'/campaigns')
        .then((res) => {
            this.setState({favorite_campaigns:res.data})
        })
        .catch((err) => {
            if(err != undefined){
                if(err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }
        })
    }

    //Prise en charge de la sélection d'un utilisateur
    select_user(user, event){
        if(this._isMounted)
            this.setState({selectedUser: user})
    }

    //Prise en charge du changement de menu
    handle_tab_change(value){
        if(value != undefined){
            store.dispatch(ActionCreators.updateActiveTab(value));
            this.setState({extratabs: value});
        }
    }

    //Modification des tags sélectionner
    changeFileTag(event){
        const tags = this.state.fileTags;
        tags[event.key]["selected"] = !tags[event.key]["selected"];

        const files = [];

        if(tags.filter(tag => tag.selected == true).length == 0){
            this.loadCompanyFilesList();
        }else{
            tags.filter(tag => tag.selected == true).forEach((tag) => {
                const fileWithTag = this.state.files.filter(file => file.tags.filter(rtag => rtag.label.includes(tag.label)).length > 0);

                fileWithTag.forEach(nfile => {
                    if(files.filter(file => file.id == nfile.id) == 0)
                        files.push(nfile)
                })

                if(this._isMounted)
                    this.setState({files: files})
                        
            })
        }

        if(this._isMounted)
            this.setState({fileTags:tags})
    }

    //Modification des tags sélectionner
    changeCampaignTag(event){
        const tags = this.state.campaignTags;
        tags[event.key]["selected"] = !tags[event.key]["selected"];

        const campaigns = [];

        if(tags.filter(tag => tag.selected == true).length == 0){
            this.loadCompanyCampaignsList();
        }else{
            tags.filter(tag => tag.selected == true).forEach((tag) => {
                const campaignWithTag = this.state.campaigns.filter(campaign => campaign.tags.filter(rtag => rtag.label.includes(tag.label)).length > 0);

                campaignWithTag.forEach(ncampaign => {
                    if(campaigns.filter(campaign => campaign.id == ncampaign.id) == 0)
                        campaigns.push(ncampaign)
                })

                if(this._isMounted)
                    this.setState({campaigns: campaigns})
                        
            })
        }

        if(this._isMounted)
            this.setState({campaignTags:tags})
    }

    onFileSearch = value => this.setState({searchFileText: value});
    handleOnChangeFileSearch = event => this.setState({searchFileText: event.target.value});
    onCampaignSearch = value => this.setState({searchCampaignText: value});
    handleOnChangeCampaignSearch = event => this.setState({searchCampaignText: event.target.value});
    favorite_campaign = () => this.setState({filter_favorite_campaign: !this.state.filter_favorite_campaign});
    favorite_files = () => this.setState({filter_favorite_files: !this.state.filter_favorite_files});
    filter_type_file_image = () => this.setState({filter_type_file_image: !this.state.filter_type_file_image});
    filter_type_file_video = () => this.setState({filter_type_file_video: !this.state.filter_type_file_video});
    filter_type_file_document = () => this.setState({filter_type_file_document: !this.state.filter_type_file_document});
    filter_loading_campaign = () => this.setState({filter_loading_campaign: !this.state.filter_loading_campaign});
    filter_included_campaigns = value => this.setState({filter_included_campaigns: value});
    filter_included_broadcasts = value => this.setState({filter_included_broadcasts: value});



    handleSort(value){
        switch(this.state.extratabs){
            case "4":
                switch (value) {
                    case 'date':
                        sortDate(this.state.campaigns,"updated_at");
                        break;
                    default:
                        sortAZ(this.state.campaigns,"name");
                }
                break;
            case "5":     
                switch (value) {
                    case 'date':
                        sortDate(this.state.files,"updated_at");
                        break;
                    default:
                        sortAZ(this.state.files,"name");
                }
                break;
        }

        this.setState({});
    }

    setCalendarDate = function(value){
        this.setState({filter_date: value._d}, () =>{this.filterBroadcastPlaylist();this.defineAgendaBroadcastList();})
    };
    
    totalDurationVideoStream(){
        let totalDuration = 0;

        for(const broadcast of this.state.agendaList){
            totalDuration = totalDuration + broadcast.campaign.total_duration;   
        }
        this.setState({duration: totalDuration});
    }

    render(){

        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        /*éléments visuels */

        const fileMenu = (
            <Menu onClick={this.changeFileTag.bind(this)}>
                {(this.state.fileTags.length > 0)?
                    this.state.fileTags.map((tag, index) => {
                            return <Menu.Item key={index}>{tag.label}</Menu.Item>
                    })
                :<Menu.Item key="0" disabled> Vous n'avez pas de tag </Menu.Item>} 
            </Menu>
        )

        const campaignMenu = (
            <Menu onClick={this.changeCampaignTag.bind(this)}>
                {(this.state.campaignTags.length > 0)?
                    this.state.campaignTags.map((tag, index) => {
                            return <Menu.Item key={index}>{tag.label}</Menu.Item>
                    })
                :<Menu.Item key="0" disabled> Vous n'avez pas de tag </Menu.Item>} 
            </Menu>
        )

        let backarrow = "";

        let extratabs = <></>;
        switch(this.state.extratabs){
            case "2": extratabs=<AddAMember company_users={this.state.users} company={this.state.company} refresh_handler={this.loadCompanyUsersList}/>;
                break;
            case "3": extratabs=<StreamBtn route={"createstream"}/>;
                break;
            case "4": extratabs=<CampaignAddBtn route={"createcampaign"}/>;
                break;
            case "5": extratabs=<AddAFile refresh_handler={this.loadCompanyFilesList} refresh_company={this.loadCompanyDetails}/>;
                break;
            case "6": extratabs=<AddMonitorBtn company_id={store.getState().user.selected_company} broadcasts={this.state.broadcasts} refresh_handler={this.loadCompanyBroadcastersList}/>;
                break;
            case "7":extratabs=<FlowBtn route={"createflow"}/>;
                break;
            default: extratabs = <></>;
                break;   
        }

        if(store.getState().user.profile.role ==  "ROLE_ADMIN"){
            if(this.state.company.type != "TYPE_CABINET"){
                backarrow = <>
                    <Title level={3}>Créateur de contenu <span className="hightlight"> {this.state.company.name}</span></Title>
                    <GoBackTitle title={"Revenir aux créateur de contenu"} route={"/acreators"}/>
                </>;
            }else{
                backarrow = <>
                    <Title level={3}>Cabinet <span className="hightlight"> {this.state.company.name}</span></Title>
                    <GoBackTitle title={"Revenir aux cabinets"} route={"/acabinets"}/>
                </>;
            }
        }else{
            if(this.state.company.type != "TYPE_CABINET"){
                backarrow = <Title level={3}>Créateur de contenu <span className="hightlight"> {this.state.company.name}</span></Title>;
            }else{
                backarrow = <Title level={3}>Cabinet <span className="hightlight"> {this.state.company.name}</span></Title>;            
            }
        }

        const broadcasts_filter = (
            <Row justify="end">
                <Col>
                    <Segmented
                        defaultValue={this.state.filter_included_broadcasts}
                        onChange={this.filter_included_broadcasts}
                        options={[
                        {
                            label: 'Toutes',
                            value: 'all',
                        },
                        {
                            label: 'Passées',
                            value: 'past',
                        },
                        {
                            label: "Aujourd'hui",
                            value: 'now',
                        },
                        {
                            label: 'A venir',
                            value: 'then',
                        },
                        ]}
                    />
                </Col>                   
            </Row>)

        /* Filtre des fichiers */

        let filterFiles=[];
        if(this.state.filter_favorite_files){
            this.state.files.filter(file => (file.name).toLowerCase().includes(this.state.searchFileText.toLowerCase())).filter(file => this.state.favorite_files.filter(ffile => ffile.id==file.id).length > 0).forEach(file =>{
                if(this.state.filter_type_file_image && file.type == "TYPE_IMAGE"){
                    filterFiles.push(file);
                }
                if(this.state.filter_type_file_video && file.type == "TYPE_VIDEO"){
                    filterFiles.push(file);
                }
                if(this.state.filter_type_file_document && file.type == "TYPE_DOCUMENT"){
                    filterFiles.push(file);
                }
            })
        }else{
            this.state.files.filter(file => (file.name).toLowerCase().includes(this.state.searchFileText.toLowerCase())).forEach(file => {
                if(this.state.filter_type_file_image && file.type == "TYPE_IMAGE"){
                    filterFiles.push(file);
                }
                if(this.state.filter_type_file_video && file.type == "TYPE_VIDEO"){
                    filterFiles.push(file);
                }
                if(this.state.filter_type_file_document && file.type == "TYPE_SLIDESHOW"){
                    filterFiles.push(file);
                }
            });
        }
        

        /* Filtre des campagnes */

        let filterCampaigns=[];
        if(this.state.filter_favorite_campaign){
            this.state.campaigns.filter(campaign => (campaign.name).toLowerCase().includes(this.state.searchCampaignText.toLowerCase())).filter(campaign => this.state.favorite_campaigns.filter(fcampaign => fcampaign.id==campaign.id).length > 0).forEach(campaign =>{
                if(this.state.filter_loading_campaign){
                    filterCampaigns.push(campaign);
                }
                else {
                    if(!campaign.in_creation){
                        filterCampaigns.push(campaign);
                    }
                }     
            })
        }else{
            this.state.campaigns.filter(campaign => (campaign.name).toLowerCase().includes(this.state.searchCampaignText.toLowerCase())).forEach(campaign => {
                if(this.state.filter_loading_campaign){
                    filterCampaigns.push(campaign);
                }
                else {
                    if(!campaign.in_creation){
                        filterCampaigns.push(campaign);
                    }
                } 
            });
        }

        let filterIncludedCampaigns = [];
        filterCampaigns.forEach((campaign) => {
            switch(this.state.filter_included_campaigns){
                case "campaigns":
                    if(!campaign.public){
                        filterIncludedCampaigns.push(campaign);
                    }
                    break;
                case "publicCampaigns":
                    if(campaign.public){
                        filterIncludedCampaigns.push(campaign);
                    }
                    break;
                default:
                    filterIncludedCampaigns.push(campaign);
                    break;   
            }
        })

        /* Filtre diffusion */       
        let filterIncludedBroadcasts=[];
        this.state.broadcasts.forEach((broadcast) => {
            const rrule = RRule.fromString(broadcast.schedule).all();
            broadcast.start_date = new Date(rrule[0]).setHours(0, 0, 0);
            broadcast.end_date = new Date(rrule[(rrule.length-1)]).setHours(0, 0, 0);
            switch(this.state.filter_included_broadcasts){
                case "past":
                    if(broadcast.end_date < today){
                        filterIncludedBroadcasts.push(broadcast);
                    }
                    break;
                case "now":
                    if(today >= broadcast.start_date && today <= broadcast.end_date){
                        filterIncludedBroadcasts.push(broadcast);
                    }
                    break;
                case "then":
                    if(broadcast.start_date > today){
                        filterIncludedBroadcasts.push(broadcast);
                    }
                    break;
                default:
                    filterIncludedBroadcasts.push(broadcast);
                    break;   
            }
        });



        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar actual_tab={this.state.company.type == "TYPE_CABINET"?"Cabinets":"Creators"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" align="bottom" className="app-header">
                            <Col span={12}>
                                {backarrow}
                            </Col>
                            <Col span={4}>
                                <Row justify="end" >
                                {this.state.users.length>0?
                                    <>{this.state.loading_users?
                                        <Avatar.Group maxCount={4}>
                                            {this.state.users.map(user =>{
                                                return <Tooltip key={user.id} title={user.first_name+" "+user.last_name} placement="bottom">{user.avatar_url!=null?<Avatar key={user.id} src={user.avatar_url}/>:<Avatar key={user.id} className='smallAvatar'>{user.first_name.substring(0,1)}{user.last_name.substring(0,1)}</Avatar>}</Tooltip>
                                            })}
                                        </Avatar.Group>
                                    :<Skeleton.Avatar/>}
                                    </>
                                :<></>}
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={[32, 16]}>
                            {/* Left Panel */}
                            <Col span={7}>
                                <CompanyCard company={this.state.company} refresh_handler={this.loadCompanyDetails}/>
                            </Col>
                            <Col span={17} className="tabspanel">
                                {this.state.loading_company && this.state.loading_broadcasts && this.state.loading_users && this.state.loading_campaigns && this.state.loading_files && this.state.loading_broadcasters?

                                    <Tabs defaultActiveKey={store.getState().user.active_tab} onChange={this.handle_tab_change} tabBarExtraContent={extratabs}>
                                        <TabPane tab="L'agenda" key="1">
                                                <Row gutter={[32, 16]}>
                                                    {/* Middle Panel */}
                                                    <Col span={14}>
                                                        {this.state.duration>0&&
                                                        <>
                                                        {this.state.filter_date>= today?
                                                            <>
                                                                {
                                                                    this.state.duration/60 > 15?
                                                                    <Row>
                                                                    <h6 className="error">Vous avez {Math.trunc(this.state.duration/60)+"' "+this.state.duration%60+"''"} de temps de diffusion ce {this.state.filter_date.getDate()+"/"+(this.state.filter_date.getMonth()+1)+"/"+this.state.filter_date.getFullYear()}</h6>
                                                                    <Tooltip title="Votre de temps de diffusion est plus long que le temps moyen d'attente de consultation. Il est donc possible que des patients ne voient pas l'ensemble de vos campagnes.">
                                                                        <WarningOutlined className="error"/>
                                                                    </Tooltip>
                                                                    </Row>
                                                                    :<h6>Vous avez <span className="hightlight"> {Math.trunc(this.state.duration/60)+"' "+this.state.duration%60+"''"} </span>de temps de diffusion ce <span className="hightlight">{this.state.filter_date.getDate()+"/"+(this.state.filter_date.getMonth()+1)+"/"+this.state.filter_date.getFullYear()}</span></h6>
                                                                }
                                                            </>
                                                            :<h6>Vous aviez <span className="hightlight"> {Math.trunc(this.state.duration/60)+"' "+this.state.duration%60+"''"} </span>de temps de diffusion ce <span className="hightlight">{this.state.filter_date.getDate()+"/"+(this.state.filter_date.getMonth()+1)+"/"+this.state.filter_date.getFullYear()}</span></h6>
                                                         }
                                                        </>
                                                        }
                                                        <List
                                                            dataSource={this.state.agendaList}
                                                            renderItem={item => (
                                                                <AgendaStreamSmallCard key={item.pivot!=undefined?item.agenda_id:item.id} broadcast={item}/>
                                                            )}
                                                            pagination={{
                                                                pageSize: 8,
                                                                hideOnSinglePage:true,
                                                                size:"small",
                                                                position:'top'
                                                                }}
                                                                locale={{
                                                                    emptyText:
                                                                        <Empty
                                                                            image={<CalendarOutlined />}
                                                                            description={
                                                                            <span>
                                                                                Vous n'avez pas de diffusion prévue ce {this.state.filter_date.getDate()+"/"+(this.state.filter_date.getMonth()+1)+"/"+this.state.filter_date.getFullYear()}
                                                                            </span>
                                                                            }
                                                                        >
                                                                        </Empty>
                                                                }}
                                                        />
                                                    </Col>
                                                    <Col span={10}>
                                                        <Space direction='vertical' size='large'>
                                                        <Card>
                                                            <Calendar 
                                                            onChange={this.setCalendarDate}
                                                            fullscreen={false}
                                                            headerRender={({ value, type, onChange, onTypeChange }) => {
                                                                const start = 0;
                                                                const end = 12;
                                                                const monthOptions = [];

                                                                const current = value.clone();
                                                                const localeData = value.localeData();
                                                                const months = [];
                                                                for (let i = 0; i < 12; i++) {
                                                                    current.month(i);
                                                                    months.push(localeData.monthsShort(current));
                                                                }

                                                                for (let index = start; index < end; index++) {
                                                                    monthOptions.push(
                                                                    <Select.Option className="month-item" key={`${index}`}>
                                                                        {months[index]}
                                                                    </Select.Option>,
                                                                    );
                                                                }
                                                                const month = value.month();

                                                                const year = value.year();
                                                                const options = [];
                                                                for (let i = year - 10; i < year + 10; i += 1) {
                                                                    options.push(
                                                                    <Select.Option key={i} value={i} className="year-item">
                                                                        {i}
                                                                    </Select.Option>,
                                                                    );
                                                                }
                                                                return (
                                                                    <div style={{ padding: 8 }}>
                                                                    <Row gutter={8}>
                                                                        <Col>
                                                                        <Select
                                                                            size="small"
                                                                            dropdownMatchSelectWidth={false}
                                                                            value={String(month)}
                                                                            onChange={selectedMonth => {
                                                                            const newValue = value.clone();
                                                                            newValue.month(parseInt(selectedMonth, 10));
                                                                            onChange(newValue);
                                                                            }}
                                                                        >
                                                                            {monthOptions}
                                                                        </Select>
                                                                        </Col>
                                                                        <Col>
                                                                        <Select
                                                                            size="small"
                                                                            dropdownMatchSelectWidth={false}
                                                                            className="my-year-select"
                                                                            onChange={newYear => {
                                                                            const now = value.clone().year(newYear);
                                                                            onChange(now);
                                                                            }}
                                                                            value={String(year)}
                                                                        >
                                                                            {options}
                                                                        </Select>
                                                                        </Col>
                                                                        
                                                                    </Row>
                                                                    </div>
                                                                );
                                                                }}
                                                            
                                                            />
                                                        </Card> 
                                                        <h6>Vos moniteurs</h6>
                                                        <List
                                                        dataSource={this.state.broadcasters}
                                                        renderItem={(item) => (
                                                            <MonitorSmallCard key={item.id} broadcaster={item} refresh_handler={this.loadCompanyBroadcastersList}/>
                                                        )}
                                                        pagination={{
                                                            pageSize: 3,
                                                            hideOnSinglePage:true,
                                                            size:"small",
                                                            position:'bottom'
                                                            }}
                                                            locale={{
                                                                emptyText:
                                                                    <Empty
                                                                        image={<></>}
                                                                        description={
                                                                        <span>
                                                                        </span>
                                                                        }
                                                                    >
                                                                        {/*<Row justify="center">
                                                                            <AddMonitorBtn company_id={store.getState().user.selected_company} broadcasts={this.state.broadcasts} refresh_handler={this.loadCompanyBroadcastersList}/>
                                                                        </Row>*/}
                                                                    </Empty>
                                                            }}   
                                                        />
                                                        </Space>
                                                    </Col>
                                                </Row>
                                        </TabPane>

                                        <TabPane tab="L'équipe" key="2">
                                            <Row gutter={[32, 16]}>
                                                {/* Middle Panel */}
                                                <Col span={(this.state.users.length > 0)?14:24}>
                                                    <List
                                                        dataSource={this.state.users}
                                                        renderItem={item => (
                                                            <UserSmallCard key={item.id} user={item} refresh_handler={this.loadCompanyUsersList} select_handler={this.select_user.bind(this, item)}/>
                                                        )}
                                                        pagination={{
                                                            pageSize: 8,
                                                            hideOnSinglePage:true,
                                                            size:"small",
                                                            position:'top'
                                                            }}
                                                            locale={{
                                                                emptyText:
                                                                    <Empty
                                                                        image={<FrownOutlined />}
                                                                        description={
                                                                        <span>
                                                                            Vous n'avez pas encore ajouté de membre
                                                                        </span>
                                                                        }
                                                                    >
                                                                        <Row justify="center">
                                                                            <AddAMember company_users={this.state.users} company={this.state.company} refresh_handler={this.loadCompanyUsersList}/>
                                                                        </Row>
                                                                    </Empty>
                                                            }} 
                                                    />
                                                </Col>

                                                {/* Right Panel */}
                                                {(this.state.users.length > 0)?
                                                    <Col span={10}>
                                                        <SmallCreatorCard user={this.state.selectedUser}/>
                                                    </Col>
                                                :<></>}
                                            </Row>
                                        </TabPane>

                                        
                                        <TabPane tab="Les diffusions" key="3">

                                            {this.state.company.type == "TYPE_CABINET"?<Tabs defaultActiveKey="1" tabBarExtraContent={broadcasts_filter}>
                                                <TabPane tab="Les diffusions" key="1">
                                                    <List
                                                        dataSource={filterIncludedBroadcasts}
                                                        renderItem={item => (
                                                            <StreamSmallCard key={item.id} broadcast={item} refresh_handler={this.loadCompanyBroadcastsList}/>
                                                        )}
                                                        className="list-columns"
                                                        pagination={{
                                                            position:"top",
                                                            pageSize: 8,
                                                            hideOnSinglePage:true,
                                                            size:"small",
                                                            }}
                                                        locale={{
                                                            emptyText:
                                                                <Empty
                                                                    image={<CalendarOutlined />}
                                                                    description={
                                                                    <span>
                                                                        Vous n'avez pas encore créé de diffusion
                                                                    </span>
                                                                    }
                                                                >
                                                                    <Row justify="center">
                                                                        <StreamBtn route={"createstream"}/>
                                                                    </Row>
                                                                </Empty>
                                                        }}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Les flux" key="2">
                                                    <List
                                                        dataSource={this.state.flows}
                                                        renderItem={item => (
                                                            <FlowSmallCard key={item.id} flow={item} refresh_handler={this.loadCompanyFlowsList}/>
                                                        )}
                                                        className="list-columns"
                                                        pagination={{
                                                            position:"top",
                                                            pageSize: 8,
                                                            hideOnSinglePage:true,
                                                            size:"small",
                                                            }}
                                                        locale={{
                                                            emptyText:
                                                                <Empty
                                                                    image={<InteractionOutlined />}
                                                                    description={
                                                                    <span>
                                                                        Il n'existe pas encore de flux
                                                                    </span>
                                                                    }
                                                                >
                                                                </Empty>
                                                        }}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                            :
                                            <List
                                                dataSource={this.state.broadcasts}
                                                renderItem={item => (
                                                    <StreamSmallCard key={item.id} broadcast={item} refresh_handler={this.loadCompanyBroadcastsList}/>
                                                )}
                                                className="list-columns"
                                                pagination={{
                                                    position:"top",
                                                    pageSize: 8,
                                                    hideOnSinglePage:true,
                                                    size:"small",
                                                    }}
                                                locale={{
                                                    emptyText:
                                                        <Empty
                                                            image={<CalendarOutlined />}
                                                            description={
                                                            <span>
                                                                Vous n'avez pas encore créé de diffusion
                                                            </span>
                                                            }
                                                        >
                                                            <Row justify="center">
                                                                <StreamBtn route={"createstream"}/>
                                                            </Row>
                                                        </Empty>
                                                }}
                                            />
                                            }
                                        </TabPane>

                                        <TabPane tab="Les campagnes" key="4">
                                            {this.state.campaigns.length>0&&
                                            <Row className="toolsbar" justify="space-between">
                                                <Col span={10}>
                                                    <Row>
                                                        <Col>
                                                            {/* Barre de Recherche */}
                                                            <Search placeholder="Rechercher un élément" onSearch={this.onCampaignSearch} onChange={this.handleOnChangeCampaignSearch} enterButton />
                                                        </Col>
                                                        <Col className="filtersbuttons">
                                                            {/* Filtre */}
                                                            <Row>
                                                                <Button onClick={this.favorite_campaign} type="text" shape="circle" icon={this.state.filter_favorite_campaign?<HeartFilled/>:<HeartOutlined/>} />
                                                                <Button onClick={this.filter_loading_campaign} type={this.state.filter_loading_campaign?"default":"text"} shape="circle" icon={<SyncOutlined />} />
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={14}>
                                                    <Row justify="end">
                                                        <Col>
                                                            <Segmented
                                                                onChange={this.filter_included_campaigns}
                                                                options={[
                                                                {
                                                                    label: 'Toutes',
                                                                    value: 'all',
                                                                },
                                                                {
                                                                    label: 'Mes campagnes',
                                                                    value: 'campaigns',
                                                                },
                                                                {
                                                                    label: 'Campagnes publiques',
                                                                    value: 'publicCampaigns',
                                                                },
                                                                ]}
                                                            />
                                                        </Col>
                                                        <Col span={3}>
                                                            <Select defaultValue="date" onChange={this.handleSort} bordered={false}>
                                                                <Select.Option value="az">A-Z</Select.Option>
                                                                <Select.Option value="date">Date</Select.Option>
                                                            </Select>
                                                        </Col>                     
                                                    </Row>
                                                </Col>
                                                {/*<Col>
                                                    {(this.state.campaignTags.length > 0)?
                                                    this.state.campaignTags.filter(tag => tag.selected == true).map((tag, index) => {
                                                            return <Tags key={index} title={tag.label}/>
                                                    })
                                                    :<></>}  

                                                    <Dropdown overlay={campaignMenu}>
                                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                            <Text>Sélectioner un tag</Text> <DownOutlined />
                                                        </a>
                                                    </Dropdown>
                                                </Col>*/}
                                            </Row>
                                            }

                                            {/* Main Panel */}
                                                <List
                                                    dataSource={filterIncludedCampaigns}
                                                    renderItem={item => (
                                                        <CampaignSmallCard key={item.id} type="Admin" campaign={item} favorite={this.state.favorite_campaigns.filter(fcampaign => fcampaign.id==item.id).length > 0} favorite_handler={this.loadUserFavorite} refresh_handler={this.loadCompanyCampaignsList}/>
                                                    )}
                                                    pagination={{
                                                        pageSize: 5,
                                                        hideOnSinglePage:true,
                                                        size:"small",
                                                        position:'top'
                                                        }}
                                                        locale={{
                                                            emptyText:
                                                                <Empty
                                                                    image={<FundProjectionScreenOutlined />}
                                                                    description={  
                                                                        
                                                                    this.state.searchCampaignText!=""?
                                                                    <span>
                                                                        Il n'y a pas de campagne correspondante à votre recherche
                                                                    </span>
                                                                    :
                                                                    <>
                                                                        {this.state.filter_included_campaigns == "PublicCampaigns"?<span>Il n'y a pas encore de campagne publique</span>:<span>Il n'existe pas encore de campagne pour votre cabinet</span>}
                                                                    </>
                                                                    }
                                                                >
                                                                    {this.state.filter_included_campaigns != "PublicCampaigns"&&
                                                                    <Row justify="center">
                                                                        <CampaignAddBtn route={"createcampaign"}/>
                                                                    </Row>
                                                                    }
                                                                </Empty>
                                                        }}   
                                                />
                                        </TabPane>

                                        <TabPane tab="Les fichiers" key="5">
                                            {this.state.files.length>0&&
                                            <Row className="toolsbar" justify="space-between">
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            {/* Barre de Recherche */}
                                                            <Search placeholder="Rechercher un élément" onSearch={this.onFileSearch} onChange={this.handleOnChangeFileSearch} enterButton />
                                                        </Col>
                                                        <Col className="filtersbuttons">
                                                            {/* Filtre */}
                                                            <Row>
                                                                <Button onClick={this.favorite_files} type={this.state.filter_favorite_files?"default":"text"} shape="circle" icon={this.state.filter_favorite_files?<HeartFilled/>:<HeartOutlined/>} />
                                                                <Button onClick={this.filter_type_file_image} type={this.state.filter_type_file_image?"default":"text"} shape="circle" icon={<PictureOutlined />} />
                                                                <Button onClick={this.filter_type_file_video} type={this.state.filter_type_file_video?"default":"text"} shape="circle" icon={<VideoCameraOutlined />} />
                                                                <Button onClick={this.filter_type_file_document} type={this.state.filter_type_file_document?"default":"text"} shape="circle" icon={<FilePptOutlined />} />
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={3}>
                                                    <Select defaultValue="az" onChange={this.handleSort} bordered={false}>
                                                        <Select.Option value="az">A-Z</Select.Option>
                                                        <Select.Option value="date">Date</Select.Option>
                                                    </Select>
                                                </Col>                     

                                                {/*<Col>
                                                    {(this.state.fileTags.length > 0)?
                                                    this.state.fileTags.filter(tag => tag.selected == true).map((tag, index) => {
                                                            return <Tags remove_handler={remove_handler} key={index} title={tag.label}/>
                                                    })
                                                    :<></>} 

                                                    <Dropdown overlay={fileMenu}>
                                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                            <Text>Sélectioner un tag</Text> <DownOutlined />
                                                        </a>
                                                    </Dropdown>
                                                </Col>*/}
                                            </Row>}
                                                
                                            {/* Main Panel */}   
                                            <List
                                                dataSource={filterFiles}
                                                renderItem={item => (
                                                    <FileSmallCard key={item.id} type="Admin" file={item} favorite={this.state.favorite_files.filter(ffile => ffile.id==item.id).length > 0} favorite_handler={this.loadUserFavorite} refresh_handler={this.loadCompanyFilesList}/>
                                                )}
                                                className="list-columns"
                                                pagination={{
                                                    position:"top",
                                                    pageSize: 8,
                                                    hideOnSinglePage:true,
                                                    size:"small",
                                                    }}
                                                locale={{
                                                    emptyText:
                                                        <Empty
                                                            image={<FileExclamationOutlined/>}
                                                            description={this.state.searchFileText!=""?
                                                            <span>
                                                                Il n'y a pas de fichier correspondant à votre recherche
                                                            </span>
                                                            :
                                                            <span>
                                                                Vous n'avez pas encore de fichier
                                                            </span>
                                                            }
                                                        >
                                                            <Row justify="center">
                                                                <AddAFile refresh_handler={this.loadCompanyFilesList} refresh_company={this.loadCompanyDetails}/>
                                                            </Row>
                                                        </Empty>
                                                }}
                                            />
                                            
                                        </TabPane>

                                        <TabPane tab="Les moniteurs" key="6">
                                            <List
                                                dataSource={this.state.broadcasters}
                                                renderItem={item => (
                                                    <MonitorSmallCard key={item.id} broadcaster={item} refresh_handler={this.loadCompanyBroadcastersList}/>
                                                )}
                                                className="list-columns"
                                                pagination={{
                                                    pageSize: 8,
                                                    hideOnSinglePage:true,
                                                    size:"small",
                                                    position:'top'
                                                    }}
                                                    locale={{
                                                        emptyText:
                                                            <Empty
                                                                image={<DesktopOutlined />}
                                                                description={
                                                                <span>
                                                                    Vous n'avez pas encore synchronisé de moniteur
                                                                </span>
                                                                }
                                                            >
                                                                <Row justify="center">
                                                                    <AddMonitorBtn company_id={store.getState().user.selected_company} broadcasts={this.state.broadcasts} refresh_handler={this.loadCompanyBroadcastersList}/>
                                                                </Row>
                                                            </Empty>
                                                    }}   
                                            />
                                        </TabPane>

                                        {this.state.company.type == "TYPE_COMPANY"&& this.state.company.name == "Santé Ardenne"&&
                                        <TabPane tab="Les flux" key="7">
                                            <List
                                                dataSource={this.state.companyFlows}
                                                renderItem={item => (
                                                    <FlowAdminSmallCard key={item.id} flow={item} refresh_handler={this.loadCompanyFlowsList}/>
                                                )}
                                                className="list-columns"
                                                pagination={{
                                                    pageSize: 8,
                                                    hideOnSinglePage:true,
                                                    size:"small",
                                                    position:'top'
                                                    }}
                                                    locale={{
                                                        emptyText:
                                                            <Empty
                                                                image={<InteractionOutlined />}
                                                                description={
                                                                <span>
                                                                    Vous n'avez pas encore créé de flux de diffusion
                                                                </span>
                                                                }
                                                            >
                                                                <Row justify="center">
                                                                    <FlowBtn route={"createflow"}/>
                                                                </Row>
                                                            </Empty>
                                                    }}   
                                            />
                                        </TabPane>}
                                    </Tabs>
                                :<>
                                    <Row justify="end">
                                        <Skeleton.Button shape="round" active/>
                                    </Row>
                                    <Row>
                                        <Skeleton paragraph={{ rows: 4 }} active/>
                                    </Row>
                                </>}
                            </Col>
                        </Row>
                    </Content>
                    
                </Layout>
            </Layout>
        );
        
    }
}

export default CompanyPage;