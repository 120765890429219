//Import des composants utiles
import React from 'react';
import API from '../api';
import { ActionCreators } from '../Store/Action';
import store from '../Store/Store';
import { Link, Redirect } from 'react-router-dom';

//Import des composants de design
import { ArrowLeftOutlined  } from '@ant-design/icons';
import { Form, Input, Card, Button, Typography, Layout, Checkbox } from 'antd';
import { PasswordReset } from '../components/Modal';
const { Title, Text } = Typography;
const { Footer, Header, Content } = Layout;
import { Row, Col } from 'antd';

//Page de connexion de l'utilisateur
class Login extends React.Component{
    constructor(props){
        super(props)

        this.formRef = React.createRef();

        this.state = {
            email: "",
            password: "",
            remember: false,
            error_message: "",
            connected: false
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    //Prise en charge de la modification des champs
    handleChange(event){
        if(this._isMounted)
            this.setState({[event.target.name]: event.target.value})

    }

    handleCheckedChange(event){
        if(this._isMounted)
            this.setState({[event.target.name]: event.target.checked})
    }

    //Prise en charge de la connexion de l'utilisateur
    login(){
        API.post('/login',{
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            remember: this.state.remember
        })
        .then((res) => {
            if(this._isMounted && res.data.id != undefined){
                store.dispatch(ActionCreators.login(res.data));
                this.setState({connected: true});
            }else{
                this.setState({error_message: "Réessayer"});
                API.post('/logout');
            }
        })
        .catch((err) => {
            switch(err.response.status){
                case 422:
                    this.setState({error_message: "Email ou mot de passe incorrect"})
                    break;
                default:
                    this.setState({error_message: "Erreur Inconnue"})
            }
        })      
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        if(this.state.connected || store.getState().user.isLogged){
            if(store.getState().user.profile.role == "ROLE_ADMIN"){
                return <Redirect to='/acabinets' />;
            }else{
                return <Redirect to='/workspace' />;
            }
        }

        return (
            <section id="login">
                <Row>
                    <Col span={6} offset={3}>
                        <Button shape="circle" icon={<ArrowLeftOutlined  />} size="large" href="/"/>
                        <Title level={3}>Connectez-vous à la plateforme</Title>
                        
                        <Form id="loginForm" layout="vertical" ref={this.formRef} onFinish={this.login.bind(this)}> 
                            <Form.Item name="login_email" label="Email" normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Veuillez entrer votre email' },{ type: 'email', message:"Votre email n'est pas valide : exemple@domaine.be" }]}>     
                                <Input autoComplete={"username"} name={"email"} size="large" onChange={this.handleChange.bind(this)} value={this.state.email} placeholder={"Entrez votre Email"}/>
                            </Form.Item>
                            <Form.Item name="login__password" label="Mot de passe" normalize={e => e.replace(/\s/g, '')} rules={[{ required: true, message: 'Veuillez entrer votre mot de passe' }]}>
                                <Input.Password autoComplete={"current-password"} name={"password"} size="large" onChange={this.handleChange.bind(this)} placeholder={"Entrez votre mot de passe"}/>
                            </Form.Item>
                            {this.state.error_message != "" &&
                                <Row justify="center">
                                    <Text type="danger">{this.state.error_message}</Text>
                                </Row>
                            } 
                            <Row className="small_options" justify="space-between">
                                <Form.Item>
                                    <Checkbox style={{paddingTop: 5}} name={"remember"} onChange={this.handleCheckedChange.bind(this)}>Se souvenir de moi</Checkbox>
                                </Form.Item>
                                <PasswordReset/> 
                            </Row>
                        </Form>  

                        <Row justify="center"> 
                            <Button type="primary" shape="round" size='large' form="loginForm" htmlType="submit" key="submit">Se connecter</Button>
                        </Row>
                        <Row justify="center">
                            <Link to={{pathname:"/register"}}>Je n'ai pas encore de compte</Link>
                        </Row>   
                  
                    </Col>
                </Row>
            </section>
        );
    }
}

export default Login;