//Import des composants utiles
import React from 'react';
import API from '../api';
import { Redirect } from 'react-router-dom';
import store from '../Store/Store';
import { ActionCreators } from '../Store/Action'
import axios from 'axios';
import arrayMove from 'array-move';
import { RRule } from 'rrule';
import moment from 'moment';

//Import des composants de design
import { Form, Card, Avatar, Input, Button, Typography, Layout, AutoComplete, notification, DatePicker, TimePicker, Badge, Checkbox,Tooltip } from 'antd';
import { EditCompanyAvatar, EditUserAvatar, EditUserProfil,EditCompanyDetails, EditPassword, DeleteUser, DeleteCompany} from '../components/Modal';
import { Tags } from './Component'
import { EmptyCard } from './Empty';
const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;
const { Footer, Header, Sider, Content } = Layout;
import { Row, Col } from 'antd';
import { FieldNumberOutlined,InfoCircleOutlined,InteractionOutlined, SendOutlined, EyeOutlined,TagOutlined,CoffeeOutlined, UserOutlined,MailOutlined, KeyOutlined, ClearOutlined, FolderOutlined,AlignLeftOutlined, PhoneOutlined, FileProtectOutlined, EnvironmentOutlined, ClockCircleOutlined,DesktopOutlined,FundProjectionScreenOutlined,CalendarOutlined } from '@ant-design/icons';

const { Meta } = Card;

//Carte d'un profile Utilisateur
export class UserProfileCard extends React.Component{
    constructor(props){
        super(props);

        //Récupération des informations dans le store
        this.state = {
            user: props.user,
            date: new Date(props.user.created_at)
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        this.sendConfirmationMail = this.sendConfirmationMail.bind(this)

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        let config = { cancelToken: this.source.token}

        this.loadUserInfo(config);
    }

    componentWillUnmount(){
        this._isMounted = false;
        //this.source.cancel("Unmounted Component");
    }

    //Chargement des informations de l'utilisateur
    loadUserInfo(config){
        API.get('api/users/'+store.getState().user.profile.id, config)
        .then((res) => {
            if(this._isMounted){
                store.dispatch(ActionCreators.updateProfile(res.data))
                this.setState({user: res.data})
            }
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Envoie d'un mail de confirmation de l'addresse email (Laisser au cas ou)
    sendConfirmationMail(){
        API.post('/email/verification-notification')
        .then((res) => {
            notification.success({message: "Email de confirmation envoyé !",duration:4.5})
        })
        .catch((err) => {
            notification.error({message: "Erreur lors de l'envoi du mail !",duration:4.5})
        })
    }

    //Prise en charge de la modification des champs
    handleChange(event){
        if(this._isMounted){
            const profile = this.state.user;
            profile[event.target.name] = event.target.value;
            this.setState({user: profile});
        }
    }

    render(){

        const icon = () => {
            if(this.state.user.role=="ROLE_ADMIN"){
                return <CoffeeOutlined />
            }else{
                let icon = <UserOutlined/>;
                if(this.state.user.compagnies != undefined){
                    this.state.user.compagnies.forEach((compagny)=>{
                        if(compagny.type=="TYPE_COMPANY"){
                            icon = <ClearOutlined />;
                        }
                    })
                }
                return icon;
            }
        }
    
        const functionLabel = () => {
            switch (this.state.user.function_label){
                case 'medecin':
                    return "Médecin généraliste";
                    break;
                case 'secretaire':
                    return "Secrétaire";
                    break;
                case 'coord':
                    return "Coordinat.eur.rice";
                    break;
                case 'infi':
                    return "Infirmier-ère";
                    break;
                case 'kine':
                    return "Kinésithérapeute";
                    break;
                case 'psy':
                    return "Psychologue";
                    break;
                case 'assist':
                    return "Assistant.e sociale";
                    break;
                case 'promot':
                    return "Promoteur de la santé";
                default:
                    return "Autre";
            }
        }

        console.log(this.state.user);

        if(this._isMounted && this.state.user!={}){
            return (
                <Card
                    cover={<Badge count={icon()} offset={[-20, 30]}><EditUserAvatar user={this.state.user} refresh_handler={this.loadUserInfo.bind(this)} /></Badge>}
                    actions={[
                        <DeleteUser user={this.state.user}/>,
                        <EditUserProfil user={this.state.user}  refresh_handler={this.loadUserInfo.bind(this)}/>,
                    ]}
                    className="form-card"
                >
                    <Meta
                        title={<Title level={5}>{this.state.user.first_name} {this.state.user.last_name}</Title>}
                    />

                    {/* membre */}
                    <Row justify="center">
                        <Col span={2}>
                            <UserOutlined />
                        </Col>
                        <Col span={14} className="ant-form-item-label">
                            <label>{functionLabel()}</label>
                        </Col>
                    </Row>

                    {/* Inami */}
                    {this.state.user.inami != null&&
                        <Row justify="center">
                            <Col span={2}>
                                <FieldNumberOutlined />
                            </Col>
                            <Col span={14} className="ant-form-item-label">
                                <label>{this.state.user.inami}</label>
                            </Col>
                        </Row>
                    }

                    {/* email */}
                    <Row justify="center">
                        <Col span={2}>
                            <MailOutlined />
                        </Col>
                        <Col span={14} className="ant-form-item-label">
                            <label>{this.state.user.email}</label>
                        </Col>
                    </Row>
                    {/* Mot de passe */}
                    <Row justify="center">
                        <Col span={2}>
                            <KeyOutlined />
                        </Col>
                        <Col span={14} className="ant-form-item-label">
                            {/* Bouton de modification du mot de passe*/}
                            <EditPassword user={this.state.user}/>
                        </Col>
                    </Row>
                    
                    {/* Téléphone */}
                    {this.state.user.phone_number != null&&
                        <Row justify="center">
                            <Col span={2}>
                                <PhoneOutlined />
                            </Col>
                            <Col span={14} className="ant-form-item-label">
                                <label>{this.state.user.phone_number}</label>
                            </Col>
                        </Row>
                    }

                    {/* Adresse */}
                    {this.state.user.street != null&&
                        <Row justify="center">
                            <Col span={2}>
                                <EnvironmentOutlined />
                            </Col>
                            <Col span={14} className="ant-form-item-label">
                                <label>{this.state.user.street}, {this.state.user.street_number}</label>
                                <label>{this.state.user.zip_code} {this.state.user.locality}</label>
                            </Col>
                        </Row>    
                    }

                    {/* membre */}
                    <Row justify="center">
                        <Col span={2}>
                            <UserOutlined />
                        </Col>
                        <Col span={14} className="ant-form-item-label">
                            <label>Membre depuis le {this.state.date.getDate()}/{(this.state.date.getMonth()+1)}/{this.state.date.getFullYear()}</label>
                        </Col>
                    </Row>                                            
                </Card>
            );
        }else{
            return <EmptyCard/>
        }
    }
}

//Carte d'un profile Utilisateur
export class OtherUserProfileCard extends React.Component{
    constructor(props){
        super(props);
        //Récupération des informations dans le store
        this.state = {
            user: props.user,
            base_email: "",
            date:new Date(props.user.created_at),
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        this.sendConfirmationMail = this.sendConfirmationMail.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        let config = { cancelToken: this.source.token}

        this.loadUserInfo(config);
    }

    componentWillUnmount(){
        this._isMounted = false;
        //this.source.cancel("Unmounted Component");
    }

    //Chargement des informations de l'utilisateur
    loadUserInfo(config){
        API.get('api/users/'+store.getState().user.selected_user, config)
        .then((res) => {
            if(this._isMounted){
                this.setState({user: res.data})
                this.setState({base_email: res.data.email})
            }
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    
    //Envoie d'un mail de confirmation de l'addresse email (Laisser au cas ou)
    sendConfirmationMail(){
        API.post('/email/verification-notification/'+this.state.user.email)
        .then((res) => {
            notification.success({message: "Email de confirmation envoyé !", duration:4.5})
        })
        .catch((err) => {
            notification.error({message: "Erreur lors de l'envoi du mail !", duration:4.5})
        })
    }

    render(){

        const icon = () => {
            if(this.state.user.role=="ROLE_ADMIN"){
                return <CoffeeOutlined />
            }else{
                let icon = <UserOutlined/>;
                if(this.state.user.compagnies != undefined){
                    this.state.user.compagnies.forEach((compagny)=>{
                        if(compagny.type=="TYPE_COMPANY"){
                            icon = <ClearOutlined />;
                        }
                    })
                }
                return icon;
            }
        }
    
        const functionLabel = () => {
            switch (this.state.user.function_label){
                case 'medecin':
                    return "Médecin généraliste";
                    break;
                case 'secretaire':
                    return "Secrétaire";
                    break;
                case 'coord':
                    return "Coordinat.eur.rice";
                    break;
                case 'infi':
                    return "Infirmier-ère";
                    break;
                case 'kine':
                    return "Kinésithérapeute";
                    break;
                case 'psy':
                    return "Psychologue";
                    break;
                case 'assist':
                    return "Assistant.e sociale";
                    break;
                case 'promot':
                    return "Promoteur de la santé";
                default:
                    return "Autre";
            }
        }

        if(this._isMounted && this.state.user!={} && this.state.user!=undefined){
            return (
                <Card
                    cover={
                        this.state.user.avatar_url!=null?<Badge count={icon()} offset={[-20, 30]}><Avatar src={this.state.user.avatar_url} size={150} style={{marginRight:"auto",marginLeft:"auto"}}/></Badge>:<Badge count={icon()} offset={[-20, 30]}><Avatar size={150} style={{marginRight:"auto",marginLeft:"auto"}}>{this.state.user.first_name.substring(0,1)}{this.state.user.last_name.substring(0,1)}</Avatar></Badge>}
                    actions={[
                        <DeleteUser user={this.state.user}/>,
                        <EditUserProfil user={this.state.user}  refresh_handler={this.loadUserInfo.bind(this)}/>,
                    ]}
                    className="form-card"
                >
                    <Meta
                        title={<Title level={5}>{this.state.user.first_name} {this.state.user.last_name}</Title>}
                    />
                    
                    {/* function */}
                    <Row justify="center">
                        <Col span={2}>
                            <UserOutlined />
                        </Col>
                        <Col span={14} className="ant-form-item-label">
                            <label>{functionLabel()}</label>
                        </Col>
                    </Row>

                    {/* Inami */}
                    {this.state.user.inami != null&&
                        <Row justify="center">
                            <Col span={2}>
                                <FieldNumberOutlined />
                            </Col>
                            <Col span={14} className="ant-form-item-label">
                                <label>{this.state.user.inami}</label>
                            </Col>
                        </Row>
                    }

                    {/* email */}
                    <Row justify="center">
                        <Col span={2}>
                            <MailOutlined />
                        </Col>
                        <Col span={14} className="ant-form-item-label">
                            <label className={this.state.user.email_verified_at ==null&&"error"}>{this.state.user.email}</label>
                            {this.state.user.email_verified_at ==null&&<Button type="link" onClick={this.sendConfirmationMail} shape="round" icon={<SendOutlined />} danger>Mail de validation</Button>}
                        </Col>
                    </Row>

                    {/* Téléphone */}
                    {this.state.user.phone_number != null&&
                        <Row justify="center">
                            <Col span={2}>
                                <PhoneOutlined />
                            </Col>
                            <Col span={14} className="ant-form-item-label">
                                <label>{this.state.user.phone_number}</label>
                            </Col>
                        </Row>
                    }

                    {/* Adresse */}
                    {this.state.user.street != null&&
                        <Row justify="center">
                            <Col span={2}>
                                <EnvironmentOutlined />
                            </Col>
                            <Col span={14} className="ant-form-item-label">
                                <label>{this.state.user.street}, {this.state.user.street_number}</label>
                                <label>{this.state.user.zip_code} {this.state.user.locality}</label>
                            </Col>
                        </Row>      
                    }  
                    
                    {/* membre */}
                    <Row justify="center">
                        <Col span={2}>
                            <UserOutlined />
                        </Col>
                        <Col span={14} className="ant-form-item-label">
                            <label>Membre depuis le {this.state.date.getDate()}/{(this.state.date.getMonth()+1)}/{this.state.date.getFullYear()}</label>
                        </Col>
                    </Row>                                            
                </Card>
            );
        }else{
            return <EmptyCard/>
        }
    }
}

//Carte d'une entreprise
export function CompanyCard(props){

    var actions = {};
    if(props.company.id != null){
        if(store.getState().user.profile.role == "ROLE_ADMIN"){
            actions=[<DeleteCompany company={props.company}/>,<EditCompanyDetails company={props.company} refresh_handler={props.refresh_handler}/>];
        }else{
            actions=[<EditCompanyDetails company={props.company} refresh_handler={props.refresh_handler}/>];       
        }
    }

    return (
        <Card
            cover={<EditCompanyAvatar company={props.company} refresh_handler={props.refresh_handler}/>}
            actions={actions}
            className="form-card"
        >
            <Meta
                title={<Title level={5}>{props.company.name}</Title>}
            />

                {/* Adresse */}
                <Row justify="center">
                    <Col span={2}>
                        <EnvironmentOutlined />
                    </Col>
                    <Col span={14} className="ant-form-item-label">
                        <Text strong>{props.company.street}, {props.company.street_number}</Text>
                        <Text strong>{props.company.zip_code} {props.company.locality}</Text>
                    </Col>
                </Row>

                {/* TVA 
                <Row justify="center">
                    <Col span={2}>
                        <FileProtectOutlined />
                    </Col>
                    <Col span={14} className="ant-form-item-label">
                        <Text strong>TVA {props.company.vat_number}</Text>
                    </Col>
                </Row> */}

                {/* Téléphone */}
                <Row justify="center">
                    <Col span={2}>
                        <PhoneOutlined />
                    </Col>
                    <Col span={14} className="ant-form-item-label">
                        <Text strong>{props.company.phone_number}</Text>
                    </Col>
                </Row>

                {/* Espace de stockage */} 
                <Row justify="center">
                    <Col span={2}>
                        <FolderOutlined />   
                    </Col>
                    <Col span={14} className="ant-form-item-label">
                        <Row>
                            {props.company.storage_limit<1000000000 ?
                            <Text strong type={((props.company.used_storage/props.company.storage_limit)*100)>90&&"danger"}>{(props.company.used_storage/1024/1000).toFixed(1)}/{(props.company.storage_limit/1000/1000).toFixed(0)} Mo</Text>
                            :
                            <Text strong type={((props.company.used_storage/props.company.storage_limit)*100)>90&&"danger"}>{(props.company.used_storage/1024/1000/1000).toFixed(1)}/{(props.company.storage_limit /1000/1000/1000).toFixed(1)} Go</Text>}
                            <Tooltip title="Votre quota est calculé en comprenant vos campagnes créées et vos fichiers">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Row>
                    </Col>
                </Row>  

                {/* Description */}
                <Row justify="center">
                    <Col span={16}>
                        <Text type="secondary"> {props.company.description} </Text>
                    </Col>
                </Row>

                                                        
        </Card>
    );
}

//Zone de création d'une nouvelle campagne
export class CampaignAdd extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            name: "",
            allTags:[],
            tags: [],
            redirect: false,
            selectedTag: null,
        };

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadTagList();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Création de la campagne
    createCampaign(){
        const files = []

        //Ajout des fichiers à la campagne
        this.props.files.forEach((file, index) => {
            var newfile = {
                "file_id":file.id,
                "order":index,
                "duration":file.duration
            }
            files.push(newfile);
        })

        var durations = 0

        this.props.files.forEach(file => {
            durations += file.duration
        })

        API.post('api/campaigns',{
            name: this.state.name,
            company_id: store.getState().user.selected_company
        })
        .then((res) => {

            API.put('api/campaigns/'+res.data.id+'/files',
                files
            )
            .then((res) => {
            })
            .catch((err) => {
                    /*delete */
            })

            //Ajout des tags à la campagne
            /*this.state.tags.forEach((tag) => {
                API.put('api/campaigns/'+res.data.id+'/tags/'+tag.id)
                .then((res) => {})
                .catch((err) => {
                })
            })*/

            if(this._isMounted){
                notification.success({message: "Vous avez créé une nouvelle campagne !", duration:4.5})
                this.setState({redirect: true})
            } 
        })
        .catch((err) => {
            notification.error({message: "Erreur lors de la création de la campagne !", duration:4.5})
        })
    }

    //Chargement de la liste des tags 
    loadTagList(){
        API.get('/api/tags')
        .then((res) => {
            if(this._isMounted){
                const options = []

                res.data.forEach(tag => {
                    options.push({value: tag.label, id:tag.id})
                });

                this.setState({allTags: options})
            }
        })
        .catch((err) => {})
    }

    //Ajout d'un tag sélectionner
    addTag(){
        if(this.state.selectedTag != null){
            const actualTags = this.state.allTags.find(element => element.value == this.state.selectedTag)
            
            if(actualTags != null){
                this.state.tags.push(actualTags)
            }else{
                API.post('/api/tags',{
                    label:this.state.selectedTag
                }).then((res) => {
                    this.loadTagList();
                    this.state.tags.push({value: res.data.label, id:res.data.id})
                })
            }
            
            if(this._isMounted)
                this.setState({selectedTag:null})
        }
    }

    //Suppresion d'un tags
    removeTag(value){
        var tags = this.state.tags;
        tags = arrayMove(tags, value, tags.length-1)
        tags.pop();
        this.setState({tags: tags})
    }

    //Sélection d'un tag pour ajouter à la campagne
    onSelect(data){
        if(this._isMounted)
            this.setState({selectedTag:data})
    };

    //Modification du nom de la campagne
    changeName(event){
        if(this._isMounted)
            this.setState({name: event.target.value})
    }
    
    render() {
        const durations = () => {
            var durations = 0

            this.props.files.forEach(file => {
                durations += file.duration
            })

            return durations;
        }

        //Redirection si l'utilisateur a réussi a créer une campagne
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Card
                actions={[<Button type="primary" onClick={this.createCampaign.bind(this)} shape="round" size='large' disabled={(this.props.files.length<=0 || !this.props.enoughSpace)}>
                Créer la campagne
            </Button>]}
            >
                <Form layout="vertical"> 
                    {/* Titre */}
                    <Row>
                        <Col span={4}>
                            <FundProjectionScreenOutlined />
                        </Col>
                        <Col span={20}>
                            <Form.Item label="Titre">     
                                <Input value={this.state.name} placeholder={"Enter le titre"} onChange={this.changeName.bind(this)}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Duration */}
                    <Row>
                        <Col span={4}>
                            <ClockCircleOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            <label>Durée totale {Math.trunc(durations()/60)+"' "+durations()%60+"''"}</label>
                        </Col>
                    </Row>

                    {!this.props.enoughSpace &&
                        <Row justify="center">
                            <Text type="danger">Vous n'avez pas l'espace de stockage nécessaire pour créer cette campagne.</Text>
                        </Row>
                    } 

                    {/* Tags Input
                    <Row>
                        <Col span={4}>
                            <TagOutlined />
                        </Col>


                        <Col span={20} className="ant-form-item-label">
                            <Row justify="space-between" align="middle">
                                <Form.Item label="Tags">     
                                    <AutoComplete options={this.state.allTags} onChange={this.onSelect.bind(this)} placeholder="Entrer un tag" name={"tags"}/>
                                </Form.Item>
                                <Button type="text" shape="circle" onClick={this.addTag.bind(this)} icon={<PlusCircleOutlined />}/>
                            </Row>
                        </Col>
                    </Row>
                    {(this.state.tags.length > 0)?
                        this.state.tags.map((tag, index) => {
                                return <Tags key={index} title={tag.value} remove_handler={this.removeTag.bind(this, index)}/>
                        })
                    :<></>}*/}
                </Form>
            </Card>
        );
    }
}

//Zone de création d'une nouvelle diffusion
export class StreamAdd extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            start_date: null,
            end_date: null,
            hour: 0,
            minute: 0,
            redirect: false,
            monitors:[],
        };

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        this.loadMonitors();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadMonitors(){
        API.get('/api/companies/'+store.getState().user.selected_company+'/broadcasters')
        .then((res) =>{
            this.setState({
                monitors:res.data,
            })
        })
        .catch((err) => {console.log(err)});
    }

    //Création de la diffusion
    createBroadcast(){
        const rule = new RRule({
            freq: RRule.DAILY,
            dtstart: this.state.start_date,
            until: this.state.end_date,
            byhour: [this.state.hour],
            byminute: [this.state.minute]
        })

        API.post('api/broadcasts',{
            campaign_id: this.props.campaign.id,
            company_id: store.getState().user.selected_company,
            schedule: rule.toString()
        })
        .then((res) => {
            if(this._isMounted){
                notification.success({message: "Vous avez créé une nouvelle diffusion !", duration:4.5})
                this.state.monitors.forEach(monitor => {
                    API.put('api/broadcasters/'+monitor.id+"/broadcasts/"+res.data.id)
                    .catch((err) => {
                        notification.error({message: "Erreur lors de la liaison avec les moniteurs !", duration:4.5})
                    })
                });
                this.setState({redirect: true})
            } 
        })
        .catch((err) => {
            notification.error({message: "Erreur lors de la création de la diffusion !", duration:4.5})
        })
    }

    setStartDate(value, dateString){
        this.setState({start_date: new Date(dateString)});
    }

    setEndDate(value, dateString){
        this.setState({end_date:new Date(dateString)});
    }

    setTime(value, dateString){
        this.setState({hour: parseInt(dateString.split(":")[0])})
        this.setState({minute: parseInt(dateString.split(":")[1])})
    }
    
    
    render() {
        let name = "";
        let total_duration=0;

        if(this.props.campaign != {} && this.props.campaign != undefined){
            name = this.props.campaign.name;
            total_duration = this.props.campaign.total_duration;
        }

        //Redirection si l'utilisateur a réussi a créer la diffusion
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Card
                actions={[<Button form="StreamCreate" key="submit" htmlType="submit" type="primary" shape="round" size='large'>
                Programmer la diffusion
            </Button> ]}
            >
                <Form id="StreamCreate" layout="vertical" onFinish={this.createBroadcast.bind(this)}>
                    {/* Campagne sélectionnée */}
                    <Row>
                        <Col span={4}>
                            <FundProjectionScreenOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            <label>{name}</label>
                        </Col>
                    </Row>

                    {/* Durée de la campagne */}
                    <Row>
                        <Col span={4}>
                            <ClockCircleOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            {isNaN(Math.trunc(total_duration/60))?
                            <label>0' 0''</label>
                            :<label>{Math.trunc(total_duration/60)+"' "+total_duration%60+"''"}</label>
                            }
                            
                        </Col>
                    </Row>

                    {/* Date début */}
                    <Row>
                        <Col span={4}>
                            <CalendarOutlined/>
                        </Col>
                        <Col span={20}>
                            <Form.Item name="start_date" label="Date de début" rules={[{ required: true, message: 'Veuillez entrer une date de début' }]}>     
                                <DatePicker onChange={this.setStartDate.bind(this)}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Date fin */}
                    <Row>
                        <Col span={4}>
                            <CalendarOutlined/>
                        </Col>
                        <Col span={20}>
                            <Form.Item name="end_date" label="Date de fin" rules={[{ required: true, message: 'Veuillez entrer une date de fin' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (value > getFieldValue('start_date')) {
                                    return Promise.resolve();
                                }
                                else{
                                    return Promise.reject(new Error('La date de fin ne peut pas être antérieure à la date de début'));
                                }

                                
                                },
                            })]}>     
                                <DatePicker onChange={this.setEndDate.bind(this)} disabled={this.state.start_date==null}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

//Zone d'édition d'une nouvelle diffusion
export class StreamModify extends React.Component{
    constructor(props){
        super(props);

        if(props.broadcast == undefined){
            this.state = {
                redirect:true,
            }
        }else{
            this.state = {
                id:props.broadcast.id,
                start_date: new Date(moment(props.broadcast.start_date).format('MM/DD/YYYY HH:mm:ss').toString()),
                end_date: new Date(moment(props.broadcast.end_date).format('MM/DD/YYYY HH:mm:ss').toString()),
                hour: 0,
                minute: 0,
                redirect: false,
                monitors:[],
            };
        }
        this.modifyBroadcast = this.modifyBroadcast.bind(this);

        this.changeName = this.changeName.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.setTime = this.setTime.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    modifyBroadcast(){
        const rule = new RRule({
            freq: RRule.DAILY,
            dtstart: this.state.start_date,
            until: this.state.end_date,
            byhour: [this.state.hour],
            byminute: [this.state.minute]
        })

        API.put('api/broadcasts/'+this.state.id,{
            campaign_id: this.props.campaign.id,
            company_id: store.getState().user.selected_company,
            schedule: rule.toString()
        })
        .then((res) => {
            if(this._isMounted){
                notification.success({message: "Vous avez modifié votre diffusion !", duration:4.5})
                this.setState({redirect: true})
            } 
        })
        .catch((err) => {
            notification.error({message: "Erreur lors de la modification de la diffusion !", duration:4.5})
        })
    }

    //Modification du nom de la diffusion
    changeName(event){
        if(this._isMounted)
            this.setState({name: event.target.value})
    }

    setStartDate(value, dateString){
        this.setState({start_date: new Date(dateString)});
    }

    setEndDate(value, dateString){
        this.setState({end_date: new Date(dateString)});
    }

    setTime(value, dateString){
        this.setState({hour: parseInt(dateString.split(":")[0])})
        this.setState({minute: parseInt(dateString.split(":")[1])})
    }
    
    
    render() {
        //Redirection si l'utilisateur a réussi a créer la diffusion
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Card
                actions={[<Button form="StreamModify" key="submit" htmlType="submit" type="primary" shape="round" size='large'>
                Modifier la diffusion
            </Button> ]}
            >
                <Form id="StreamModify" layout="vertical" onFinish={this.modifyBroadcast}
                    fields={[
                        {
                            name: ["start_date"],
                            value: moment(this.state.start_date),
                        },
                        {
                            name: ["end_date"],
                            value: moment(this.state.end_date),
                        },
                    ]}
                >
                    {/* Campagne sélectionnée */}
                    <Row>
                        <Col span={4}>
                            <FundProjectionScreenOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            <label>{this.props.campaign.name}</label>
                        </Col>
                    </Row>

                    {/* Durée de la campagne */}
                    <Row>
                        <Col span={4}>
                            <ClockCircleOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            <label>{Math.trunc(this.props.campaign.total_duration/60)+"' "+this.props.campaign.total_duration%60+"''"}</label>
                        </Col>
                    </Row>

                    {/* Date début */}
                    <Row>
                        <Col span={4}>
                            <CalendarOutlined/>
                        </Col>
                        <Col span={20}>
                            <Form.Item name="start_date" label="Date de début" rules={[{ required: true, message: 'Veuillez entrer une date de début' }]}>     
                                <DatePicker name="start_date" onChange={this.setStartDate}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Date fin */}
                    <Row>
                        <Col span={4}>
                            <CalendarOutlined/>
                        </Col>
                        <Col span={20}>
                            <Form.Item name="end_date" label="Date de fin" rules={[{ required: true, message: 'Veuillez entrer une date de fin' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (value > getFieldValue('start_date')) {
                                    return Promise.resolve();
                                }
                                else{
                                    return Promise.reject(new Error('La date de fin ne peut pas être antérieure à la date de début'));
                                }

                                
                                },
                            })]}>     
                                <DatePicker name="end_date" onChange={this.setEndDate} disabled={this.state.start_date==null}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

//Zone de création d'une nouvelle campagne
export class FlowAdd extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            name: undefined,
            description:undefined,
            redirect: false,
        };

        this.createFlow = this.createFlow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Création de la diffusion
    createFlow(){
        const broadcasts = []

        //Ajout des fichiers à la campagne
        this.props.addedStreams.forEach((broadcast, index) => {
            var newbroadcast = {
                "broadcast_id":broadcast.id,
            }
            broadcasts.push(newbroadcast);
        })

        API.post('api/flows',{
            name: this.state.name,
            description:this.state.description,
            company_id: store.getState().user.selected_company
        })
        .then((res) => {

            API.put('api/flows/'+res.data.id+'/broadcasts',
            broadcasts
            )

            if(this._isMounted){
                notification.success({message: "Vous avez créé un nouveau flux !", duration:4.5})
                this.setState({redirect: true})
            } 
        })
        .catch((err) => {
            notification.error({message: "Erreur lors de la création du flux !", duration:4.5})
        })
    }

    handleChange(event){
        let value = event.target.value
        if(value == ""){
            value = undefined;
        }
        if(this._isMounted)
            this.setState({[event.target.name]: value})
        
    }
    
    render() {
        //Redirection si l'utilisateur a réussi a créer le flux
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Card
                actions={[<Button form="FlowCreate" key="submit" htmlType="submit" type="primary" shape="round" size='large' disabled={(this.props.addedStreams.length<=0)}>
                Créer le flux
            </Button> ]}
            >
                <Form id="FlowCreate" layout="vertical" onFinish={this.createFlow}
                    fields={[
                        {
                            name: ["name"],
                            value: this.state.name,
                        },
                        {
                            name: ["description"],
                            value: this.state.description,
                        }
                    ]}
                
                >
                    {/* Titre */}
                    <Row>
                        <Col span={4}>
                            <InteractionOutlined />
                        </Col>
                        <Col span={20}>
                            <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Veuillez entrer un titre' }]}>     
                                <Input name="name" placeholder={"Nom du flux"} onChange={this.handleChange}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Description */}
                    <Row>
                        <Col span={4}>
                            <AlignLeftOutlined />
                        </Col>
                        <Col span={20}>
                            <Form.Item name="description" label="Description">     
                                <TextArea name="description"  onChange={this.handleChange} rows={4} maxLength={500} /> 
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Durée totale des diffusions cumulées */}
                    <Row>
                        <Col span={4}>
                            <ClockCircleOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            <label>Durée totale {Math.trunc(this.props.duration/60)+"' "+this.props.duration%60+"''"}</label>
                        </Col>
                    </Row>

                </Form>
            </Card>
        );
    }
}

//Zone de création d'une nouvelle campagne
export class FlowModify extends React.Component{
    constructor(props){
        super(props);

        if(props.flow == undefined){
            this.state = {
                redirect:true,
            }
        }else{
            let description = undefined;
            if(props.flow.description != null){
                description=props.flow.description;
            }
            this.state = {
                id:props.flow.id,
                title: props.flow.name,
                description:description,
                broadcasts:props.addedStreams,
                redirect: false,
            };
        }

        this.modifyFlow = this.modifyFlow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Création de la diffusion
    modifyFlow(){
        const broadcasts = []

        //Ajout des fichiers à la campagne
        this.props.addedStreams.forEach((broadcast, index) => {
            var newbroadcast = {
                "broadcast_id":broadcast.id,
            }
            broadcasts.push(newbroadcast);
        })

        API.put('api/flows/'+this.state.id,{
            name: this.state.name,
            description:this.state.description,
            company_id: store.getState().user.selected_company
        })
        .then((res) => {

            API.put('api/flows/'+this.state.id+'/broadcasts',
            broadcasts
            )

            if(this._isMounted){
                notification.success({message: "Vous avez modifié votre flux !", duration:4.5})
                this.setState({redirect: true})
            } 
        })
        .catch((err) => {
            notification.error({message: "Erreur lors de la modification du flux !", duration:4.5})
        })
    }

    handleChange(event){
        let value = event.target.value
        if(value == ""){
            value = undefined;
        }
        if(this._isMounted)
            this.setState({[event.target.name]: value})
        
    }
    
    render() {
        //Redirection si l'utilisateur a réussi a créer le flux
        if(this.state.redirect)
            return <Redirect to='/company' />;

        return (
            <Card
                actions={[<Button form="FlowModify" key="submit" htmlType="submit" type="primary" shape="round" size='large'>
                Modifier le flux
            </Button> ]}
            >
                <Form id="FlowModify" layout="vertical" onFinish={this.modifyFlow}
                    fields={[
                        {
                            name: ["title"],
                            value: this.state.title,
                        },
                        {
                            name: ["description"],
                            value: this.state.description,
                        }
                    ]}
                
                >
                    {/* Titre */}
                    <Row>
                        <Col span={4}>
                            <InteractionOutlined />
                        </Col>
                        <Col span={20}>
                            <Form.Item name="title" label="Nom" rules={[{ required: true, message: 'Veuillez entrer un titre' }]}>     
                                <Input name="title" value={"this.state.title"} placeholder={"Nom du flux"} onChange={this.handleChange}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Description */}
                    <Row>
                        <Col span={4}>
                            <AlignLeftOutlined />
                        </Col>
                        <Col span={20}>
                            <Form.Item name="description" label="Description">     
                                <TextArea name="description" value={this.state.description}  onChange={this.handleChange} rows={4} maxLength={6} /> 
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Durée totale des diffusions cumulées */}
                    <Row>
                        <Col span={4}>
                            <ClockCircleOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            <label>Durée totale {Math.trunc(this.props.duration/60)+"' "+this.props.duration%60+"''"}</label>
                        </Col>
                    </Row>

                </Form>
            </Card>
        );
    }
}

//Zone de création d'une nouvelle campagne
export function FlowManage(props){
    return (

        <Card
            className="form-card"
        >
            <Meta
                title={<Title level={5}>{props.flow.name}</Title>}
                description={"flux proposé par "+props.company.name}
            />

                {/* Description */}
                <Row justify="center">
                    <Col span={16} className="ant-form-item-label">
                        <Text type="secondary">{props.flow.description} </Text>
                    </Col>
                </Row>                                    
        </Card>
    );
}

//Zone de modification d'une campagne
export class CampaignModify extends React.Component{
    constructor(props){
        super(props);

        console.log(props.files);

        this.state = {
            name: props.campaign.name,
            id:props.campaign.id,
            allTags:[],
            tags: props.campaign.tags,
            files:props.files,
            selectedTag: null
        };

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadTagList();
        //this.loadCampaignTagList();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des tags de la campagne
    loadCampaignTagList(){
        var tags = []

        API.get('/api/campaigns/'+store.getState().user.selected_campaign+'/tags')
        .then((res) => {
            res.data.forEach(tag => {
                tags.push({value: tag.label, id:tag.id})
            });

            if(this._isMounted)
                this.setState({tags: tags})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Modification de la campagne
    modifyCampaign(){
        const files = []

        //Ajout des fichiers à la campagne
        this.props.files.forEach((file, index) => {
            var newfile = {
                "file_id":file.id,
                "order":index,
                "duration":file.duration
            }
            files.push(newfile);
        })

        console.log(files);

        //Modification des données la campagne
        API.put('api/campaigns/'+this.state.id,{
            name: this.state.name
        })
        .then((res) => {
            //Ajout des tags à la campagne
            /*this.state.tags.forEach((tag) => {
                API.put('api/campaigns/'+this.state.id+'/tags/'+tag.id)
                .then((res) => {})
                .catch((err) => {
                })
            })*/

            //Modification des fichiers de la campagne
            API.put('api/campaigns/'+this.state.id+'/files',
                files
            )
            .then((res) => {})
            .catch((err) => {})

            if(this._isMounted){
                notification.success({message: "Vous avez modifié votre campagne", duration:4.5})
                this.setState({redirect: true})
            } 

        })
        .catch((err) => {
            notification.error({message: "Erreur lors de la modification de la campagne !", duration:4.5})
        })

        
    }

    //Chargement de la liste des tags
    loadTagList(){
        API.get('/api/tags')
        .then((res) => {
            if(this._isMounted){
                const options = []

                res.data.forEach(tag => {
                    options.push({value: tag.label, id:tag.id})
                });

                this.setState({allTags: options})
            }
        })
        .catch((err) => {})
    }

    //Ajout d'un tag sélectionner
    addTag(){
        if(this.state.selectedTag != null){
            const actualTags = this.state.allTags.find(element => element.value == this.state.selectedTag)
            
            if(actualTags != null){
                this.state.tags.push(actualTags)
            }else{
                API.post('/api/tags',{
                    label:this.state.selectedTag
                }).then((res) => {
                    this.loadTagList();
                    this.state.tags.push({value: res.data.label, id:res.data.id})
                })
            }
            
            if(this._isMounted)
                this.setState({selectedTag:null})
        }
    }

    //Sélection d'un tag pour ajouter à la campagne
    onSelect(data){
        if(this._isMounted)
            this.setState({selectedTag:data})
    };

    //Modification du nom de la campagne
    changeName(event){
        if(this._isMounted)
            this.setState({name: event.target.value})
    }
    
    render() {
        const durations = () => {
            var durations = 0

            this.props.files.forEach(file => {
                durations += file.duration
            })

            return durations;
        }

        //Redirection si l'utilisateur a réussi a modifier une campagne
        if(this.state.redirect)
            return <Redirect to='/company' />;
            
        return (
            <Card
                actions={[<Button onClick={this.modifyCampaign.bind(this)} shape="round" type="primary" disabled={(this.props.files.length<=0 || !this.props.enoughSpace)}>
                Modifier la campagne
                </Button> ]}
            >
                <Form layout="vertical"
                    fields={[
                        {
                            name: ["name"],
                            value: this.state.name,
                        }
                    ]}
                > 

                    {/* Name Enter */}
                    {/* Titre */}
                    <Row>
                        <Col span={4}>
                            <FundProjectionScreenOutlined />
                        </Col>
                        <Col span={20}>
                            <Form.Item name="name" label="Titre" rules={[{ required: true, message: 'Veuillez entrer un nom' }]}>     
                                <Input value={this.state.name} placeholder={"Entez le nom"} onChange={this.changeName.bind(this)}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Duration */}
                    <Row>
                        <Col span={4}>
                            <ClockCircleOutlined />
                        </Col>
                        <Col span={20} className="ant-form-item-label">
                            <label>Durée totale {Math.trunc(durations()/60)+"' "+durations()%60+"''"}</label>
                        </Col>
                    </Row>

                    {!this.props.enoughSpace &&
                        <Row justify="center">
                            <Text type="danger">Vous n'avez pas l'espace de stockage nécessaire pour créer cette campagne.</Text>
                        </Row>
                    } 

                    {/* Tags Enter
                    <Row>
                        <Col span={4}>
                            <TagOutlined />
                        </Col>


                        <Col span={20} className="ant-form-item-label">
                            <Row justify="space-between" align="middle">
                                <Form.Item label="Tags">     
                                    <AutoComplete options={this.state.allTags} onChange={this.onSelect.bind(this)} placeholder="Entrer un tag" name={"tags"}/>
                                </Form.Item>
                                <Button type="text" shape="circle" onClick={this.addTag.bind(this)} icon={<PlusCircleOutlined />}/>
                            </Row>
                        </Col>
                    </Row> */}

                    {/* Tags 
                    {(this.state.tags.length > 0)?
                    this.state.tags.map((tag, index) => {
                            return <Tags key={index} title={tag.value} />
                    })
                    :<></>}*/}
                </Form>
            </Card>
        );
    }
}
