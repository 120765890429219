//Import des composants utiles
import React from 'react';
import Navbar from '../../components/Navbar';
import API from '../../api';
import store from '../../Store/Store';
import { ActionCreators } from '../../Store/Action';

//Import des composants de design
import { Typography, Layout, Dropdown, Menu, Input,List, Button, Empty } from 'antd';
import { AllCampaignSmallCard, UserAccount, Tags } from '../../components/Component';
import { Row, Col } from 'antd';
import { BookOutlined,HeartOutlined, HeartFilled } from '@ant-design/icons';
const { Title, Text } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;

//Page de gestion des fichiers
class CampaignsPage extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            campaigns: [],
            publicscabinets:[],
            favorite_campaigns: [],
            filter_favorite_campaign: false,
            searchText: ""
        }

        this.loadCompanyCampaignsList = this.loadCompanyCampaignsList.bind(this);
        this.loadUserFavorite = this.loadUserFavorite.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        this.loadCompanyCampaignsList();
        this.loadUserFavorite();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Chargement des campagnes disponible
    loadCompanyCampaignsList(){

        const publicscabinets =[];

        API.get('/api/campaigns?public=1')
        .then((res) => {
            let campaigns = [];
            res.data.forEach(async (campaign) => {
                const tags = await API.get('/api/campaigns/'+campaign.id+"/tags");
                campaign["tags"] = tags.data
                if(!publicscabinets.some(e => e.id == campaign.company_id)){
                    const company = await API.get('/api/companies/'+campaign.company_id);
                    publicscabinets.push(company.data);
                    campaign["company"]=company.data;
                }else{
                    campaign["company"]=publicscabinets.find(e => e.id == campaign.company_id);
                }

                campaigns.push(campaign);
                
                campaigns.sort((a, b) => {
                    let na = a.name.toLowerCase(),
                        nb = b.name.toLowerCase();
                
                    if (na < nb) {
                        return -1;
                    }
                    if (na > nb) {
                        return 1;
                    }
                    return 0;
                });
                if(this._isMounted)
                    this.setState({campaigns:campaigns})
            });
            
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement des fichiers et campagnes favorite
    loadUserFavorite(){
        API.get('/api/users/'+store.getState().user.profile.id+'/campaigns')
        .then((res) => {
            if(this._isMounted)
                this.setState({favorite_campaigns:res.data})
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){
        const favorite_campaign = () => this.setState({filter_favorite_campaign: !this.state.filter_favorite_campaign})

        //Vérification de la connexion de l'utilisateur
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <img src="img/ico.png"/>
                    <Navbar actual_tab={"Campaigns"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Row justify="end" align="middle">
                            <UserAccount />
                        </Row>
                    </Header>
                    <Content className="app-content">
                        <Row justify="space-between" className="app-header">
                            <Col span={8}>
                                <Title level={3}>Bibliothèque de <span className="hightlight">campagnes</span></Title>
                            </Col>
                        </Row>

                        <Row className="toolsbar" justify="start">
                            <Col>
                                <Search placeholder="Rechercher une campagne" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
                            </Col>
                            <Col>
                                <Button onClick={favorite_campaign} type="text" shape="circle" icon={!this.state.filter_favorite_campaign?<HeartOutlined/>:<HeartFilled/>}/>
                            </Col>
                        </Row>
                        <Row>
                            {/* Liste des campagnes */}
                            {!this.state.filter_favorite_campaign?
                                <List
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                    position:'top'
                                }}
                                className="list-columns"
                                dataSource={this.state.campaigns.filter(campaign => (campaign.name).toLowerCase().includes(this.state.searchText.toLowerCase()))}
                                renderItem={item => (
                                    <AllCampaignSmallCard key={item.id} element_id={item.id} favorite={this.state.favorite_campaigns.filter(fcampaign => fcampaign.id==item.id).length > 0} refresh_handler={this.loadUserFavorite.bind(this)} campaign={item}/>
                                    )
                                }
                                locale={{emptyText:<Empty
                                    image={<BookOutlined />}
                                    description={this.state.searchText!=""?
                                    <span>
                                        Il n'y a pas de campagne publique correspondante à votre recherche
                                    </span>
                                    :
                                    <span>
                                        Il n'y a pas encore de campagne publique
                                    </span>
                                    }
                                >
                                </Empty>}}
                                />  
                            :

                                <List
                                    pagination={{
                                        pageSize: 12,
                                        hideOnSinglePage:true,
                                        position:'top'
                                    }}
                                    className="list-columns"
                                    dataSource={this.state.campaigns.filter(campaign => (campaign.name).toLowerCase().includes(this.state.searchText.toLowerCase())).filter(campaign => this.state.favorite_campaigns.filter(fcampaign => fcampaign.id==campaign.id).length > 0)}
                                    renderItem={item => (
                                        <AllCampaignSmallCard key={item.id} element_id={item.id} favorite={this.state.favorite_campaigns.filter(fcampaign => fcampaign.id==item.id).length > 0} refresh_handler={this.loadUserFavorite.bind(this)} campaign={item}/>
                                        )
                                    }
                                    locale={{emptyText:<Empty
                                        image={<BookOutlined />}
                                        description={
                                        <span>
                                            Pas de campagne publique trouvée
                                        </span>
                                        }
                                    >
                                    </Empty>}}
                                    /> 
                                }
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default CampaignsPage;